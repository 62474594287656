import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { ReportsService } from 'src/app/services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-research-companies-add',
  templateUrl: './research-companies-add.component.html',
  styleUrls: ['./research-companies-add.component.scss']
})
export class ResearchCompaniesAddComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  form: FormGroup
  id = null
  loading = false
  files = []
  image_url: any
  item: any
  fileName = 'Clique ou arraste aqui para inserir sua imagem'
  loadingImage = false
  imgLoaded: any
  submitting = false

  constructor(
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private reportsService: ReportsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.setupForm()
    if (this.id) {
      this.getItem()
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": [this.id],
      "title": [null, Validators.required],
      "description": [null, Validators.required],
      "image": [this.image_url],
    })
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.reportsService.showResearchCompanies(this.id)
      this.item = res.data
      this.form.patchValue(res.data)
    } catch (error) {
      console.log(error)
      this.loading = false
    } finally {
      this.loading = false
    }
  }

  async save() {
    this.submitting = true
    try {
      let params = this.form.getRawValue()
      if (this.image_url) {
        params.image = this.image_url
      } else {
        delete (params.image)
      }
      console.log(params)
      // return
      let res: any = await this.reportsService.saveResearchCompanies(params)
      console.log({ res })
      if (res.status) {
        this.helper.triggerNotification(true)
        this.router.navigate([PathRoutes.ResearchCompanies.Detail + '/' + res.data.id])
      } else {
        this.helper.triggerNotification(false)
      }
    } catch (error) {
      this.helper.triggerNotification(false)
      console.log(error)
      this.submitting = false
    } finally {
      this.submitting = false
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isFileValid: any

  fileChangeEvent(event: any): void {
    this.loadingImage = true
    this.isFileValid = false
    this.imageChangedEvent = event
    let hiddenImage: any = document.getElementById('preview')
    let reader = new FileReader
    reader.readAsDataURL(event.target.files[0])

    reader.addEventListener("load", function () {
      hiddenImage.src = reader.result;
    }, false);

    setTimeout(() => {
      // if (hiddenImage.height < 250) {
      //   this.helper.triggerNotification(false, 'Erro', 'Tamanho mínimo para altura é de 250px')
      //   this.isFileValid = false
      // } else
      if (event.target.files[0].size > 1000000) {
        this.helper.triggerNotification(false, 'Erro', 'Tamanho máximo para imagem é de 1mb')
        this.isFileValid = false
      } else {
        this.isFileValid = true
      }
      this.loadingImage = false
    }, 1000)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.image_url = this.helper.dataURLtoFile(event.base64, 'research_company.jpg')
  }

  cancelUpload() {
    this.image_url = null;
    this.isFileValid = false
    this.fileName = 'Clique ou arraste aqui para inserir sua imagem'
  }
}
