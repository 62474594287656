import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private network: NetworkService) { }

  sendNotification(params) {
    return this.network.post(Endpoints.Notification, params)
  }
}
