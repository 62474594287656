<app-navbar [breadcrumbCurrent]="'Postagens'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-md-10">
  <app-header title="Postagens" icon="fas fa-th-list" [buttonLink]="PathRoutes.Posts.Add" buttonLabel="Adicionar"
    buttonIcon="fas fa-plus-circle mr-2"></app-header>
  <app-table [columns]="columns" [more]="btnLoading" [loading]="loading" (loadMore)="loadMore()"
    (search)="search($event)" [empty]="items.length == 0" [hasSearch]="true" [paginateInfo]="paginateInfo">
    <tr *ngFor="let item of items;" (click)="detail(item.id)" class="tr-link">
      <td width="5%">
        <span>{{ item?.user?.nickname }}</span>
      </td>
      <td width="45%">
        <span>{{ item.title }}</span>
      </td>
      <td >
        <span>{{ item.type_string }}</span>
      </td>
      <td >
        <span>{{ item.subchannel.title }} <small style="font-size: smaller; opacity: .65;">({{ item.channel.title }})</small></span>
      </td>
      <td width="10%">
        <span>{{ item.created_at | date: 'dd/MM/yyyy' }}</span>
      </td>
    </tr>
  </app-table>
</app-container>
