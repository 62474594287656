import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { AdminsService } from 'src/app/services/admins.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  columns = ['', 'Nome', 'Nickname', 'Email', 'Desde de']
  loading = false
  loadingMore = false
  filters = {
    search: '',
    page: 1,
    type: 'admin'
  }
  items = []
  btnLoading = false

  constructor(private router: Router, private usersService: UsersService) { }

  ngOnInit() {
    this.getItems();
  }

  getItems(loadingMore = false) {

    if (!loadingMore) {
      this.filters.page = 1
      this.loading = true
    } else {
      this.loadingMore = true
    }

    this.usersService.list(this.filters)
      .then((res: any) => {
        if (res.status) {
          this.btnLoading = res.next_page_url ? true : false
          this.items = loadingMore ? this.items.concat(res.data) : res.data
        }
        this.filters.page++
      })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
        this.loadingMore = false
      })
  }

  loadMore() {
    this.getItems(true)
  }

  search(string) {
    this.filters.search = string
    this.getItems(false)
  }

  detail(id) {
    this.router.navigate([PathRoutes.Admins.Detail + `/${id}`])
  }
}
