<app-navbar breadcrumbCurrent="Youtube Settings"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-10">
  <app-header title="Youtube Settings"></app-header>
  <app-card>
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Client ID" [required]="true" name="client_id" placeholder="Código"
            [ngClass]="{'is-invalid': form.controls['client_id'].touched && !form.controls['client_id'].valid}"
            [messageCondition]="form.controls['client_id'].touched && !form.controls['client_id'].valid"
            message="Preencha o código"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Client Secret" [required]="true" name="client_secret" placeholder="Código"
            [ngClass]="{'is-invalid': form.controls['client_secret'].touched && !form.controls['client_secret'].valid}"
            [messageCondition]="form.controls['client_secret'].touched && !form.controls['client_secret'].valid"
            message="Preencha o código"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Google API Key" [required]="true" name="google_api_key" placeholder="Código"
            [ngClass]="{'is-invalid': form.controls['google_api_key'].touched && !form.controls['google_api_key'].valid}"
            [messageCondition]="form.controls['google_api_key'].touched && !form.controls['google_api_key'].valid"
            message="Preencha o código"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Youtube Channel" [required]="true" name="channel_youtube" placeholder="Código"
            [ngClass]="{'is-invalid': form.controls['channel_youtube'].touched && !form.controls['channel_youtube'].valid}"
            [messageCondition]="form.controls['channel_youtube'].touched && !form.controls['channel_youtube'].valid"
            message="Preencha o código"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Redirect URI" [required]="true" name="redirect_uri" placeholder="Código"
            [ngClass]="{'is-invalid': form.controls['redirect_uri'].touched && !form.controls['redirect_uri'].valid}"
            [messageCondition]="form.controls['redirect_uri'].touched && !form.controls['redirect_uri'].valid"
            message="Preencha o código"></app-input>
        </div>
      </div>
      <app-form-button [loading]="loading" [disabled]="loading || form.invalid" [form]="form" [label]="'Salvar'">
      </app-form-button>
    </form>
  </app-card>
</app-container>
