import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() label: string = ''
  @Input() icon: string = ''
  @Input() disabled: any = false
  @Input() loading: boolean = false
  @Input() link: string = ''
  @Input() ngClass: any = ''
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  actionEmittted() {
    this.action.emit()
  }
}
