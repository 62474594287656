<div class="form-group" [formGroup]="form">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>

  <input type="text" [value]="value" readonly [formControlName]="name" (click)="getOptions()"
    [placeholder]="placeholder" (focusout)="focusout()" class="form-control" [ngClass]="ngClass">

  <ul class="options" [hidden]="!showOptions">
    <li *ngIf="actionLabel">
      <a href="" (click)="actionClicked()" data-toggle="modal">
        <i class="fas fa-plus-circle"></i>
        {{ actionLabel }}
      </a>
    </li>
    <li *ngFor="let option of options; let i = index" (click)="setOption(option)">
      {{ optionsId ? option.name : option }}</li>
    <li *ngIf="options.length == 0" style="cursor:auto;" class="none">Nenhum item encontrado</li>
  </ul>

  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
