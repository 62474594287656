import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons';
import { PathRoutes } from 'src/app/routes/path-routes';
import { PostsService } from 'src/app/services/posts.service';
import { environment } from './../../../environments/environment';
import { HelperService } from './../../helpers/helper.service';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss']
})
export class ComplaintsComponent implements OnInit {
  columns = ['#', 'Usuário', 'Autor', 'Post', '', '']
  environment = environment
  PathRoutes = PathRoutes
  loading = false
  loadingMore = false
  items = []
  filters = {
    search: '',
    page: 1,
  }
  btnLoading = false
  counter: any

  constructor(
    public helper: HelperService,
    private postsService: PostsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getItems();
  }

  getItems(loadingMore = false) {

    if (!loadingMore) {
      this.filters.page = 1
      this.loading = true
    } else {
      this.loadingMore = true
    }

    this.postsService.getComplaints(this.filters)
      .then((res: any) => {
        if (res.status) {
          this.btnLoading = res.next_page_url ? true : false
          this.items = loadingMore ? this.items.concat(res.data) : res.data
          this.counter = res.data.filter(elem => !elem?.post?.deleted).length
        }
        this.filters.page++
      })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
        this.loadingMore = false
      })
  }

  loadMore() {
    this.getItems(true)
  }

  search(string) {
    this.filters.search = string
    this.getItems(false)
  }

  detail(id) {
    this.router.navigate([PathRoutes.Users.Detail + `/${id}`])
  }

  async deletePost(id) {
    try {
      let swal = await this.helper.deleteSwal()
      if (swal.value) {
        let del = await this.postsService.update(id, { deleted: 1 })
        this.getItems()
      }
    } catch (error) {
      console.log(error)
    }
  }
}
