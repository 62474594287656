import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
declare var $: any

@Component({
  selector: 'app-select-custom',
  templateUrl: './select-custom.component.html',
  styleUrls: ['./select-custom.component.scss']
})
export class SelectCustomComponent implements OnInit {
  @Input() form: FormGroup = null
  @Input() label: string = ''
  @Input() required: boolean = false
  @Input() options: any = []
  @Input() optionsId: boolean = false
  @Input() optionsIndex: boolean = false
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() dataTarget: string = ''
  @Input() actionLabel: string = ''
  @Input() value: string = ''
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();

  // value = ''
  showOptions = false

  constructor() { }

  ngOnInit(): void {
  }

  getOptions() {
    this.showOptions = true
  }

  actionClicked(): void {
    this.action.emit()
    if (this.dataTarget) {
      $(this.dataTarget).modal()
    }
    this.value = ''
  }

  setOption(option) {
    this.value = this.optionsId ? option.name : option
    this.showOptions = false
    this.form.get(this.name).setValue(this.optionsId ? option.id : option)
    if (this.change) {
      this.change.emit(this.optionsId ? option.id : option)
    }
  }

  focusout() {
    setTimeout(() => {
      this.showOptions = false
    }, 200);
  }

}
