<div>
  <button class="btn btn-primary" (click)="actionEmittted()" *ngIf="!link" [disabled]="disabled" [ngClass]="ngClass">
    <span *ngIf="icon" [ngClass]="icon" class="mr-2"></span>
    <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
    {{ label }}
  </button>
  <a class="btn btn-primary" routerLink="{{ link }}" *ngIf="link" [ngClass]="ngClass">
    <span *ngIf="icon" [ngClass]="icon" class="mr-2"></span>
    {{ label }}
  </a>
</div>
