import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private network: NetworkService, private sessionService: SessionService) { }

  list(params) {
    var url = `${Endpoints.Users}?type=${params.type || ''}&page=${params.page || ''}&per_page=${params.per_page || ''}&date_from=${params.dateFrom || ''}&date_to=${params.dateTo || ''}&plan_status=${params.plan_status || ''}&search=${params.search || ''}`;
    return this.network.get(url)

  }

  get(id) {
    var url = `${Endpoints.Users}/${id}`
    return this.network.get(url)
  }

  // getAddress(id) {
  //   var url = `${Endpoints.Address}/${id}`
  //   return this.network.get(url, false)
  // }

  save(info) {
    var params = info
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.UpdateUser}/${id}`, params)
    } else {
      delete (params.id)
      // console.log(JSON.stringify(params, null, 3))
      return this.network.postMultipart(Endpoints.RegisterUser, params)
    }
  }

  active(params) {
    var id = params.id
    delete (params.id)
    var url = `${Endpoints.UpdateUser}/${id}`
    return this.network.postMultipart(url, params)
  }

  getTerms() {
    return this.network.get(`${Endpoints.Settings}/terms-of-use`)
  }

  saveTerms(params) {
    return this.network.put(`${Endpoints.Settings}/terms-of-use`, params)
  }

  paymentHistory(id) {
    return this.network.get(`${Endpoints.PaymentHistory}?user_id=${id || ''}`)
  }

  update(id, params) {
    return this.network.postMultipart(`${Endpoints.UpdateUser}/${id}`, params)
  }

  delete(id) {
    return this.network.delete(`${Endpoints.Users}/${id}?type=permanent`)
  }

  homeInfo() {
    return this.network.get(Endpoints.HomeInfo)
  }

}
