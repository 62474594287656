<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" [attr.primary]="whiteLabel?.primary_color">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse"
      aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="mt-4 border-radius-2" routerLinkActive="active" routerLink="/">
      <img [src]="environment.imageBaseUrl + session?.white_label?.icon_path" alt="Logo" class="rounded" width="50">
      <span class="text-muted ml-2">{{ session?.white_label?.app_name }}</span>
    </a>
    <span *ngIf="environment.version" class="text-muted mt-2"><small>{{environment.version}}</small></span>
    <!-- <a class="navbar-brand" routerLinkActive="active" routerLink="/">
      <img src="assets/img/PsiApp-paint.png" alt="Leap" style="max-height: 3rem">
    </a> -->
    <div class="navbar-user d-md-none">
      <div class="dropdown">
        <a id="sidebarIcon" class="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <div class="avatar avatar-sm">
            <!-- <img src="assets/img/avatar.svg" class="avatar-img rounded-circle" alt="..."> -->
          </div>
        </a>
      </div>
    </div>
    <div class="collapse navbar-collapse mt-5" id="sidebarCollapse">
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="permissions.app_home">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Home}}">
            <i class="fas fa-poll mr-3"></i>
            Home
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions.lives">
          <a class="nav-link" routerLinkActive="active" target="_blank" [href]="'https://studio.youtube.com/channel/'">
            <i class="fab fa-chromecast mr-3"></i>
            Lives
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Financial.List}}">
            <i class="fas fa-hand-holding-usd mr-3"></i>
            Financeiro
          </a>
        </li> -->
      </ul>
      <div *ngIf="permissions.users || permissions.admins">
        <hr class="navbar-divider my-3">
        <h6 class="navbar-heading">
          Usuários
        </h6>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="permissions.users">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Users.List}}">
            <i class="fas fa-user-friends mr-3"></i>
            Usuários
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions.admins">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Admins.List}}">
            <i class="fas fa-user-cog mr-3"></i>
            Administradores
          </a>
        </li>
      </ul>
      <div *ngIf="permissions.channels || permissions.posts || permissions.complaints">
        <hr class="navbar-divider my-3">
        <h6 class="navbar-heading">
          Postagens
        </h6>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="permissions.channels">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Channels.List}}">
            <i class="fas fa-th-large mr-3"></i>
            Comunidades
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions.channels">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Subchannels.List}}">
            <i class="fas fa-th-large mr-3"></i>
            Canais
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions.posts">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Posts.List}}">
            <i class="fas fa-th-list mr-3"></i>
            Postagens
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions.complaints">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Complaints}}">
            <i class="fas fa-bullhorn mr-3"></i>
            Denúncias
          </a>
        </li>
      </ul>
      <div *ngIf="permissions.research_house">
        <hr class="navbar-divider my-3">
        <h6 class="navbar-heading">
          Casas de análise
        </h6>
      </div>
      <ul class="navbar-nav" *ngIf="permissions.research_house">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.ResearchCompanies.List}}">
            <i class="fas fa-search mr-3"></i>
            Casas de análise
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Reports.List}}">
            <i class="fas fa-file-alt mr-3"></i>
            Relatórios
          </a>
        </li>
      </ul>

      <div *ngIf="permissions.settings || permissions.notifications">
        <hr class="navbar-divider my-3">
        <h6 class="navbar-heading">
          Aplicativo
        </h6>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="permissions.settings">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.BannerApp.List}}">
            <i class="fas fa-mobile-alt mr-3"></i>
            Banners
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions.notifications">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.Notification}}">
            <i class="fas fa-bell mr-3"></i>
            Notificação
          </a>
        </li>
        <!-- <li class="nav-item"> -->
        <li class="nav-item" *ngIf="permissions.home_ads">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.HomeAds}}">
            <i class="fas fa-ad mr-3"></i>
            Anúncio
          </a>
        </li>
      </ul>
      <hr class="navbar-divider my-3">
      <h6 class="navbar-heading">
        Outros
      </h6>
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="permissions.settings">
          <a class="nav-link" routerLinkActive="active" routerLink="{{PathRoutes.TermsOfUse.Edit}}">
            <i class="fas fa-file-signature mr-3"></i>
            Termos de uso
          </a>
        </li>
      </ul>
      <hr class="navbar-divider my-3">
      <h6 class="navbar-heading">
        Dev
      </h6>
      <ul class="navbar-nav" *ngIf="permissions.youtube">
        <li class=" nav-item">
          <a class="nav-link" routerLinkActive="active" [href]="urlGooglePermission">
            <i class="fas fa-unlock-alt mr-3"></i>
            Linkar Youtube
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="PathRoutes.YoutubeSettings">
            <i class="fab fa-youtube mr-3"></i>
            Youtube Settings
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
