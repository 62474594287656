import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { ReportsService } from 'src/app/services/reports.service';
import { environment } from 'src/environments/environment';
declare var $: any

@Component({
  selector: 'app-reports-add',
  templateUrl: './reports-add.component.html',
  styleUrls: ['./reports-add.component.scss']
})
export class ReportsAddComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  form: FormGroup
  categoryForm: FormGroup
  id = null
  loading = false
  files = []
  path: any
  item: any
  fileName = 'Clique ou arraste aqui para inserir seu arquivo'
  loadingImage = false
  imgLoaded: any
  submitting = false
  types = [
    { id: 'report', name: 'Relatório' },
    { id: 'recommended_wallet', name: 'Carteira recomendada' }
  ]
  researchCompanies = []
  reportCategories = []
  categoryValue = ''

  constructor(
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private reportsService: ReportsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.setupForm()
    this.setupCategoryForm()
    this.getResearchCompanies()
    this.getCategories()
    if (this.id) {
      this.getItem()
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": [this.id],
      "title": ['', Validators.required],
      "description": ['', Validators.required],
      "research_company_id": ['', Validators.required],
      "report_category_id": ['', Validators.required],
      "type": ['', Validators.required],
      "date": ['', Validators.required],
      "file": [null],
    })
  }

  setupCategoryForm() {
    this.categoryForm = this.formBuilder.group({
      "title": ['', Validators.required],
    })
  }

  changeType(e) {
    this.form.get('type').setValue(e.target.value)
  }

  changeResearchCompany(e) {
    this.form.get('research_company_id').setValue(e.target.value)
  }

  changeReportCategory(e) {
    this.form.get('report_category_id').setValue(e)
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.reportsService.showReports(this.id)
      this.item = res.data
      this.form.patchValue(res.data)
      this.categoryValue = res.data.category?.title
    } catch (error) {
      console.log(error)
      this.loading = false
    } finally {
      this.loading = false
    }
  }

  async getResearchCompanies() {
    try {
      let res: any = await this.reportsService.listResearchCompanies()
      if (res.status) {
        this.researchCompanies = res.data.map(e => ({ id: e.id, name: e.title }))
      }
    } catch (error) {
      console.log({ error })
    }
  }

  async getCategories() {
    try {
      let res: any = await this.reportsService.listReportCategories()
      if (res.status) {
        this.reportCategories = res.data.map(e => ({ id: e.id, name: e.title }))
      }
    } catch (error) {
      console.log({ error })
    }
  }

  async saveCategory() {
    try {
      console.log(this.categoryForm.getRawValue())
      let res = await this.reportsService.saveReportCategory(this.categoryForm.getRawValue())
      if (res.status) {
        this.form.get('report_category_id').setValue(res.data.id)
        this.categoryValue = res.data.title
        this.getCategories()
        this.categoryForm.get('title').setValue('')
        this.helper.triggerNotification(true)
        $('#categoriesModal').modal('hide')
      }
    } catch (error) {
      console.log({ error })
    }
  }

  async save() {
    this.submitting = true
    try {
      let params = this.form.getRawValue()
      if (!params.file) {
        delete (params.file)
      }
      console.log(params)
      // return
      let res: any = await this.reportsService.saveReports(params)
      if (res.status) {
        this.helper.triggerNotification(true)
        this.router.navigate([PathRoutes.Reports.Detail + '/' + res.data.id])
      } else {
        this.helper.triggerNotification(false)
      }
    } catch (error) {
      this.helper.triggerNotification(false)
      console.log(error)
      this.submitting = false
    } finally {
      this.submitting = false
    }
  }

  fileChangeEvent(event: any): void {
    this.form.get('file').setValue(event.target.files[0])
    let split = event.target.value.split('\\')
    this.fileName = split[split.length - 1]
  }

  cancelUpload() {
    this.form.get('file').setValue(null)
    this.fileName = 'Clique ou arraste aqui para inserir seu arquivo'
  }
}
