import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class HomeAdsService {

  constructor(private network: NetworkService) { }

  get() {
    return this.network.get(Endpoints.HomeAds)
  }

  save(params) {
    return this.network.postMultipart(Endpoints.HomeAds, params)
  }
}
