<app-navbar [breadcrumbLink]="PathRoutes.BannerApp.List" [breadcrumbLinkName]="'Banners'"
  [breadcrumbCurrent]="'Detalhes'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Banner" icon="fas fa-mobile-alt" [buttonLink]="PathRoutes.BannerApp.Edit +'/'+ id"
    buttonLabel="Editar" buttonIcon="fas fa-edit mr-2" [custom]="true">
    <button *ngIf="count > 1" class="btn btn-danger" (click)="deleteBanner()">
      <span class="fas fa-times-circle mr-2"></span>
      Excluir
    </button>
  </app-header>
  <app-card [loading]="loading">
    <form>
      <div class="row">
        <div *ngIf="item?.image" class="col-12 d-flex justify-content-center pb-5">
          <img [src]="item?.image ? environment.imageBaseUrl+item?.image : 'assets/img/placeholder.jpg'"
            class="img-fluid screenshot rounded" style="max-width: 500px;">
        </div>
        <div class="col-12">
          <app-input label="Título" [disabled]="true" [value]="item?.title"></app-input>
        </div>
        <div class="col-12">
          <app-textarea label="Descrição" [disabled]="true" [value]="item?.description"></app-textarea>
        </div>
        <div class="col-12">
          <app-input label="Duração do slide (em segundos)" [disabled]="true" [value]="item?.timer || null"></app-input>
        </div>
        <div class="col-12" *ngIf="item?.audio">
          <div class="form-group">
            <label class="text-muted">Áudio</label>
            <audio [src]="environment.imageBaseUrl+item?.audio" controls style="width: 100%"></audio>
          </div>
        </div>
      </div>
    </form>
  </app-card>
</app-container>
