import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private network: NetworkService) { }

  getComplaints(filters) {
    return this.network.get(`${Endpoints.Complaint}?page=${filters.page || ''}`)
  }

  list(filters) {
    return this.network.get(`${Endpoints.Posts}?channel_id=${filters.channel_id || ''}&page=${filters.page || ''}&type=all&q=${filters.search || ''}`)
  }

  show(id) {
    return this.network.get(`${Endpoints.Posts}/${id}`)
  }

  update(id, params) {
    return this.network.put(`${Endpoints.Posts}/${id}`, params)
  }

  post(params) {
    if (params.id) {
      let id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.Posts}s/${id}/update`, params)
    } else {
      delete (params.id)
      return this.network.postMultipart(Endpoints.Posts, params)
    }
  }

  getChannels() {
    return this.network.get(Endpoints.Channels)
  }

  showChannel(id) {
    return this.network.get(`${Endpoints.Channels}/${id}`)
  }


  saveChannel(params) {
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.Channels}/update/${id}`, params)
    } else {
      delete (params.id)
      return this.network.postMultipart(Endpoints.Channels, params)
    }
  }

  deleteChannel(id) {
    return this.network.delete(`${Endpoints.Channels}/${id}`)
  }


  getSubChannels(channel_id = '') {

    if(channel_id != ''){
      
      return this.network.get(`${Endpoints.Subchannels}?channel_id=${channel_id}`)
    }else{
      return this.network.get(Endpoints.Subchannels)
    }
  }

  showSubChannel(id) {
    return this.network.get(`${Endpoints.Subchannels}/${id}`)
  }

  saveSubChannel(params) {
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.put(`${Endpoints.Subchannels}/${id}`, params)
    } else {
      delete (params.id)
      return this.network.post(Endpoints.Subchannels, params)
    }
  }

  deleteSubChannel(id) {
    return this.network.delete(`${Endpoints.Subchannels}/${id}`)
  }

  deleteImage(id) {
    return this.network.delete(`${Endpoints.PostImage}/${id}`)
  }

  listAllStock(filters) {
    return this.network.get(`${Endpoints.Stocks}?page=${filters?.page || 1}&search=${filters?.search || ''}`)
  }
}
