
export const Endpoints = Object.freeze({
  Login: 'login',
  Users: 'user',
  RegisterUser: 'user/register',
  UpdateUser: 'user/update',
  PasswordReset: 'reset-password',
  PasswordEmail: 'forgot-password',
  Financial: 'financial',
  PaymentHistory: 'history-subscription',
  Settings: 'settings',
  Lives: 'live',
  Complaint: 'complaint',
  Notification: 'notification-for-all',
  Posts: 'post',
  Channels: 'channels',
  Subchannels: 'subchannels',
  WhiteLabels: 'white-labels',
  HomeCards: 'home-cards',
  PostImage: 'post-image',
  Stocks: 'stocks',
  HomeCardsOrder: 'home-cards/change-order',
  HomeAds: 'home-ads',
  Reports: 'reports',
  ReportCategories: 'report-categories',
  ResearchCompanies: 'research-companies',
  Plans: 'plans',
  HomeInfo: 'post/countMonth'
})
