
  <div class="row">
    <div class="col-12">
      <div class="card card-inactive">
        <div class="card-body text-center my-6">
          <span class="display-3 fe fe-wind text-primary"></span>
          <h1>
            {{title}}
          </h1>
          <p class="text-muted">
            {{subtitle}}
          </p>
        </div>
      </div>
    </div>
  </div>
