<app-navbar [breadcrumbLink]="PathRoutes.Admins.List" [breadcrumbLinkName]="'Administradores'"
  [breadcrumbSubLink]="PathRoutes.Admins.Edit+'/'+id" [breadcrumbSubLinkName]="'Editar'"
  [breadcrumbCurrent]="'Alterar Senha'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Alterar senha"></app-header>
  <app-card>
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12">
          <app-input [form]="form" label="Senha atual" type="password" name="old_password" placeholder="Senha atual"
            [ngClass]="{'is-invalid': form.controls['old_password'].touched && !form.controls['old_password'].valid}"
            [messageCondition]="form.controls['old_password'].touched && !form.controls['old_password'].valid"
            message="Preencha a senha atual">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Nova senha" type="password" name="password" placeholder="Nova senha"
            [ngClass]="{'is-invalid': form.controls['password'].touched && !form.controls['password'].valid}"
            [messageCondition]="form.controls['password'].touched && !form.controls['password'].valid"
            message="Preencha a nova atual">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Confirmar nova senha" type="password" name="confirm_password"
            placeholder="Confirmar nova senha"
            [ngClass]="{'is-invalid': form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value}"
            [messageCondition]="form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value"
            message="As senhas não conferem">
          </app-input>
        </div>
      </div>
      <app-form-button [form]="form" [loading]="loading" label="Concluir"
        [disabled]="loading || form.invalid || form.controls.password.value != form.controls.confirm_password.value">
      </app-form-button>
    </form>
  </app-card>
</app-container>
