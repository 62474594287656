<app-navbar [breadcrumbCurrent]="'Casas de análise'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-md-10">
  <app-header title="Casas de análise" icon="fas fa-search" [buttonLink]="PathRoutes.ResearchCompanies.Add"
    buttonLabel="Adicionar" buttonIcon="fas fa-plus-circle mr-2"></app-header>
  <app-table [columns]="columns" [loading]="loading" [empty]="items.length == 0">
    <tr *ngFor="let item of items;" (click)="detail(item.id)" class="tr-link">
      <td width="5%">
        <div class="avatar avatar-sm" *ngIf="item?.image">
          <img [src]="environment.imageBaseUrl+item?.image" alt="..." class="avatar-img rounded-circle">
        </div>
        <span class="fas fa-user-circle text-primary display-3" *ngIf="!item?.image"></span>
      </td>
      <td width="20%">
        <span>{{ item?.title }}</span>
      </td>
      <td>
        <span>{{ item.description }}</span>
      </td>
    </tr>
  </app-table>
</app-container>
