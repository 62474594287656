import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { HomeCardsService } from './../../services/home-cards.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-banner-app',
  templateUrl: './banner-app.component.html',
  styleUrls: ['./banner-app.component.scss']
})
export class BannerAppComponent implements OnInit {
  columns = ['', 'Titulo']
  environment = environment
  PathRoutes = PathRoutes
  loading = false
  loadingMore = false
  items = []
  filters = {
    search: '',
    page: 1,
    type: 'user'
  }
  btnLoading = false
  moveEnabled = false

  // Orders
  order() {
    this.moveEnabled = !this.moveEnabled
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('Eventoooooooooo', event)
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  updateOrder() {
    let auxArray = this.items.map((elem, index) => ({ id: elem.id, order: index + 1 }))

    console.log('novo array', { order: auxArray })
    // return

    this.homeCardsService.order({ order: auxArray })
      .then((res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true)
          this.getItems()
          this.moveEnabled = false
        } else {
          this.helper.triggerNotification(false)
        }
      })
      .catch(e => console.log(e))
  }


  // End Order

  constructor(
    public helper: HelperService,
    private router: Router,
    private homeCardsService: HomeCardsService
  ) { }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.homeCardsService.list()
      .then((res: any) => {
        if (res.status) {
          this.items = res.data
        }
        this.filters.page++
      })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

  detail(id) {
    this.router.navigate([PathRoutes.BannerApp.Detail + `/${id}`])
  }
}
