import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private network: NetworkService) { }

  listReports() {
    return this.network.get(Endpoints.Reports)
  }

  listResearchCompanies() {
    return this.network.get(Endpoints.ResearchCompanies)
  }

  listReportCategories() {
    return this.network.get(Endpoints.ReportCategories)
  }

  showReports(id) {
    return this.network.get(`${Endpoints.Reports}/${id}`)
  }

  showResearchCompanies(id) {
    return this.network.get(`${Endpoints.ResearchCompanies}/${id}`)
  }

  showReportCategory(id) {
    return this.network.get(`${Endpoints.ReportCategories}/${id}`)
  }

  saveReports(info) {
    var params = info
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.Reports}/${id}/update`, params)
    } else {
      delete (params.id)
      return this.network.postMultipart(Endpoints.Reports, params)
    }
  }

  saveResearchCompanies(info) {
    var params = info
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.ResearchCompanies}/${id}/update`, params)
    } else {
      delete (params.id)
      return this.network.postMultipart(Endpoints.ResearchCompanies, params)
    }
  }

  saveReportCategory(info) {
    var params = info
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.ReportCategories}/${id}`, params)
    } else {
      delete (params.id)
      return this.network.postMultipart(Endpoints.ReportCategories, params)
    }
  }

  deleteReport(id) {
    return this.network.delete(`${Endpoints.Reports}/${id}`)
  }

  deleteResearchCompany(id) {
    return this.network.delete(`${Endpoints.ResearchCompanies}/${id}`)
  }

  deleteReportCategory(id) {
    return this.network.delete(`${Endpoints.ReportCategories}/${id}`)
  }

}
