import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { HelperService } from './../../helpers/helper.service';
import { LivesService } from './../../services/lives.service';

@Component({
  selector: 'app-google-permission',
  templateUrl: './google-permission.component.html',
  styleUrls: ['./google-permission.component.scss']
})
export class GooglePermissionComponent implements OnInit {
  code: any
  form: FormGroup
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private router: Router,
    private livesService: LivesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.code = this.activatedRoute.snapshot.queryParams['code'];
    this.setupForm()
    if (this.code) {
      this.form.patchValue({ code: this.code })
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'code': [this.code, Validators.required],
    })
  }

  save() {
    console.log(this.form.getRawValue())
    this.loading = true
    if (!this.form.invalid) {
      this.livesService.save(this.form.getRawValue())
        .then(async (res: any) => {
          if (res.status) {
            await this.livesService.getAccessToken(res.data.code)
            this.helper.triggerNotification(true);
            this.router.navigate([PathRoutes.Home])
          } else {
            console.log(res.error)
            this.helper.triggerNotification(false, 'Erro', res.error);
            this.loading = false
          }
        })
        .catch(e => {
          console.log(e)
          this.helper.triggerNotification(false, 'Erro', e);
          this.loading = false
        })
    }
  }

}
