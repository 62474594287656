import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private network: NetworkService) { }

  list() {
    return this.network.get(Endpoints.Plans)
  }

}
