<p>users-add works!</p>
<app-navbar *ngIf="!id" [breadcrumbLink]="PathRoutes.Users.List" [breadcrumbLinkName]="'Usuários'"
  [breadcrumbCurrent]="'Adicionar'"></app-navbar>
<app-navbar *ngIf="id" [breadcrumbLink]="PathRoutes.Users.List" [breadcrumbLinkName]="'Usuários'"
  [breadcrumbSubLink]="PathRoutes.Users.Detail+'/'+id" [breadcrumbSubLinkName]="'Detalhes'"
  [breadcrumbCurrent]="'Editar'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header [title]="id ? 'Editar Usuário' : 'Adicionar Usuário'"></app-header>
  <app-card [loading]="loadingAdmin">
    <form [formGroup]="form" (submit)="save()">
      <div class="row pb-5 pt-2" *ngIf="item?.profile_picture">
        <div class="col-12 d-flex justify-content-center">
          <img [src]="environment.imageBaseUrl+item?.profile_picture" class="img-fluid screenshot rounded-circle"
            width="150">
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-12 d-flex justify-content-center">
          <app-loading *ngIf="loadingImage"></app-loading>
        </div>
        <div class="col-12 d-flex flex-column" *ngIf="!loadingImage">
          <small>
            <span *ngIf="isFileValid" class="text-danger" style="cursor: pointer"
              (click)="cancelUpload()">Cancelar</span>
          </small>
          <div>
            <app-input-file [form]="form" [fileName]="fileName" (fileInput)="fileChangeEvent($event)"
              accept=".png, .jpg, .jpeg">
            </app-input-file>
            <img [src]="imgLoaded" id="preview" hidden />
          </div>
          <div *ngIf="isFileValid" class="row pt-3">
            <div class="col-md-6 col-12">
              <h4>Imagem original</h4>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
                format="jpg" backgroundColor="#FFFFFF" (imageCropped)="imageCropped($event)"
                [containWithinAspectRatio]="true">
              </image-cropper>
            </div>
            <div class="col-md-6 col-12">
              <h4>Imagem cortada</h4>
              <div class="text-center">
                <img [src]="croppedImage" style="border: 1px dashed #D2DDEC; width:100%; border-radius: 100%" />
              </div>
            </div>
          </div>
          <!-- <div *ngIf="!isFileValid" class="d-flex justify-content-center pt-4">
              <img [src]="item?.image_url ? environment.imageBaseUrl+item?.image_url : 'assets/img/placeholder.jpg'"
                class="img-fluid screenshot" style="max-width: 500px;">
            </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Nome" [required]="true" name="name" placeholder="Qual o nome?"
            [ngClass]="{'is-invalid': form.controls['name'].touched && !form.controls['name'].valid}"
            [messageCondition]="form.controls['name'].touched && !form.controls['name'].valid"
            message="Preencha o nome"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Nickname" name="nickname" placeholder="Insira um nickname"
            (input)="toLowerCase($event)" [dropCharacters]="false" [mask]="helper.masks.nickname"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Email" [required]="true" name="email" placeholder="Qual o email?"
            [ngClass]="{'is-invalid': form.controls['email'].touched && !form.controls['email'].valid}"
            [messageCondition]="form.controls['email'].touched && !form.controls['email'].valid"
            message="Preencha o email"></app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input [form]="form" label="Telefone (Whatsapp)" name="phone" placeholder="Qual o telefone?"
            [mask]="helper.masks.mobile" [required]="true"
            [ngClass]="{'is-invalid': form.controls['phone'].touched && !form.controls['phone'].valid}"
            [messageCondition]="form.controls['phone'].touched && !form.controls['phone'].valid"
            message="Preencha o telefone"></app-input>
        </div>
        <div class="col-12 col-md-6" *ngIf="!id">
          <app-input [form]="form" label="Senha" [required]="true" name="password" placeholder="Insira uma senha"
            type="password"
            [ngClass]="{'is-invalid': form.controls['password'].touched && !form.controls['password'].valid}"
            [messageCondition]="form.controls['password'].touched && !form.controls['password'].valid"
            message="Preencha a senha"></app-input>
        </div>
        <div class="col-12 col-md-6" *ngIf="!id">
          <app-input [form]="form" label="Confirmar senha" [required]="true" name="confirm_password"
            placeholder="Repita a senha" type="password"
            [ngClass]="{'is-invalid': form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value}"
            [messageCondition]="form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value"
            message="Senhas não conferem"></app-input>
        </div>
        <!-- <div class="col-12 col-md-6">
          <app-input [form]="form" label="Data de início" name="start_date" placeholder="Qual a data de início?"
            [required]="true" type="date"
            [ngClass]="{'is-invalid': form.controls['start_date'].touched && !form.controls['start_date'].valid}"
            [messageCondition]="form.controls['start_date'].touched && !form.controls['start_date'].valid"
            message="Preencha a data de início"></app-input>
        </div> -->
        <div class="col-12">
          <app-input [form]="form" label="Data de expiração" name="expiration_date"
            placeholder="Qual a data de expiração?" [required]="true" type="date"
            [ngClass]="{'is-invalid': form.controls['expiration_date'].touched && !form.controls['expiration_date'].valid}"
            [messageCondition]="form.controls['expiration_date'].touched && !form.controls['expiration_date'].valid"
            message="Preencha a data de expiração"></app-input>
        </div>
        <div class="col-12 text-center mb-4" *ngIf="message">
          <small class="text-danger">{{ message }}</small>
        </div>
      </div>
      <a class="btn btn-light d-block btn-block" *ngIf="id" routerLink="{{ PathRoutes.Users.ChangePassword }}/{{id}}">
        Alterar Senha
      </a>
      <app-form-button [loading]="loading"
        [disabled]="loading || form.invalid || (form.controls.password.value != form.controls.confirm_password.value && !id)"
        [form]="form" [label]="id ? 'Editar' : 'Concluir'"></app-form-button>
    </form>
  </app-card>
</app-container>
