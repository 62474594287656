import { Endpoints } from './../routes/endpoints';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private network: NetworkService) { }

  getHomeTitle() {
    return this.network.get(`${Endpoints.Settings}/home-title`)
  }

  getHomeImage() {
    return this.network.get(`${Endpoints.Settings}/home-image`)
  }

  saveHomeTitle(params) {
    return this.network.put(`${Endpoints.Settings}/home-title`, params)
  }

  saveHomeImage(params) {
    return this.network.postMultipart(`${Endpoints.Settings}/update/home-image`, params)
  }
}
