import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { session_key, token_key } from '../../infos.json'

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  SESSION_KEY = session_key
  TOKEN_KEY = token_key

  sessionBehavior = new BehaviorSubject<any>(this.getSession())
  sessionWatcher = this.sessionBehavior.asObservable()

  constructor() { }

  saveToken(token) {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  saveSession(params){
    localStorage.setItem(this.SESSION_KEY, JSON.stringify(params))
  }

  getSession(){
    return localStorage.getItem(this.SESSION_KEY) ? JSON.parse(localStorage.getItem(this.SESSION_KEY)) : null
  }

  destroySession(){
    localStorage.clear()
  }
}
