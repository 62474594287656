<div class="header mt-md-5">
  <div class="header-body border-0">
    <div class="row align-items-center">
      <div class="col">
        <h1 class="header-title font-weight-bold" *ngIf="title">
          <i *ngIf="icon" class="text-primary mr-2" [ngClass]="icon"></i>
          {{ title }}
          <small>
            <span *ngIf="counter" class="ml-2 mb-2 badge badge-soft-primary"
              style="font-size: 12px; border-radius: 100%;">{{counter}}</span>
          </small>
        </h1>
        <p class="header-subtitle" *ngIf="subtitle">
          {{ subtitle }}
        </p>
      </div>
      <div class="col-auto" *ngIf="custom">
        <ng-content></ng-content>
      </div>
      <div class="col-auto" *ngIf="buttonLabel">
        <app-button [link]="buttonLink" [icon]="buttonIcon" [label]="buttonLabel"></app-button>
      </div>
    </div>
  </div>
</div>
