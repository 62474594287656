// modules
import 'flatpickr/dist/flatpickr.css'
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlatpickrModule } from 'angularx-flatpickr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './routes/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { CKEditorModule } from 'ckeditor4-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { DragDropModule } from '@angular/cdk/drag-drop'

// configs
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

// components
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CardComponent } from './components/card/card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TableComponent } from './components/table/table.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ModalComponent } from './components/modal/modal.component';
import { FlatpickrComponent } from './components/flatpickr/flatpickr.component';
import { SelectCustomComponent } from './components/select-custom/select-custom.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { ContainerComponent } from './components/container/container.component';
import { ButtonComponent } from './components/button/button.component';

// pages
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { TermsOfUseEditComponent } from './pages/terms-of-use/terms-of-use-edit/terms-of-use-edit.component';
import { UsersComponent } from './pages/users/users.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { AdminsDetailComponent } from './pages/admins/admins-detail/admins-detail.component';
import { AdminsAddComponent } from './pages/admins/admins-add/admins-add.component';
import { AdminsChangePasswordComponent } from './pages/admins/admins-change-password/admins-change-password.component';
import { UsersDetailComponent } from './pages/users/users-detail/users-detail.component';
import { PasswordResetComponent } from './pages/login/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { GooglePermissionComponent } from './pages/google-permission/google-permission.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { BannerAppComponent } from './pages/banner-app/banner-app.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { PostsComponent } from './pages/posts/posts.component';
import { PostsAddComponent } from './pages/posts/posts-add/posts-add.component';
import { PostsDetailComponent } from './pages/posts/posts-detail/posts-detail.component';
import { YoutubeSettingsComponent } from './pages/youtube-settings/youtube-settings.component';
import { ChannelsComponent } from './pages/channels/channels.component';
import { UsersAddComponent } from './pages/users/users-add/users-add.component';
import { BannerAppAddComponent } from './pages/banner-app/banner-app-add/banner-app-add.component';
import { BannerAppDetailComponent } from './pages/banner-app/banner-app-detail/banner-app-detail.component';
import { InputFileCustomComponent } from './components/input-file-custom/input-file-custom.component';
import { HomeAdsComponent } from './pages/home-ads/home-ads.component';
import { ResearchCompaniesComponent } from './pages/research-companies/research-companies.component';
import { ResearchCompaniesAddComponent } from './pages/research-companies/research-companies-add/research-companies-add.component';
import { ResearchCompaniesDetailComponent } from './pages/research-companies/research-companies-detail/research-companies-detail.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportsAddComponent } from './pages/reports/reports-add/reports-add.component';
import { ReportsDetailComponent } from './pages/reports/reports-detail/reports-detail.component';
import { SubchannelsComponent } from './pages/subchannels/subchannels.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavbarComponent,
    SidebarComponent,
    CardComponent,
    LoadingComponent,
    BreadcrumbComponent,
    TableComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DropzoneComponent,
    FormButtonComponent,
    EmptyStateComponent,
    ModalComponent,
    FlatpickrComponent,
    SelectCustomComponent,
    InputFileComponent,
    ContainerComponent,
    ButtonComponent,

    AppComponent,
    HomeComponent,
    LoginComponent,
    NotFoundComponent,
    TermsOfUseComponent,
    TermsOfUseEditComponent,
    UsersComponent,
    AdminsComponent,
    AdminsDetailComponent,
    AdminsAddComponent,
    AdminsChangePasswordComponent,
    UsersDetailComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    GooglePermissionComponent,
    ComplaintsComponent,
    BannerAppComponent,
    NotificationsComponent,
    PostsComponent,
    PostsAddComponent,
    PostsDetailComponent,
    YoutubeSettingsComponent,
    ChannelsComponent,
    UsersAddComponent,
    BannerAppAddComponent,
    BannerAppDetailComponent,
    InputFileCustomComponent,
    HomeAdsComponent,
    ResearchCompaniesComponent,
    ResearchCompaniesAddComponent,
    ResearchCompaniesDetailComponent,
    ReportsComponent,
    ReportsAddComponent,
    ReportsDetailComponent,
    SubchannelsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FlatpickrModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    CKEditorModule,
    ImageCropperModule,
    NgbModule,
    NgxGalleryModule,
    DragDropModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
