import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeCardsService } from './../../../services/home-cards.service';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-research-companies-detail',
  templateUrl: './research-companies-detail.component.html',
  styleUrls: ['./research-companies-detail.component.scss']
})
export class ResearchCompaniesDetailComponent implements OnInit {
  id: any
  item: any
  loading = false
  environment = environment
  PathRoutes = PathRoutes

  constructor(
    private reportsService: ReportsService,
    private activatedRoute: ActivatedRoute,
    public helper: HelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getItem()
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.reportsService.showResearchCompanies(this.id)
      if (res.status) {
        this.item = res.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  async delete() {
    try {
      let swal = await this.helper.deleteSwal()
      if (swal.value) {
        let del = await this.reportsService.deleteResearchCompany(this.id)
        console.log(del)
        this.router.navigate([PathRoutes.ResearchCompanies.List])
      }
    } catch (error) {
      console.log(error)
    }
  }

}
