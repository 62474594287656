import { ComplaintsComponent } from './../pages/complaints/complaints.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminsAddComponent } from '../pages/admins/admins-add/admins-add.component';
import { AdminsChangePasswordComponent } from '../pages/admins/admins-change-password/admins-change-password.component';
import { AdminsDetailComponent } from '../pages/admins/admins-detail/admins-detail.component';
import { AdminsComponent } from '../pages/admins/admins.component';
import { GooglePermissionComponent } from '../pages/google-permission/google-permission.component';
import { HomeComponent } from '../pages/home/home.component';
import { ForgotPasswordComponent } from '../pages/login/forgot-password/forgot-password.component';
import { LoginComponent } from '../pages/login/login.component';
import { PasswordResetComponent } from '../pages/login/password-reset/password-reset.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { TermsOfUseEditComponent } from '../pages/terms-of-use/terms-of-use-edit/terms-of-use-edit.component';
import { TermsOfUseComponent } from '../pages/terms-of-use/terms-of-use.component';
import { UsersDetailComponent } from '../pages/users/users-detail/users-detail.component';
import { UsersComponent } from '../pages/users/users.component';
import { AuthService } from '../services/auth.service';
import { PathRoutes } from './path-routes';
import { BannerAppComponent } from '../pages/banner-app/banner-app.component';
import { NotificationsComponent } from '../pages/notifications/notifications.component';
import { PostsComponent } from '../pages/posts/posts.component';
import { PostsAddComponent } from '../pages/posts/posts-add/posts-add.component';
import { PostsDetailComponent } from '../pages/posts/posts-detail/posts-detail.component';
import { YoutubeSettingsComponent } from '../pages/youtube-settings/youtube-settings.component';
import { ChannelsComponent } from '../pages/channels/channels.component';
import { UsersAddComponent } from '../pages/users/users-add/users-add.component';
import { BannerAppAddComponent } from '../pages/banner-app/banner-app-add/banner-app-add.component';
import { BannerAppDetailComponent } from '../pages/banner-app/banner-app-detail/banner-app-detail.component';
import { HomeAdsComponent } from '../pages/home-ads/home-ads.component';
import { ReportsComponent } from '../pages/reports/reports.component';
import { ReportsAddComponent } from '../pages/reports/reports-add/reports-add.component';
import { ReportsDetailComponent } from '../pages/reports/reports-detail/reports-detail.component';
import { ResearchCompaniesComponent } from '../pages/research-companies/research-companies.component';
import { ResearchCompaniesAddComponent } from '../pages/research-companies/research-companies-add/research-companies-add.component';
import { ResearchCompaniesDetailComponent } from '../pages/research-companies/research-companies-detail/research-companies-detail.component';
import { SubchannelsComponent } from '../pages/subchannels/subchannels.component';

const routes: Routes = [

  // canActivate: [AuthService]

  { path: PathRoutes.Login.replace('/', ''), component: LoginComponent },
  { path: PathRoutes.Password.Reset.replace('/', '') + '/:token', component: PasswordResetComponent },
  { path: PathRoutes.Password.Forgot.replace('/', ''), component: ForgotPasswordComponent },

  { path: '', component: HomeComponent, canActivate: [AuthService] },
  { path: PathRoutes.Home.replace('/', ''), component: HomeComponent, canActivate: [AuthService] },

  { path: PathRoutes.Complaints.replace('/', ''), component: ComplaintsComponent, canActivate: [AuthService] },

  { path: PathRoutes.BannerApp.List.replace('/', ''), component: BannerAppComponent, canActivate: [AuthService] },
  { path: PathRoutes.BannerApp.Add.replace('/', ''), component: BannerAppAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.BannerApp.Edit.replace('/', '') + '/:id', component: BannerAppAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.BannerApp.Detail.replace('/', '') + '/:id', component: BannerAppDetailComponent, canActivate: [AuthService] },

  { path: PathRoutes.Reports.List.replace('/', ''), component: ReportsComponent, canActivate: [AuthService] },
  { path: PathRoutes.Reports.Add.replace('/', ''), component: ReportsAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.Reports.Edit.replace('/', '') + '/:id', component: ReportsAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.Reports.Detail.replace('/', '') + '/:id', component: ReportsDetailComponent, canActivate: [AuthService] },

  { path: PathRoutes.ResearchCompanies.List.replace('/', ''), component: ResearchCompaniesComponent, canActivate: [AuthService] },
  { path: PathRoutes.ResearchCompanies.Add.replace('/', ''), component: ResearchCompaniesAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.ResearchCompanies.Edit.replace('/', '') + '/:id', component: ResearchCompaniesAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.ResearchCompanies.Detail.replace('/', '') + '/:id', component: ResearchCompaniesDetailComponent, canActivate: [AuthService] },

  { path: PathRoutes.Notification.replace('/', ''), component: NotificationsComponent, canActivate: [AuthService] },

  { path: PathRoutes.Channels.List.replace('/', ''), component: ChannelsComponent, canActivate: [AuthService] },

  { path: PathRoutes.Subchannels.List.replace('/', ''), component: SubchannelsComponent, canActivate: [AuthService] },

  { path: PathRoutes.Posts.List.replace('/', ''), component: PostsComponent, canActivate: [AuthService] },
  { path: PathRoutes.Posts.Add.replace('/', ''), component: PostsAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.Posts.Edit.replace('/', '') + '/:id', component: PostsAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.Posts.Detail.replace('/', '') + '/:id', component: PostsDetailComponent, canActivate: [AuthService] },

  { path: PathRoutes.Admins.List.replace('/', ''), component: AdminsComponent, canActivate: [AuthService] },
  { path: PathRoutes.Admins.Add.replace('/', ''), component: AdminsAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.Admins.Edit.replace('/', '') + '/:id', component: AdminsAddComponent, canActivate: [AuthService] },
  { path: PathRoutes.Admins.Detail.replace('/', '') + '/:id', component: AdminsDetailComponent, canActivate: [AuthService] },
  { path: PathRoutes.Admins.ChangePassword.replace('/', '') + '/:id', component: AdminsChangePasswordComponent, canActivate: [AuthService] },

  { path: PathRoutes.Users.List.replace('/', ''), component: UsersComponent, canActivate: [AuthService] },
  { path: PathRoutes.Users.Detail.replace('/', '') + '/:id', component: UsersDetailComponent, canActivate: [AuthService] },
  { path: PathRoutes.Users.Add.replace('/', ''), component: UsersAddComponent, canActivate: [AuthService] },

  { path: PathRoutes.TermsOfUse.Show.replace('/', ''), component: TermsOfUseComponent, canActivate: [AuthService] },
  { path: PathRoutes.TermsOfUse.Edit.replace('/', ''), component: TermsOfUseEditComponent, canActivate: [AuthService] },

  { path: PathRoutes.GooglePermission.replace('/', ''), component: GooglePermissionComponent, canActivate: [AuthService] },
  { path: PathRoutes.YoutubeSettings.replace('/', ''), component: YoutubeSettingsComponent, canActivate: [AuthService] },

  { path: PathRoutes.HomeAds.replace('/', ''), component: HomeAdsComponent, canActivate: [AuthService] },

  // { path: PathRoutes.Cases.List.replace('/', ''), component: CasesComponent, canActivate: [AuthService] },
  // { path: PathRoutes.Cases.Deatil.replace('/', '') + '/:id', component: ChatComponent, canActivate: [AuthService] },

  // { path: PathRoutes.Financial.List.replace('/', ''), component: FinancialComponent, canActivate: [AuthService] },
  // { path: PathRoutes.Financial.Detail.replace('/', '') + '/:id', component: FinancialDetailComponent, canActivate: [AuthService] },

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
