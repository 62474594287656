<div class="form-group">
  <span *ngIf="!hasLogo && id" class="align-self-end text-danger" style="cursor: pointer"
    (click)="cancelUploadClicked()">Cancelar</span>
  <div class="custom-dropzone" ngx-dropzone [multiple]="false" (change)="onSelectClicked($event)" *ngIf="!hasLogo">
    <ngx-dropzone-label>Arraste seu logo aqui</ngx-dropzone-label>
    <ngx-dropzone-image-preview *ngFor="let f of files" ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true"
      (removed)="onRemoveClicked(f)"></ngx-dropzone-image-preview>
  </div>
  <div [formGroup]="form">
    <input type="hidden" [formControlName]="name" [(ngModel)]="imageUrl">
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="hasLogo">
    <div class="avatar-custom d-flex justify-content-center align-items-center" (click)="hasLogo = !hasLogo">
      <div class="avatar avatar-xxl avatar-4by3">
        <img [src]="itemImageUrl ? environment.imageBaseUrl+itemImageUrl : 'assets/img/placeholder.jpg'"
          class="avatar-img rounded">
      </div>
      <span class="change-logo">
        Alterar Logo
      </span>
    </div>
  </div>
</div>
