import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  terms: any

  constructor(private usersService: UsersService) {
  }

  ngOnInit() {
    this.getItem()
  }

  async getItem() {
    const res = await this.usersService.getTerms()
    this.terms = res.data.value
  }

}
