import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { NotificationService } from 'src/app/services/notification.service';
import { PlansService } from 'src/app/services/plans.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  id = null
  loading = false;
  message: any
  plans = []
  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private notificationService: NotificationService,
    private plansService: PlansService
  ) { }


  ngOnInit() {
    this.setupForm();
    this.getPlans()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "title": ['', Validators.compose([Validators.required])],
      "description": ['', Validators.compose([Validators.required])],
      "plan_id": [''],
    })
  }

  async getPlans() {
    try {
      let res: any = await this.plansService.list()
      if (res.status) {
        this.plans = res.data.map(e => ({ id: e.id, name: e.name }))
      }
    } catch (error) {
      console.log({ error })
    }
  }

  async save() {
    console.log(this.form.getRawValue())
    this.loading = true
    try {
      await this.notificationService.sendNotification(this.form.getRawValue())
      this.helper.triggerNotification(true, 'Notificação enviada', 'A notificação foi enviada com sucesso');
      this.setupForm()
    } catch (error) {
      this.helper.triggerNotification(false);
      console.log(error)
    } finally {
      this.loading = false
    }
  }

}
