<app-navbar [breadcrumbCurrent]="'Denúncias'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-md-10">
  <app-header title="Denúncias" icon="fas fa-bullhorn" [counter]="counter"></app-header>
  <app-table [columns]="columns" [more]="btnLoading" [loading]="loading" (loadMore)="loadMore()"
    (search)="search($event)" [empty]="items.length == 0" [hasSearch]="false">
    <tr *ngFor="let item of items;">
      <td width="5%">
        <span>{{ item?.id }}</span>
      </td>
      <td>
        <span>{{ item.owner?.name }}</span>
      </td>
      <td>
        <span>{{ item.user?.name }}</span>
      </td>
      <td>
        <span>{{ item.post?.title }}</span>
      </td>
      <td>
        <span *ngIf="item?.post?.deleted">
          Postagem exlcuida
          <i class="fas fa-times-circle text-danger"></i>
        </span>
        <span *ngIf="!item?.post?.deleted"></span>
      </td>
      <td class="text-end">
        <div class="dropdown"><a class="dropdown-toggle dropdown-ellipses" id="navbarAccount" data-toggle="dropdown"
            href="#" aria-haspopup="true" aria-expanded="false">
            <i class="fe fe-more-vertical"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" [routerLink]="PathRoutes.Posts.Detail + '/'+ item?.post?.id">
              <li class="dropdown-link">Ver postagem</li>
            </a>
            <button *ngIf="!item?.post?.deleted" class="dropdown-item btn-link" (click)="deletePost(item?.post?.id)">
              <li class="dropdown-link">Excluir postagem</li>
            </button>
          </div>
        </div>
      </td>
    </tr>
  </app-table>
</app-container>
