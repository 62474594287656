import { Endpoints } from './../../routes/endpoints';
import { Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  PathRoutes = PathRoutes
  items = []
  columns = ['Título', 'Descrição', 'Casa de análise', 'Data', 'Tipo', 'Categoria']
  loading = false

  constructor(
    private reportsService: ReportsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.getItems()
  }

  async getItems() {
    try {
      let res: any = await this.reportsService.listReports()
      if (res.status) {
        this.items = res.data
      }
    } catch (error) {
      console.log({ error })
    } finally {
      this.loading = false
    }
  }

  getType(t) {
    switch (t) {
      case 'report':
        return 'Relatórios'
      case 'recommended_wallet':
        return 'Carteira recomendada'
    }
  }

  detail(id) {
    this.router.navigate([`${PathRoutes.Reports.Detail}/${id}`])
  }
}
