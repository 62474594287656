<div class="form-group" [formGroup]="form">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <select [formControlName]="name" class="custom-select" data-toggle="select" [ngClass]="ngClass" (change)="changeEmitted($event)">
    <option value="">{{ placeholder }}</option>
    <option *ngFor="let option of options" [value]="optionsId ? option.id : option">
      {{ optionsId ? option.name : option }}</option>
  </select>
  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
