<app-navbar [breadcrumbLink]="PathRoutes.Users.List" [breadcrumbLinkName]="'Usuários'" [breadcrumbCurrent]="'Detalhes'">
</app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Detalhes do usuário" [custom]="true">
    <div class="form-group">
      <button class="btn mr-2" (click)="activeUser(item?.id, !item?.active)"
        [ngClass]="{'btn-success': !item?.active, 'btn-outline-secondary btn-del': item?.active}">
        <span [ngClass]="item?.active ? 'fas fa-times-circle mr-2' : 'fas fa-check-circle mr-2'"></span>
        {{item?.active ? 'Bloquear' : 'Desbloquear'}}
      </button>
      <button class="btn btn-danger" (click)="deleteUser(item?.id)">
        <span class="fas fa-times-circle mr-2"></span>
        Excluir
      </button>
    </div>
  </app-header>
  <app-card [loading]="loading">
    <div class="row">
      <div class="col-12 pb-5 pt-2 d-flex justify-content-center" *ngIf="item?.profile_picture">
        <img [src]="environment.imageBaseUrl+item?.profile_picture" class="img-fluid screenshot rounded-circle"
          width="150">
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Nome" [value]="item?.name"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Nickname" [value]="item?.nickname"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Email" [value]="item?.email"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Telefone" [value]="item?.phone | mask: helper.masks.mobile"></app-input>
      </div>
      <div class="col-12" *ngIf="item?.expiration_date">
        <app-input [disabled]="true" label="Data de expiração" [value]="item?.expiration_date | date: 'dd/MM/yyyy'">
        </app-input>
      </div>
      <!-- <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="CPF" [value]="item?.cpf | mask: helper.masks.cpf"></app-input>
      </div> -->
    </div>
    <!-- <div *ngIf="item?.address">
        <h2 class="mt-5">Informações de Endereço</h2>
        <hr class="my-4">
        <div class="row">
          <div class="col-md-3 m-b-15">
            <app-input [disabled]="true" label="CEP" [value]="item?.address?.zip_code | mask: helper.masks.cep">
            </app-input>
          </div>
          <div class="col-md-6 m-b-15">
            <app-input [disabled]="true" label="Endereço" [value]="item?.address?.street"></app-input>
          </div>
          <div class="col-md-3 m-b-15">
            <app-input [disabled]="true" label="Número" [value]="item?.address?.number"></app-input>
          </div>
          <div class="col-md-4 m-b-15">
            <app-input [disabled]="true" label="Bairro" [value]="item?.address?.neighborhood"></app-input>
          </div>
          <div class="col-md-4 m-b-15">
            <app-input [disabled]="true" label="Cidade" [value]="item?.address?.city"></app-input>
          </div>
          <div class="col-md-4 m-b-15">
            <app-input [disabled]="true" label="Estado" [value]="item?.address?.state"></app-input>
          </div>
        </div>
      </div>
      <h2 class="mt-5">Informações de Plano</h2>
      <hr class="my-4">
      <div class="row">
        <div class="col-12">\
          <app-card>
            <div class="row align-items-center">
              <div class="col">
                <div class="d-flex justify-content-between mb-4">
                  <h2 class="mb-2 text-primary">
                    {{ item?.plan?.title }}
                  </h2>
                  <h2 class="mb-2">
                    <small>
                      {{ item?.plan?.price | currency: 'BRL' }}
                    </small>
                  </h2>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <span class="h5 mb-0 text-primary">
                      {{ item?.plan?.limit - requestsLeft}} utilizados
                    </span>
                    <span class="h5 mb-0 text-muted">
                      {{ requestsLeft }} restantes
                    </span>
                </div>
                <div class="row align-items-center no-gutters">
                  <div class="col">
                    <div class="progress progress-sm">
                      <div class="progress-bar" role="progressbar" [style]="percentage"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-12 col-md-4">
          <app-input [disabled]="true" label="Plano" [value]="item?.plan?.title"></app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input [disabled]="true" label="Preço" [value]="item?.plan?.price | currency: 'BRL'"></app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input [disabled]="true" label="Restantes" [value]="requestsLeft+' casos'"></app-input>
        </div>
      </div> -->
    <div *ngIf="paymentHistory.length > 0">
      <h2 class="mt-5">Histórico de pagamento</h2>
      <hr class="my-4">
      <div class="list-group list-group-focus">
        <span class="list-group-item" *ngFor="let ph of paymentHistory;">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="text-body text-focus mb-1">
                {{ph.cost | currency: 'BRL'}}
              </h4>
              <p class="mb-0 text-muted">
                <small>
                  {{ph.date_created | date: 'dd/MM/YYYY'}}
                </small>
              </p>
            </div>
            <div class="col-auto">
              <i *ngIf="ph.status == 'paid'" class="fa fa-check-circle text-success"></i>
              <i *ngIf="ph.status == 'pending'" class="fa fa-clock text-warning"></i>
              <i *ngIf="ph.status == 'unpaid'" class="fa fa-times-circle text-danger"></i>
            </div>
          </div>
        </span>
      </div>
    </div>
  </app-card>
</app-container>