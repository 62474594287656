import { Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-file-custom',
  templateUrl: './input-file-custom.component.html',
  styleUrls: ['./input-file-custom.component.scss']
})
export class InputFileCustomComponent implements OnInit {

  @Input() fileName: string
  @Input() form: FormGroup = null
  @Input() label: string = null
  @Input() required: boolean = false
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() value: string = ''
  @Input() disabled: boolean = false
  @Input() hasFile: boolean = false
  @Input() hasCancel: boolean = false
  @Input() hasImage: boolean = false
  @Input() hasEdit: boolean = false
  @Input() accept: string
  @Input() index: any
  @Output() fileInput: EventEmitter<any> = new EventEmitter();
  @Output() cancelUpload: EventEmitter<any> = new EventEmitter();
  @Output() deleteImage: EventEmitter<any> = new EventEmitter();
  fn: string
  height: any

  constructor() { }

  ngOnInit(): void {
    this.fn = this.fileName || 'Clique aqui ou arraste para inserir o seu arquivo'
    this.calculateSizes()
  }

  ngOnChanges(changes) {
    this.fn = this.fileName || 'Clique aqui ou arraste para inserir o seu arquivo'
    this.calculateSizes()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateSizes()
  }

  fileClicked(event) {
    event.target.value = null
  }

  cancelUploadClicked() {
    this.cancelUpload.emit()
  }

  deleteImageClicked() {
    this.deleteImage.emit()
  }

  fileChange(event) {
    this.fileInput.emit(event)
    var f = event.target.files[0]
    this.fileName = f.name
  }
  @ViewChild('inputFileCustom', { static: true }) public inputFileCustom: ElementRef;

  calculateSizes() {
    setTimeout(() => {
      let width = this.inputFileCustom.nativeElement.offsetWidth
      this.height = `${width / 16 * 9}px`
    }, 250);
  }

}
