import { SessionService } from 'src/app/services/session.service';
import { PostsService } from 'src/app/services/posts.service';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { AdminsService } from 'src/app/services/admins.service';

@Component({
  selector: 'app-admins-add',
  templateUrl: './admins-add.component.html',
  styleUrls: ['./admins-add.component.scss'],
})
export class AdminsAddComponent implements OnInit {
  PathRoutes = PathRoutes;
  environment = environment;
  form: FormGroup;
  id = null;
  loading = false;
  loadingAdmin = false;
  loadingImage = false;
  image_url: any;
  fileName = 'Clique ou arraste aqui para inserir sua imagem';
  message: any;
  item: any;
  reg = /[^a-z0-9._@]/g;
  public customPatterns = { '0': { pattern: new RegExp(this.reg) } };
  session: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private usersService: UsersService,
    private postsService: PostsService
  ) {}

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.setupForm();
    this.getChannels();
    if (this.id) {
      this.getItem();
    }
  }

  async getChannels() {
    try {
      let res: any = await this.postsService.getChannels();
      this.channels = res.data.map((elem) => ({
        key: elem.id,
        label: elem.title,
        checked: true,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  getItem() {
    this.loadingAdmin = true;
    this.usersService
      .get(this.id)
      .then((res: any) => {
        if (res.status) {
          this.item = res.data;
          this.form.patchValue(res.data);
          let permissions = res.data.permissions
            .filter((elem) => !elem.channel_id)
            .map((elem) => elem.function);
          let channels = res.data.permissions
            .filter((elem) => elem.channel_id)
            .map((elem) => elem.channel_id);
          this.channels = this.channels.map((elem) => ({
            ...elem,
            checked: channels.includes(elem.key),
          }));
          this.permissionsArr = this.permissionsArr.map((elem) => ({
            ...elem,
            checked: permissions.includes(elem.key),
          }));
        }
      })
      .catch((e) => console.log(e))
      .finally(() => (this.loadingAdmin = false));
  }

  setupForm() {
    this.form = this.formBuilder.group({
      id: this.id,
      nickname: [''],
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: [
        '',
        Validators.compose(
          !this.id ? [Validators.required, Validators.minLength(8)] : []
        ),
      ],
      confirm_password: [''],
      type: 'admin',
      permissions: [[]],
      channels: [[]],
    });
  }

  channels = [];
  permissionsArr = [
    { key: 'app_home', checked: true, label: 'Home' },
    { key: 'lives', checked: true, label: 'Lives' },
    { key: 'users', checked: true, label: 'Usuários' },
    { key: 'admins', checked: true, label: 'Admins' },
    { key: 'channels', checked: true, label: 'Canais' },
    { key: 'complaints', checked: true, label: 'Denuncias' },
    { key: 'settings', checked: true, label: 'Banners e Termos de uso' },
    { key: 'notifications', checked: true, label: 'Notificação' },
    { key: 'youtube', checked: true, label: 'Youtube' },
    { key: 'home_ads', checked: true, label: 'Anúncio' },
    { key: 'research_house', checked: true, label: 'Casas de análise' },
    { key: 'posts', checked: true, label: 'Postagens' },
  ];

  toLowerCase(event) {
    event.target.value = event.target.value.toLowerCase();
  }

  save() {
    let params;
    if (this.image_url) {
      params = {
        ...this.form.getRawValue(),
        profilePicture: this.image_url,
      };
    } else {
      params = this.form.getRawValue();
    }

    if (params.id) {
      delete params.password;
      delete params.confirm_password;
    }

    let permissions = this.permissionsArr
      .filter((elem) => elem.checked)
      .map((elem) => elem.key);
    let channels = this.channels
      .filter((elem) => elem.checked)
      .map((elem) => elem.key);

    params.permissions = permissions;
    params.channels = channels;

    console.log(params);
    // return
    this.loading = true;

    if (!this.form.invalid) {
      this.usersService
        .save(params)
        .then((res: any) => {
          if (res.status) {
            this.helper.triggerNotification(true);
            this.router.navigate([
              PathRoutes.Admins.Detail + '/' + res.data.id,
            ]);
          } else {
            let p = [];
            Object.keys(res.errors).forEach((key) =>
              res.errors[key].map((e) => p.push(e))
            );
            this.helper.triggerNotification(
              false,
              'Erro',
              p.map((elem) => elem).join('\n'),
              null
            );
            this.loading = false;
          }
        })
        .catch((e) => {
          let p = [];
          Object.keys(e.error.errors).forEach((key) =>
            e.error.errors[key].map((r) => p.push(r))
          );
          this.helper.triggerNotification(
            false,
            'Erro',
            p.map((elem) => elem).join('\n'),
            null
          );
          this.loading = false;
        });
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isFileValid: any;

  fileChangeEvent(event: any): void {
    this.loadingImage = true;
    this.isFileValid = false;
    this.imageChangedEvent = event;
    let hiddenImage: any = document.getElementById('preview');
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.addEventListener(
      'load',
      function () {
        hiddenImage.src = reader.result;
      },
      false
    );

    setTimeout(() => {
      if (hiddenImage.height < 250) {
        this.helper.triggerNotification(
          false,
          'Erro',
          'Tamanho mínimo para altura é de 250px'
        );
        this.isFileValid = false;
      } else if (event.target.files[0].size > 2000000) {
        this.helper.triggerNotification(
          false,
          'Erro',
          'Tamanho máximo para imagem é de 2mb'
        );
        this.isFileValid = false;
      } else {
        this.isFileValid = true;
      }
      this.loadingImage = false;
    }, 1000);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.image_url = this.helper.dataURLtoFile(event.base64, 'profile.jpg');
  }

  cancelUpload() {
    this.image_url = null;
    this.isFileValid = false;
    this.fileName = 'Clique ou arraste aqui para inserir sua imagem';
  }

  permissionsChanged(e, i) {
    if (
      this.permissionsArr[i].key == 'posts' &&
      this.permissionsArr[i].checked
    ) {
      this.channels = this.channels.map((elem) => ({
        ...elem,
        checked: false,
      }));
    }
    this.permissionsArr[i].checked = !this.permissionsArr[i].checked;
  }

  channelsChanged(e, i) {
    this.channels[i].checked = !this.channels[i].checked;
  }
}
