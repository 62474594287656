import { Endpoints } from './../routes/endpoints';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class AdminsService {

  constructor(private network: NetworkService) { }

  // list(filters){
  //   var url = `${Endpoints.Admins}`
  //   return this.network.get(url)
  // }

  get(id){
    var url = `${Endpoints.Users}/${id}`
    return this.network.get(url)
  }

  save(params) {
    if(params.id) {
      var id = params.id
      delete(params.id)
      return this.network.put(`${Endpoints.Users}/${id}`, params)
    } else {
      delete(params.id)
      return this.network.post(Endpoints.Users, params)
    }
  }

}
