import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { PlansService } from 'src/app/services/plans.service';
import { PostsService } from 'src/app/services/posts.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-channels',
  templateUrl: './subchannels.component.html',
  styleUrls: ['./subchannels.component.scss'],
})
export class SubchannelsComponent implements OnInit {
  columns = ['Nome', ''];
  environment = environment;
  items = [];
  channels = [];
  loading = false;
  hasItem = false;
  loadingImage = false;
  picture: any;
  form: FormGroup;
  plans = [];
  session: any;

  constructor(
    private postsService: PostsService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    public helper: HelperService,
    private plansService: PlansService
  ) {}

  ngOnInit(): void {
    this.session = this.sessionService.getSession();
    this.getChannels();
    this.getItems();
    this.setupForm();
    this.getPlans();
  }

  async getPlans() {
    try {
      let res: any = await this.plansService.list();
      if (res.status) {
        this.plans = res.data.map((e) => ({
          id: e.id,
          name: e.name,
          checked: false,
        }));
      }
    } catch (error) {
      console.log({ error });
    }
  }

  plansChanged(plan, event) {
    plan.checked = event.target.checked;
  }

  async getChannels() {
    try {
      let res = await this.postsService.getChannels();
      let channels = res.data.filter(
        (elem) =>
          elem.id != 1 && elem.white_label_id == this.session.white_label_id
      );
      this.channels = channels.map((elem) => ({
        id: elem.id,
        name: elem.title,
      }));
      this.channelsObj = {};
    } catch (error) {
      console.log(error);
    }
  }

  async getItems() {
    this.loading = true;
    try {
      let res = await this.postsService.getSubChannels();
      if (res.status) {
        this.items = res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  setupForm() {
    this.hasItem = false;
    this.form = this.formBuilder.group({
      id: null,
      title: [null, Validators.required],
      channel_id: [null, Validators.required],
    });
    this.plans.forEach((plan) => {
      plan.checked = false;
    });
  }

  getItem(id) {
    this.hasItem = true;
    this.postsService
      .showSubChannel(id)
      .then((res: any) => {
        if (res.status) {
          this.form.get('id').setValue(res.data.id);
          this.form.patchValue(res.data);
          this.plans.forEach((plan) => plan.checked = false);
          res.data.plans_permitted.forEach((plan_permitted) => {
            let planIndex = this.plans.findIndex((e) => e.id == plan_permitted.plan.id);
            if (planIndex != -1) {
              this.plans[planIndex].checked = true;
            }
          });
        }
      })
      .catch((e) => console.log(e));
  }

  save() {
    let params = {
      ...this.form.getRawValue(),
      plans: this.plans.filter(e => e.checked).map(e => e.id),
    };
    this.loading = true;
    this.postsService
      .saveSubChannel(params)
      .then((res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true);
          window.location.reload();
          this.getItems();
        } else {
          this.helper.triggerNotification(false);
        }
        $('#modalChannels').modal('hide');
      })
      .catch((e) => console.log(e))
      .finally(() => (this.loading = false));
  }

  async deleteChannel(id) {
    try {
      let swal = await this.helper.deleteSwal(
        'As postagens referentes a esse canal também serão excluídas'
      );
      if (swal.value) {
        let res: any = await this.postsService.deleteSubChannel(id);
        if (res.status) {
          this.helper.triggerNotification(
            true,
            'Sucesso',
            'Canal excluído com sucesso'
          );
          this.getItems();
        } else {
          this.helper.triggerNotification(
            false,
            'Erro',
            'Ocorreu um erro, por favor tente novamente'
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  channelsObj: { [id: number]: any } = {};
  getChannelName(channel_id) {
    if (this.channelsObj[channel_id]) {
      return this.channelsObj[channel_id].name;
    }
    const channel = this.channels.find((elem) => elem.id == channel_id);
    if (channel) this.channelsObj[channel_id] = channel;
    return channel ? channel.name : '';
  }
}
