<app-navbar [breadcrumbLink]="PathRoutes.Reports.List" [breadcrumbLinkName]="'Relatórios'"
  [breadcrumbCurrent]="'Detalhes'">
</app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Detalhes do relatório" [buttonLink]="PathRoutes.Reports.Edit +'/'+ id" buttonLabel="Editar"
    buttonIcon="fas fa-edit mr-2" [custom]="true">
    <button class="btn btn-danger" (click)="delete()">
      <span class="fas fa-times-circle mr-2"></span>
      Excluir
    </button>
  </app-header>
  <app-card [loading]="loading">
    <div class="row">
      <div class="col-12">
        <app-input [disabled]="true" label="Título" [value]="item?.title"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Tipo" [value]="getType(item?.type)"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Categoria" [value]="item?.category?.title"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Casa de análise" [value]="item?.research_company?.title"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Data" [value]="item?.date | date: 'dd/MM/yyyy'"></app-input>
      </div>
      <div class="col-12">
        <app-textarea [disabled]="true" label="Descrição" [value]="item?.description"></app-textarea>
      </div>
      <div class="col-12">
        <iframe style="width: 100%; height: 40vw" [src]="src"></iframe>
      </div>
    </div>
  </app-card>
</app-container>
