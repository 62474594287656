import { AccountService } from 'src/app/services/account.service';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  token: any
  email: any
  type: any

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private router: Router,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.email = this.activatedRoute.snapshot.queryParams['email'];
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    console.log(this.token, this.email, this.type)
    this.form = this.formBuilder.group({
      'email': [this.email],
      'token': [this.token],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      'password_confirmation': ''
    })
  }

  passwordReset() {
    this.loading = true;
    this.accountService.changePassword(this.form.getRawValue()).then((res: any) => {
      if (res.status) {
        if (this.type == 'admin') {
          this.helper.triggerNotification(true, 'Senha Alterada', res.message);
          this.router.navigate([PathRoutes.Login])
        } else {
          this.router.navigate([PathRoutes.PasswordResetSuccess])
        }
      } else {
        this.helper.triggerNotification(false, 'Erro', res.error);
        this.loading = false
      }
    })
  }
}
