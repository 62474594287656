<div class="d-flex align-items-center border-top border-top-2 border-primary vh-100">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <!-- <div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
        <div class="text-center">
          <img src="assets/img/doctor_plan.svg" alt="Chefe de Plantão" class="img-fluid">
        </div>
      </div> -->
      <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
        <h1 class="display-4 text-center mb-3">
          Alterar Senha
        </h1>
        <p class="text-center mb-5">
          Preencha os campos abaixos
        </p>
        <form [formGroup]="form" (submit)="passwordReset()">
          <div class="form-group">
            <label>Nova Senha</label>
            <input type="password" formControlName="password" class="form-control" placeholder="Nova Senha"
              [ngClass]="{'is-invalid': form.controls.password.touched && form.controls.password.invalid}">
            <small class="text-danger" *ngIf="form.controls.password.touched && form.controls.password.invalid">Digite
              uma senha</small>
          </div>
          <div class="form-group">
            <label>Confirmar Nova Senha</label>
            <input type="password" formControlName="password_confirmation" class="form-control"
              placeholder="Confirmar Nova Senha"
              [ngClass]="{'is-invalid': form.controls.password_confirmation.touched && form.controls.password.value != form.controls.password_confirmation.value}">
            <small class="text-danger"
              *ngIf="form.controls.password_confirmation.touched && form.controls.password.value != form.controls.password_confirmation.value">Senhas
              não conferem</small>
          </div>
          <button class="btn btn-lg btn-block btn-primary mb-3 mt-4" [disabled]="form.invalid || loading">
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Concluir
          </button>
          <div class="text-center">
            <small>
              Lembrou de sua senha? <a routerLink="{{PathRoutes.Login}}">Acesse.</a>
            </small>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
