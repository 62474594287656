import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { PathRoutes } from '../routes/path-routes';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  baseUrl = environment.baseUrl

  constructor(
    private http: HttpClient,
    private router: Router,
    private session: SessionService
  ) { }

  post(endPoint, params, sendBearerToken = true, whiteLabelId = true) {
    let p = params
    if (this.session.getSession()?.white_label_id) {
      p = whiteLabelId ? { ...params, white_label_id: this.session.getSession()?.white_label_id } : p
    }
    return this.http.post(this.baseUrl + endPoint, p, { headers: this.headers(false, sendBearerToken) }).toPromise()
      .catch(e => {
        if (e.error.message == 'Unauthenticated.') {
          localStorage.clear()
          this.router.navigate([PathRoutes.Login])
        }
      })
  }

  get(endPoint, sendBearerToken = true, forceToken = null, whiteLabelId = null) {
    let white_label = ''
    if (this.session.getSession()?.white_label_id) {
      white_label = endPoint.includes('?') ? `&white_label_id=${this.session.getSession()?.white_label_id}` : `?white_label_id=${this.session.getSession().white_label_id}`
    }
    return this.http.get<any>(this.baseUrl + endPoint + white_label, { headers: this.headers(false, sendBearerToken, forceToken) }).toPromise()
      .catch(e => {
        if (e.error.message == 'Unauthenticated.') {
          localStorage.clear()
          this.router.navigate([PathRoutes.Login])
        }
      })
  }
  put(endPoint, params) {
    return this.http.put(this.baseUrl + endPoint, { ...params, white_label_id: this.session.getSession()?.white_label_id || '' }, { headers: this.headers() }).toPromise()
      .catch(e => {
        if (e.error.message == 'Unauthenticated.') {
          localStorage.clear()
          this.router.navigate([PathRoutes.Login])
        }
      })
  }
  delete(endPoint) {
    return this.http.delete(this.baseUrl + endPoint, { headers: this.headers() }).toPromise()
      .catch(e => {
        if (e.error.message == 'Unauthenticated.') {
          localStorage.clear()
          this.router.navigate([PathRoutes.Login])
        }
      })
  }

  makeExternalRequest(endPoint, params, type) {
    switch (type) {
      case "post":
        return this.http.post(endPoint, params).toPromise();

      case "get":
        return this.http.get<any>(endPoint).toPromise();

      case "put":
        return this.http.put(endPoint, params).toPromise();
    }
  }

  postMultipart(endPoint, params) {
    // var token = localStorage.getItem('token')
    // console.log(token)
    // if (token == null) {
    //   token = ''
    // }
    return this.http.post<any>(this.baseUrl + endPoint, this.makeItMultipartParams({ ...params, white_label_id: this.session.getSession()?.white_label_id || '' }), { headers: this.headers(true) }).toPromise()

  }

  putMultipart(endPoint, params) {
    // var token = localStorage.getItem('access_token')
    // console.log(token)
    // if (token == null) {
    //   token = ''
    // }
    return this.http.put<any>(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers(true) }).toPromise()

  }

  makeItMultipartParams(params) {

    var p = new FormData()
    Object.keys(params).forEach(function (key, index) {
      if (Array.isArray(params[key])) {
        params[key].map(r => {
          p.append(`${key}[]`, r)
        })
      } else {
        // console.log(key)
        // console.log(params[key])
        p.append(key, params[key])
      }

    });
    return p
  }

  headers(multipart = false, sendBearerToken = true, forceToken = null) {

    var r = this.session.getSession()

    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (multipart) {
      delete (headers['Content-Type'])
    }
    if (r && sendBearerToken) {
      headers['Authorization'] = `Bearer ${r.access_token}`
    }
    if (forceToken && sendBearerToken) {
      headers['Authorization'] = `Bearer ${forceToken}`
    }

     var h = {
      headers: headers
    }
    return headers
  }

}
