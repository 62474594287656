<app-navbar *ngIf="!id" [breadcrumbLink]="PathRoutes.Admins.List" [breadcrumbLinkName]="'Administradores'"
  [breadcrumbCurrent]="'Adicionar'"></app-navbar>
<app-navbar *ngIf="id" [breadcrumbLink]="PathRoutes.Admins.List" [breadcrumbLinkName]="'Administradores'"
  [breadcrumbSubLink]="PathRoutes.Admins.Detail+'/'+id" [breadcrumbSubLinkName]="'Detalhes'"
  [breadcrumbCurrent]="'Editar'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header [title]="id ? 'Editar Administrador' : 'Adicionar Administrador'"></app-header>
  <app-card [loading]="loadingAdmin">
    <form [formGroup]="form" (submit)="save()">
      <div class="row pb-5 pt-2" *ngIf="item?.profile_picture">
        <div class="col-12 d-flex justify-content-center">
          <img [src]="environment.imageBaseUrl+item?.profile_picture" class="img-fluid screenshot rounded-circle"
            width="150">
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-12 d-flex justify-content-center">
          <app-loading *ngIf="loadingImage"></app-loading>
        </div>
        <div class="col-12 d-flex flex-column" *ngIf="!loadingImage">
          <small>
            <span *ngIf="isFileValid" class="text-danger" style="cursor: pointer"
              (click)="cancelUpload()">Cancelar</span>
          </small>
          <div>
            <app-input-file [form]="form" [fileName]="fileName" (fileInput)="fileChangeEvent($event)"
              accept=".png, .jpg, .jpeg">
            </app-input-file>
            <img [src]="imgLoaded" id="preview" hidden />
          </div>
          <div *ngIf="isFileValid" class="row pt-3">
            <div class="col-md-6 col-12">
              <h4>Imagem original</h4>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
                format="jpg" backgroundColor="#FFFFFF" (imageCropped)="imageCropped($event)"
                [containWithinAspectRatio]="true">
              </image-cropper>
            </div>
            <div class="col-md-6 col-12">
              <h4>Imagem cortada</h4>
              <div class="text-center">
                <img [src]="croppedImage" style="border: 1px dashed #D2DDEC; width:100%; border-radius: 10px" />
              </div>
            </div>
          </div>
          <!-- <div *ngIf="!isFileValid" class="d-flex justify-content-center pt-4">
              <img [src]="item?.image_url ? environment.imageBaseUrl+item?.image_url : 'assets/img/placeholder.jpg'"
                class="img-fluid screenshot" style="max-width: 500px;">
            </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <app-input [form]="form" label="Nome" [required]="true" name="name" placeholder="Nome"
            [ngClass]="{'is-invalid': form.controls['name'].touched && !form.controls['name'].valid}"
            [messageCondition]="form.controls['name'].touched && !form.controls['name'].valid"
            message="Preencha o nome"></app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input [form]="form" label="Nickname" name="nickname" placeholder="Nickname" (input)="toLowerCase($event)"
            [dropCharacters]="false" [mask]="helper.masks.nickname"></app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input [form]="form" label="Email" [required]="true" name="email" placeholder="Email"
            [ngClass]="{'is-invalid': form.controls['email'].touched && !form.controls['email'].valid}"
            [messageCondition]="form.controls['email'].touched && !form.controls['email'].valid"
            message="Preencha o email"></app-input>
        </div>
        <div class="col-12 col-md-6" *ngIf="!id">
          <app-input [form]="form" label="Senha" [required]="true" name="password" placeholder="Senha" type="password"
            [ngClass]="{'is-invalid': form.controls['password'].touched && !form.controls['password'].valid}"
            [messageCondition]="form.controls['password'].touched && !form.controls['password'].valid"
            message="Preencha a senha"></app-input>
        </div>
        <div class="col-12 col-md-6" *ngIf="!id">
          <app-input [form]="form" label="Confirmar senha" [required]="true" name="confirm_password"
            placeholder="Confirme sua senha" type="password"
            [ngClass]="{'is-invalid': form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value}"
            [messageCondition]="form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value"
            message="Senhas não conferem"></app-input>
        </div>
        <div class="col-12 pb-3">
          <label for="">Permissões</label>
          <div class="form-check" *ngFor="let p of permissionsArr; let i = index">
            <input class="form-check-input" type="checkbox" value="" [id]="p.key" [checked]="p.checked"
              (change)="permissionsChanged($event, i)">
            <label class="form-check-label" [for]="p.key">
              {{p.label}}
            </label>
            <div *ngIf="p.key == 'posts' && p.checked">
              <div class="form-check" *ngFor="let c of channels; let i = index">
                <input class="form-check-input" type="checkbox" value="" [id]="c.key" [checked]="c.checked"
                  (change)="channelsChanged($event, i)">
                <label class="form-check-label" [for]="c.key">
                  {{c.label}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mb-4" *ngIf="message">
          <small class="text-danger">{{ message }}</small>
        </div>
      </div>
      <a class="btn btn-light d-block btn-block" *ngIf="id" routerLink="{{ PathRoutes.Admins.ChangePassword }}/{{id}}">
        Alterar Senha
      </a>
      <app-form-button [loading]="loading"
        [disabled]="loading || form.invalid || (form.controls.password.value != form.controls.confirm_password.value && !id)"
        [form]="form" [label]="id ? 'Editar' : 'Concluir'"></app-form-button>
    </form>
  </app-card>
</app-container>
