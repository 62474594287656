import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { PostsService } from 'src/app/services/posts.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from './../../services/users.service';
// import { FinancialService } from 'src/app/services/financial.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  PathRoutes = PathRoutes
  postsTotal = 0
  usersTotal = 0
  adminsTotal = 0
  balance = 0
  fee = 0
  // accessing favicon link tag
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  session: any
  permissions = {
    app_home: false,
    lives: false,
    users: false,
    admins: false,
    channels: false,
    posts: false,
    complaints: false,
    banner: false,
    notifications: false,
    settings: false,
    youtube: false,
  }

  constructor(
    private usersService: UsersService,
    private postsService: PostsService,
    public helper: HelperService,
    private sessionService: SessionService,
    private titleService: Title
    // private financialService: FinancialService
  ) {
    this.titleService.setTitle(this.sessionService.getSession()?.white_label?.app_name);
    document.documentElement.style.setProperty('--primary-color', this.sessionService.getSession()?.white_label?.primary_color)
    this.favIcon.href = environment.imageBaseUrl + this.sessionService.getSession()?.white_label?.icon_path;
  }


  ngOnInit(): void {
    this.session = this.sessionService.getSession()
    if (this.session.permissions) {
      let permissions = this.session?.permissions.map(elem => elem.function)
      this.permissions = permissions.reduce((a, v) => ({ ...a, [v]: true }), {})
    }
    this.getUsers()
    // this.getPosts()
    this.getInfo()
    // document.location.reload()
    // this.getBalance()
  }

  // getBalance() {
  //   this.ordersService.list({ per_page: 10000 })
  //     .then((res: any) => {
  //       if (res.status) {
  //         let currentMonth = new Date().getMonth()
  //         let d = res.data
  //           .filter(elem => {
  //             let split = elem.created_at.split(' ')[0].split('-')
  //             return split[1] == currentMonth + 1 && elem.status != 'cancelado'
  //           })
  //         this.balance = d.reduce((accumulator, currentValue) => accumulator += currentValue.value, 0)
  //         this.fee = d.reduce((accumulator, currentValue) => accumulator += currentValue.convenience_fee, 0)
  //       }
  //     })
  //     .catch(e => console.log(e))
  // }

  async getInfo() {
    let res = await this.usersService.homeInfo()
    console.log({ res })
    this.usersTotal = res['users']
    this.postsTotal = res['posts']
  }

  getUsers() {
    this.usersService.list({ per_page: 10000 })
      .then((res: any) => {
        if (res.status) {
          // this.usersTotal = res.data.filter(elem => elem.type == 'user' && this.helper.getCurrentDate(elem.created_at)).length
          this.adminsTotal = res.data.filter(elem => elem.type == 'admin').length
        }
      })
      .catch(e => console.log(e))
  }

  async getPosts() {
    try {
      const res = await this.postsService.list({})
      if (res.status) {
        this.postsTotal = res.data.filter(elem => this.helper.getCurrentDate(elem.created_at)).length
      }
    } catch (error) {
      console.log(error)
    }
  }

}
