import { HomeCardsService } from './../../../services/home-cards.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';
declare var $: any
@Component({
  selector: 'app-banner-app-add',
  templateUrl: './banner-app-add.component.html',
  styleUrls: ['./banner-app-add.component.scss']
})
export class BannerAppAddComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  form: FormGroup
  id = null
  loading = false
  ufs = []
  files = []
  hasLogo = false
  image_url: any
  item: any
  establishment_id: any
  price: any
  categories = []
  activeOptions = [
    { id: 1, name: 'Sim' },
    { id: 0, name: 'Não' }
  ]
  hasItem
  promotionalPrice = false
  percentage = 0
  discount = null
  category_name = null
  fileName = 'Clique ou arraste aqui para inserir sua imagem'
  audioFileName = 'Clique ou arraste aqui para inserir seu audio'
  loadingImage = false
  imgLoaded
  audio
  submitting = false

  constructor(
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private settingsService: SettingsService,
    private homeCardsService: HomeCardsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.setupForm()
    if (this.id) {
      this.getItem()
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": [this.id],
      "title": [null, Validators.required],
      "description": [null, Validators.required],
      "image": [this.image_url],
      "audio": [this.audio],
      "timer": [null, Validators.compose([Validators.min(3), Validators.max(10)])],
    })
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.homeCardsService.show(this.id)
      res.data.timer = res.data.timer / 1000
      this.item = res.data
      this.form.patchValue(res.data)
    } catch (error) {
      console.log(error)
      this.loading = false
    } finally {
      this.loading = false
    }
  }

  async save() {
    try {
      let params = this.form.getRawValue()
      if (this.image_url) {
        params.image = this.image_url
      } else {
        delete (params.image)
      }
      if (this.audio) {
        params.audio = this.audio
      } else {
        delete (params.audio)
      }
      if (params.timer) {
        params.timer = params.timer * 1000
      } else {
        delete (params.timer)
      }
      console.log(params)
      // return
      this.submitting = true
      let res = await this.homeCardsService.save(params)
      if (res.status) {
        this.helper.triggerNotification(true)
        this.router.navigate([PathRoutes.BannerApp.Detail + '/' + res.data.id])
      } else {
        this.helper.triggerNotification(false)
      }
    } catch (error) {
      this.helper.triggerNotification(false)
      console.log(error)
      this.submitting = false
    } finally {
      this.submitting = false
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isFileValid: any

  audioFileChangeEvent(event: any) {
    this.audio = event.target.files[0]
    this.audioFileName = event.target.files[0].name

    setTimeout(() => {
      let audio: any = document.getElementById('audio')
      let reader = new FileReader
      reader.readAsDataURL(event.target.files[0])

      reader.addEventListener("load", function () {
        audio.src = reader.result;
      }, false);
    }, 1000);
  }

  cancelAudioUpload() {
    this.audio = null
    this.audioFileName = 'Clique ou arraste aqui para inserir seu audio'
    let audio: any = document.getElementById('audio')
    audio.src = this.item?.audio ? environment.imageBaseUrl + this.item?.audio : ''
  }

  fileChangeEvent(event: any): void {
    this.loadingImage = true
    this.isFileValid = false
    this.imageChangedEvent = event
    let hiddenImage: any = document.getElementById('preview')
    let reader = new FileReader
    reader.readAsDataURL(event.target.files[0])

    reader.addEventListener("load", function () {
      hiddenImage.src = reader.result;
    }, false);

    setTimeout(() => {
      if (hiddenImage.height < 250) {
        this.helper.triggerNotification(false, 'Erro', 'Tamanho mínimo para altura é de 250px')
        this.isFileValid = false
      } else if (event.target.files[0].size > 1000000) {
        this.helper.triggerNotification(false, 'Erro', 'Tamanho máximo para imagem é de 1mb')
        this.isFileValid = false
      } else {
        this.isFileValid = true
      }
      this.loadingImage = false
    }, 1000)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.image_url = this.helper.dataURLtoFile(event.base64, 'banner.jpg')
  }

  cancelUpload() {
    this.image_url = null;
    this.isFileValid = false
    this.fileName = 'Clique ou arraste aqui para inserir sua imagem'
  }
}
