import { SessionService } from './../../services/session.service';
import { UsersService } from 'src/app/services/users.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from './../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from './../../helpers/helper.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  columns = ['', 'Nome', 'Email', 'Status', 'Desde de']
  environment = environment
  PathRoutes = PathRoutes
  loading = false
  loadingMore = false
  items = []
  filters = {
    search: '',
    page: 1,
    type: 'user',
    dateFrom: '',
    dateTo: '',
    plan_status: ''
  }
  planStatus = [
    { key: 'own', label: 'Gratuíto' },
    { key: 'paid', label: 'Pago' },
    { key: 'pending', label: 'Pendente' },
    { key: 'backoffice_manual_add', label: 'Backoffice' },
  ]
  btnLoading = false

  constructor(
    public helper: HelperService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.getItems();
    this.session = this.sessionService.getSession()
  }

  session: any

  planStatusChange(e) {
    this.filters.plan_status = e.target.value
    this.getItems()
  }

  getPlanStatus(s) {
    switch (s) {
      case 'paid':
        return 'Pago'
      case 'own':
        return 'Gratuíto'
      case 'pending':
        return 'Pendente'
      case 'backoffice_manual_add':
        return 'Backoffice'
      default:
        return
    }
  }

  getItems(loadingMore = false) {

    if (!loadingMore) {
      this.filters.page = 1
      this.loading = true
    } else {
      this.loadingMore = true
    }

    this.usersService.list(this.filters)
      .then((res: any) => {
        if (res.status) {
          this.btnLoading = res.next_page_url ? true : false
          let data = res.data.map(elem => ({ ...elem, payment_status: this.getPlanStatus(elem.plan_status) }))
          this.items = loadingMore ? this.items.concat(data) : data
        }
        this.filters.page++
      })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
        this.loadingMore = false
      })
  }

  loadMore() {
    this.getItems(true)
  }

  search(string) {
    this.filters.search = string
    this.getItems(false)
  }

  detail(id) {
    this.router.navigate([PathRoutes.Users.Detail + `/${id}`])
  }

  dateChange(event) {
    var date = event.target.value.split(' ');
    // this.filters.dateFrom = date[0] ? date[0] + ' 00:00:01' : '';
    // this.filters.dateTo = date[2] ? date[2] + ' 23:59:59' : '';
    this.filters.dateFrom = date[0]
    this.filters.dateTo = date[2]
    this.getItems()
  }

  exportUsers(e) {
    e.preventDefault()
    window.open(`https://api.4traders.in/export/users/${this.session?.id}?date_from=${this.filters.dateFrom || ''}&date_to=${this.filters.dateTo || ''}&plan_status=${this.filters.plan_status || ''}&search=${this.filters.search || ''}`)
  }

}
