<app-navbar *ngIf="!id" [breadcrumbCurrent]="'Adicionar'" [breadcrumbLink]="PathRoutes.Posts.List"
  [breadcrumbLinkName]="'Postagens'"></app-navbar>
<app-navbar *ngIf="id" [breadcrumbCurrent]="'Editar'" [breadcrumbLink]="PathRoutes.Posts.List"
  [breadcrumbSubLink]="PathRoutes.Posts.Detail + '/'+ id" [breadcrumbSubLinkName]="'Detalhes'"
  [breadcrumbLinkName]="'Postagens'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header [title]="id ? 'Editar postagem' : 'Adicionar postagem'"></app-header>
  <app-card>
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12">
          <app-select label="Tipo de postagem" placeholder="Selecione o tipo de postagem" [form]="form" name="type"
            [options]="types" [optionsId]="true" (change)="changePostType($event)"
            [ngClass]="{'is-invalid': form.controls.type.touched && form.controls.type.invalid}"
            [messageCondition]="form.controls.type.touched && form.controls.type.invalid"
            message="Selecione o tipo de postagem">
          </app-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-input [form]="form" label="Título" [required]="true" name="title" placeholder="Nome"
            [ngClass]="{'is-invalid': form.controls['title'].touched && !form.controls['title'].valid}"
            [messageCondition]="form.controls['title'].touched && !form.controls['title'].valid"
            message="Preencha o título"></app-input>
        </div>
        <div class="col-12">
          <app-select label="Comunidade" placeholder="Selecione a comunidade" [form]="form" name="channel_id" [options]="channels"
            [optionsId]="true"
            [ngClass]="{'is-invalid': form.controls.channel_id.touched && form.controls.channel_id.invalid}"
            [messageCondition]="form.controls.channel_id.touched && form.controls.channel_id.invalid"
            (change)="changeSubChannels()"
            message="Selecione a comunidade">
          </app-select>
        </div>
        <div class="col-12">
          <app-select label="Canal" placeholder="Selecione o canal" [form]="form" name="subchannel_id" [options]="subchannels"
            [optionsId]="true"
            [ngClass]="{'is-invalid': form.controls.channel_id.touched && form.controls.channel_id.invalid}"
            [messageCondition]="form.controls.channel_id.touched && form.controls.channel_id.invalid"
            message="Selecione o canal">
          </app-select>
        </div>
      </div>

      <div *ngIf="form.controls.type.value == 'text'">


        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="input-group-example">
                Ativo
              </label>
              <input type="text" class="form-control" placeholder="Digite para pesquisar" formControlName="symbol"
                (input)="filter($event)">

              <ul class="results list-group" *ngIf="searchResults.length">
                <div *ngIf="loadingSearch">
                  <span class="spinner-border m-7"></span>
                </div>
                <div *ngIf="!loadingSearch">
                  <li *ngFor="let s of searchResults; let i = index" (click)="itemSelected(i)" class="list-group-item">
                    {{s.symbol}}
                  </li>
                </div>
              </ul>
            </div>
          </div>

          <div class="col-12 pt-0 pb-4">
            <span class="badge badge-soft-primary" *ngFor="let s of selectedSymbolsArr">
              {{s}}
              <i class="fas fa-times-circle pointer" (click)="deleteSelectedItem(s)"></i>
            </span>
          </div>

          <!-- <div class="col-12">
            <app-select label="Ativo" placeholder="Selecione o ativo" [form]="form" name="symbol" [options]="symbols"
              [optionsId]="true">
            </app-select>
          </div> -->
        </div>
        <div class="row">
          <div class="col-12 pb-4">
            <label>Descrição</label>
            <ckeditor formControlName="description" [config]="{uiColor: '#CCCCCC', entities:false, height: '500px'}"
              (change)="onChange($event)" (ready)="onReady($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
              (fileUploadRequest)="onFileUploadRequest($event)" (fileUploadResponse)="onFileUploadResponse($event)"
              debounce="700">
              {{item?.description}}
            </ckeditor>
            <!-- <app-textarea [form]="form" label="Descrição" [required]="true" name="description"
              placeholder="Digite uma descrição"
              [ngClass]="{'is-invalid': form.controls['description'].touched && !form.controls['description'].valid }"
              [messageCondition]="form.controls.description.touched && !form.controls.description.valid"
              message="Preencha a descrição">
            </app-textarea> -->
          </div>
        </div>

        <label for="">Tendência</label>
        <div class="row pb-4">
          <div class="col-12">
            <div class="btn-group btn-group-toggle btn-block" data-toggle="buttons">
              <label class="btn btn-secondary" (click)="changeTrend('down')">
                <input type="radio" formControlName="trend" value="down"> Baixa
              </label>
              <label class="btn btn-secondary" (click)="changeTrend('neutral')">
                <input type="radio" formControlName="trend" value="neutral"> Neutra
              </label>
              <label class="btn btn-secondary" (click)="changeTrend('up')">
                <input type="radio" formControlName="trend" value="up"> Alta
              </label>
            </div>
          </div>
        </div>

        <label for="">Imagens</label>
        <div class="row pb-3">
          <div class="col-4 mb-3" *ngFor="let f of files; let i = index">
            <div class="d-flex justify-content-center align-items-center py-4" *ngIf="f.loadingImage">
              <div class="spinner-border text-primary d-flex justify-content-center align-items-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngIf="!f.loadingImage">
              <app-input-file-custom [form]="form" [fileName]="f.fileName"
                [hasFile]="f.image_src || item?.images[i]?.url" (fileInput)="fileChangeEvent($event, i)"
                (deleteImage)="deleteImage(item?.images[i])" accept=".png, .jpg, .jpeg" [hasCancel]="f.isFileValid"
                (cancelUpload)="cancelUpload(i)" [index]="i" [hasEdit]="f.hasEdit && f.image_src" [hasImage]="item?.images[i]?.url && !f.image_src">
                <img [src]="f.image_src.src" class="img-fluid screenshot rounded" *ngIf="f.image_src">
                <img [src]="environment.imageBaseUrl+item?.images[i]?.url" *ngIf="item?.images[i]?.url && !f.image_src"
                  class="img-fluid screenshot rounded" width="100%">
              </app-input-file-custom>
              <img [src]="f.imgLoaded?.src" [id]="'preview'+i" hidden />
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <small>
              <span *ngIf="audio" class="text-danger" style="cursor: pointer; float: right;"
                (click)="cancelAudioUpload()">Cancelar</span>
            </small>
            <app-input-file label="Áudio" [form]="form" [fileName]="audioFileName"
              (fileInput)="audioFileChangeEvent($event)" accept=".mp3, .mp4, .wav">
            </app-input-file>
            <audio class="mt-4" *ngIf="item?.audios[0] || audio" id="audio"
              [src]="id ? environment.imageBaseUrl+item?.audios[0].url : ''" controls style="width: 100%"></audio>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <small>
              <span *ngIf="hasFile" class="text-danger" style="cursor: pointer; float: right;"
                (click)="cancelVideoUpload()">Cancelar</span>
            </small>

            <div class="form-group">
              <label>Vídeo </label>
              <div class="form-control-custom">
                <input type="file" #file name="video" id="video" accept="video/*" multiple
                  (change)="videoFileChangeEvent($event)">
                <p class="small-custom">{{videoFileName?videoFileName:'Clique aqui ou arraste para inserir o seu
                  arquivo'}}</p>
              </div>
              <div class="progress mt-2">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  attr.aria-valuenow="{{percentage}}" aria-valuemin="0" aria-valuemax="100"
                  [style.width]="percentage+'%'">
                </div>
              </div>
              <button class="btn btn-sm btn-primary mt-2 float-right" type="button" (click)="start(file.files);"
                [disabled]="!hasFile">
                <span *ngIf="uploading" class="spinner-border spinner-border-sm"></span>
                Upload
              </button>
            </div>

            <!-- <video class="mt-4" *ngIf="hasFile" controls style="width: 100%" id="videoPlayer">
              <source [src]="''" type="video/*" />
            </video> -->

          </div>
          <div class="col-12">
            <iframe class="mt-3" *ngIf="hasVideo || item?.videos.length > 0" [src]="src" width="100%" height="400"
              frameborder="0" id="iframe" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
              [title]="video?.title"></iframe>
            <a [href]="href" *ngIf="hasVideo || item?.videos.length > 0" id="href"
              class="btn btn-sm mt-2 btn-info float-right" target="_blank">
              Ver vídeo
              <i class="fab fa-vimeo-v"></i>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="form.controls.type.value == 'poll'">

        <div formArrayName="options" class="pb-4">
          <label for="">Opções</label>
          <div class="row" *ngFor="let option of optionsArray.controls; let i = index" [formGroupName]="i">

            <div class="col-md-11 col-12">
              <div class="form-group">
                <input type="text" formControlName="option" class="form-control" [placeholder]="'Opção ' + (i+1)"
                  [ngClass]="{'is-invalid': option.controls.option.touched && option.controls.option.invalid}">
                <small class=" text-danger"
                  *ngIf="option.controls.option.touched && option.controls.option.invalid">Preencha
                  a opção</small>
              </div>
            </div>
            <div class="col-12 col-md-1 d-flex align-items-center justify-content-center"
              *ngIf="optionsArray.controls.length > 2">
              <div class="form-group">
                <button class="btn btn-sm btn-danger" (click)="deleteOptions(i)">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
            </div>
            <div class="col-12 col-md-1 d-flex align-items-center justify-content-center"
              *ngIf="optionsArray.controls.length == 2">
              <div class="form-group">
                <button class="btn btn-sm btn-danger" (click)="cleanOptions(i, $event)">
                  <i class="fas fa-backspace"></i>
                </button>
              </div>
            </div>
            <!-- <div class="col-12 mb-4" *ngIf="optionsArray.controls.length > 1 && i+1 != optionsArray.controls.length">
            <div class="border-bottom-dashed"></div>
          </div> -->
          </div>

          <button class="btn btn-sm btn-primary" (click)="addOptions($event)">Adicionar</button>

        </div>


        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="public_results">
          <label class="custom-control-label" for="customSwitch1">Resultado público</label>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-form-button [form]="form" [loading]="submitting"
            [disabled]="submitting || form.invalid || (hasFile && !hasVideo)" [label]="'Concluir'">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-container>
