import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-research-companies',
  templateUrl: './research-companies.component.html',
  styleUrls: ['./research-companies.component.scss']
})
export class ResearchCompaniesComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  items = []
  columns = ['', 'Título', 'Descrição']
  loading = false

  constructor(
    private reportsService: ReportsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.getItems()
  }

  async getItems() {
    try {
      let res: any = await this.reportsService.listResearchCompanies()
      if (res.status) {
        this.items = res.data
      }
    } catch (error) {
      console.log({ error })
    } finally {
      this.loading = false
    }
  }

  detail(id) {
    this.router.navigate([`${PathRoutes.ResearchCompanies.Detail}/${id}`])
  }

}
