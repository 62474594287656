// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { version } from '../../package.json';

export const environment = {
  production: false,
  version: `Beta`,
  baseUrl: 'https://api.ogropro.com.br/api/',
  // baseUrl: 'http://192.168.3.181:8000/api/',
  imageBaseUrl: 'https://4traders-main-bucket.nyc3.digitaloceanspaces.com/',
  youtubeSettings: {
    // channelId: 'UCwOuSQSlBeby_iW81JuJmbQ', //Canal do Vinnie para testes
    channelId: 'UCdpWSL_JI970SZXJ6R41Qtg'
  },
  vimeoSettings: {
    baseUrl: 'https://api.vimeo.com/',
    videoUri: 'https://player.vimeo.com/video/',
    // token: 'ac85695e9659e27f182a91f9610dc1b8', // token stepbystock
    // token: '75f06c85e7f1516306d9a091206ac4bd', // token psiapp
    // token: 'effdc47d776f587ed97d0cef23e67d38', // Canal do Vinnie
    token: 'b02a4079890a9bb7e0c830adfdcbadf9', // 4traders
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
