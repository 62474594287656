<app-navbar [breadcrumbCurrent]="'Canais'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-md-10 col-12">
  <app-header title="Canais" icon="fas fa-th-large" [custom]="true">
    <div class="col-auto">
      <button class="btn btn-primary ml-2" (click)="setupForm()" data-toggle="modal" data-target="#modalSubchannels">
        Adicionar
        <i class="fas fa-plus-circle ml-1"></i>
      </button>
    </div>
  </app-header>
  <app-table [columns]="columns" [empty]="items.length == 0" [loading]="loading">
    <tr *ngFor="let item of items;" class="tr-link">
      <td class="tables-name">
        <span>{{ item.title }} <small style="color: #fff9; font-size: smaller;" *ngIf="getChannelName(item.channel_id)">({{ getChannelName(item.channel_id) }})</small></span>
      </td>
      <td width="5%">
        <div class="dropdown">
          <a href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            class="dropdown-ellipses dropdown-toggle">
            <i class="fe fe-more-vertical"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#" (click)="getItem(item.id)" data-toggle="modal"
              data-target="#modalSubchannels">Editar</a>
            <button class="btn btn-link dropdown-item text-danger" href="#"
              (click)="deleteChannel(item.id)">Excluir</button>
          </div>
        </div>
      </td>
    </tr>
  </app-table>
</app-container>

<app-modal identifier="modalSubchannels" [title]="hasItem ? 'Editar Canal' : 'Adicionar Canal'">
  <form [formGroup]="form" (submit)="save()">
    <app-input type="text" name="title" placeholder="Nome do canal" [form]="form"
      [ngClass]="{'is-invalid': form.controls.title.touched && form.controls.title.invalid }"
      [messageCondition]="form.controls.title.touched && form.controls.title.invalid" message="Digite o nome do canal">
    </app-input>

      <app-select label="Comunidade" placeholder="Selecione a comunidade" [form]="form" name="channel_id" [options]="channels"
        [optionsId]="true"
        [ngClass]="{'is-invalid': form.controls.channel_id.touched && form.controls.channel_id.invalid}"
        [messageCondition]="form.controls.channel_id.touched && form.controls.channel_id.invalid"
        message="Selecione o canal">
      </app-select>

    <label for="">Planos</label>
    <div class="form-check" *ngFor="let plan of plans; let i = index">
      <input class="form-check-input" type="checkbox" value="" [id]="plan.id" [checked]="plan.checked"
        (change)="plansChanged(plan, $event)">
      <label class="form-check-label" [for]="plan.id">
        {{plan.name}}
      </label>
    </div>

    <app-form-button label="Concluir" [disabled]="loading || form.invalid" [loading]="loading" [form]="form">
    </app-form-button>
  </form>
</app-modal>