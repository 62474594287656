import { PathRoutes } from 'src/app/routes/path-routes';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn: boolean = false;
  public redirectUrl: string;
  constructor(private router: Router, private sessionService: SessionService, private usersService: UsersService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.redirectUrl = state.url
    var session = this.sessionService.getSession()

    if (!session) {
      this.router.navigate([PathRoutes.Login])
    }

    let permissions = {
      'home': 'app_home',
      'usuarios': 'users',
      'admins': 'admins',
      'canais': 'channels',
      'postagens': 'posts',
      'denuncias': 'complaints',
      'banner': 'banner',
      'enviar-notificacao': 'notifications',
      'termos-de-uso/editar': 'settings',
    }

    let pages = [
      'home',
      'usuarios',
      'admins',
      'canais',
      'postagens',
      'denuncias',
      'banner',
      'enviar-notificacao',
      'termos-de-uso/editar',
    ]

    if (session.permissions) {
      let path = route.routeConfig.path
      if (pages.includes(path)) {
        let permission = session?.permissions.some(elem => elem.function == permissions[path])
        if (!permission) {
          this.router.navigate([PathRoutes.Home])
        }
      }
    }

    return true
  }
}
