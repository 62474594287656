import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'traderland-angular-cms';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private sessionService: SessionService,
    private titleService: Title
  ) {
    let session = this.sessionService.getSession()
    if (session && session.version != environment.version) {
      this.sessionService.destroySession()
    }
    if (session) {
      this.titleService.setTitle(this.sessionService.getSession()?.white_label?.app_name);
      document.documentElement.style.setProperty('--primary-color', this.sessionService.getSession()?.white_label?.primary_color)
      this.favIcon.href = environment.imageBaseUrl + this.sessionService.getSession()?.white_label?.icon_path;
    }
  }
}
