import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class HomeCardsService {

  constructor(private network: NetworkService) { }

  list() {
    return this.network.get(Endpoints.HomeCards)
  }

  show(id) {
    return this.network.get(`${Endpoints.HomeCards}/${id}`)
  }

  save(params) {
    if (params.id) {
      let id = params.id
      delete (params.id)
      return this.network.postMultipart(`${Endpoints.HomeCards}/${id}/update`, params)
    } else {
      return this.network.postMultipart(`${Endpoints.HomeCards}`, params)
    }
  }

  delete(id) {
    return this.network.delete(`${Endpoints.HomeCards}/${id}`)
  }

  order(params) {
    return this.network.post(Endpoints.HomeCardsOrder, params)
  }

}
