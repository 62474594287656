<app-navbar [breadcrumbCurrent]="'Relatórios'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-md-10">
  <app-header title="Relatórios" icon="fas fa-file-alt" [buttonLink]="PathRoutes.Reports.Add" buttonLabel="Adicionar"
    buttonIcon="fas fa-plus-circle mr-2"></app-header>
  <app-table [columns]="columns" [loading]="loading" [empty]="items.length == 0">
    <tr *ngFor="let item of items;" (click)="detail(item.id)" class="tr-link">
      <td width="20%">
        <span>{{ item?.title }}</span>
      </td>
      <td width="40%">
        <span>{{ item.description }}</span>
      </td>
      <td width="10%">
        <span>{{ item.research_company?.title }}</span>
      </td>
      <td width="10%">
        <span>{{ item?.date | date: 'dd/MM/yyyy'}}</span>
      </td>
      <td width="10%">
        <span class="badge badge-soft-primary">{{ getType(item.type) }}</span>
      </td>
      <td width="10%">
        <span class="badge badge-soft-primary">{{ item.category?.title }}</span>
      </td>
    </tr>
  </app-table>
</app-container>
