import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeCardsService } from './../../../services/home-cards.service';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';

@Component({
  selector: 'app-banner-app-detail',
  templateUrl: './banner-app-detail.component.html',
  styleUrls: ['./banner-app-detail.component.scss']
})
export class BannerAppDetailComponent implements OnInit {
  id: any
  item: any
  loading = false
  environment = environment
  PathRoutes = PathRoutes
  count = 0
  constructor(
    private homeCardsService: HomeCardsService,
    private activatedRoute: ActivatedRoute,
    public helper: HelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getItem()
    this.getCount()
  }

  async getCount() {
    try {
      let res = await this.homeCardsService.list()
      if (res.status) {
        this.count = res.data.length
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.homeCardsService.show(this.id)
      if (res.status) {
        res.data.timer = res.data.timer / 1000
        this.item = res.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  async deleteBanner() {
    try {
      let swal = await this.helper.deleteSwal()
      if (swal.value) {
        let del = await this.homeCardsService.delete(this.id)
        this.router.navigate([PathRoutes.BannerApp.List])
      }
    } catch (error) {
      console.log(error)
    }
  }


}
