import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { PlansService } from 'src/app/services/plans.service';
import { PostsService } from 'src/app/services/posts.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
})
export class ChannelsComponent implements OnInit {
  columns = ['Nome', ''];
  environment = environment;
  items = [];
  loading = false;
  hasItem = false;
  loadingImage = false;
  picture: any;
  form: FormGroup;

  constructor(
    private postsService: PostsService,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private plansService: PlansService
  ) {}

  ngOnInit(): void {
    this.getItems();
    this.setupForm();
  }


  async getItems() {
    this.loading = true;
    try {
      let res = await this.postsService.getChannels();
      if (res.status) {
        this.items = res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  setupForm() {
    this.hasItem = false;
    this.form = this.formBuilder.group({
      id: null,
      title: [null, Validators.required],
      description: [null, Validators.required],
      picture: [null],
    });
  }

  getItem(id) {
    this.postsService
      .showChannel(id)
      .then((res: any) => {
        if (res.status) {
          this.hasItem = true;
          this.picture = res.data.picture;
          this.form.get('id').setValue(res.data.id);
          this.picture = res.data.picture;
          this.form.patchValue(res.data);
          console.log(res.data);
        }
      })
      .catch((e) => console.log(e));
  }

  save() {
    console.log(this.image_url);

    let params = {
      ...this.form.getRawValue(),
      channelPicture: this.image_url,
    };
    console.log(params);
    this.loading = true;
    this.postsService
      .saveChannel(params)
      .then((res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true);
          window.location.reload();
          this.getItems();
        } else {
          this.helper.triggerNotification(false);
        }
        $('#modalChannels').modal('hide');
      })
      .catch((e) => console.log(e))
      .finally(() => (this.loading = false));
  }

  async deleteChannel(id) {
    try {
      let swal = await this.helper.deleteSwal(
        'As postagens referentes a essa Comunidade também serão excluídas'
      );
      if (swal.value) {
        let res: any = await this.postsService.deleteChannel(id);
        if (res.status) {
          this.helper.triggerNotification(
            true,
            'Sucesso',
            'Comunidade excluída com sucesso'
          );
          this.getItems();
        } else {
          this.helper.triggerNotification(
            false,
            'Erro',
            'Ocorreu um erro, por favor tente novamente'
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isFileValid: any;
  image_url: any;
  fileName: any;

  fileChangeEvent(event: any): void {
    this.loadingImage = true;
    this.isFileValid = false;
    this.imageChangedEvent = event;
    let hiddenImage: any = document.getElementById('preview');
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.addEventListener(
      'load',
      function () {
        hiddenImage.src = reader.result;
      },
      false
    );

    setTimeout(() => {
      if (hiddenImage.height < 250) {
        this.helper.triggerNotification(
          false,
          'Erro',
          'Tamanho mínimo para altura é de 250px'
        );
        this.isFileValid = false;
      } else if (event.target.files[0].size > 2000000) {
        this.helper.triggerNotification(
          false,
          'Erro',
          'Tamanho máximo para imagem é de 2mb'
        );
        this.isFileValid = false;
      } else {
        this.isFileValid = true;
      }
      this.loadingImage = false;
    }, 1000);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.image_url = this.helper.dataURLtoFile(event.base64, 'profile.jpg');
  }

  cancelUpload() {
    this.image_url = null;
    this.isFileValid = false;
    this.fileName = 'Clique ou arraste aqui para inserir sua imagem';
  }
}
