import { ModalComponent } from './../modal/modal.component';
import { Component, OnInit } from '@angular/core';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { LivesService } from './../../services/lives.service';
import { SessionService } from './../../services/session.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  urlGooglePermission: any
  session: any
  loading = false
  liveConfig: any
  icon: any = '/assets/img/placeholder.jpg'
  whiteLabel: any

  permissions = {
    app_home: false,
    lives: false,
    users: false,
    admins: false,
    channels: false,
    posts: false,
    complaints: false,
    banner: false,
    notifications: false,
    settings: false,
    youtube: false,
    home_ads: false,
    research_house: false
  }

  constructor(
    private sessionService: SessionService,
    private livesService: LivesService,
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.session = this.sessionService.getSession()
    if (this.session.permissions) {
      let permissions = this.session?.permissions.map(elem => elem.function)
      this.permissions = permissions.reduce((a, v) => ({ ...a, [v]: true }), {})
    }
    this.getCode()
    this.updatePermissions()
  }

  async updatePermissions() {
    try {
      let res: any = await this.usersService.get(this.session.id)
      if (res.status) {
        this.sessionService.saveSession({ ...this.session, permissions: res.data.permissions })
      }
    } catch (error) {
      console.log(error)
    }
  }

  getCode() {
    this.loading = true
    this.livesService.get()
      .then((res: any) => {
        if (res.status) {
          this.liveConfig = res.data[0]
          let clientId = res.data[0]?.client_id
          let redirectUri = res.data[0]?.redirect_uri
          this.urlGooglePermission = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/youtube.readonly&response_type=code&prompt=consent&access_type=offline`
        }
      })
      .catch(e => console.log(e))
      .finally(() => this.loading = false)
  }

}

