import { Router } from '@angular/router';
import { SessionService } from './../../services/session.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  session = null
  PathRoutes = PathRoutes
  @Input() breadcrumbLink
  @Input() breadcrumbLinkName
  @Input() breadcrumbSubLink
  @Input() breadcrumbSubLinkName
  @Input() breadcrumbCurrent

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(private sessionService: SessionService, private router: Router, private titleService: Title) { }

  ngOnInit() {
    this.session = this.sessionService.getSession()
  }

  logout() {
    this.sessionService.destroySession()
    this.titleService.setTitle('4traders | Parceiro')
    this.favIcon.href = '../../../favicon.ico'
    this.router.navigate([PathRoutes.Login])
  }
}
