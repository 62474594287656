import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-terms-of-use-edit',
  templateUrl: './terms-of-use-edit.component.html',
  styleUrls: ['./terms-of-use-edit.component.scss']
})
export class TermsOfUseEditComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false

  terms: any = ''
  constructor(public helper: HelperService, private formBuilder: FormBuilder, private usersService: UsersService) { }

  ngOnInit() {
    this.setupForm()
    this.getItem()
  }

  onReady(e) { }
  onChange(e) { }
  onFocus(e) { }
  onBlur(e) { }
  onFileUploadRequest(e) { }
  onFileUploadResponse(e) { }


  async getItem() {
    const res = await this.usersService.getTerms()
    this.terms = res.data.value
    this.form.patchValue({ value: this.terms })
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "value": ['', Validators.compose([Validators.required])],
    })

  }

  save() {
    this.loading = true
    var params = this.form.getRawValue()
    if (!this.form.invalid) {
      this.usersService.saveTerms(params)
        .then((res: any) => {
          this.loading = false
          if (res.status) {
            this.helper.triggerNotification(res.status)
          } else {
            this.helper.triggerNotification(res.status)
          }
        })
        .catch(e => { console.log(e); this.helper.triggerNotification(false) })
        .finally(() => { this.loading = false })

    }
  }
}
