import { PostsService } from 'src/app/services/posts.service';
import { environment } from 'src/environments/environment';
import { AdminsService } from './../../../services/admins.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { HelperService } from 'src/app/helpers/helper.service';

@Component({
  selector: 'app-admins-detail',
  templateUrl: './admins-detail.component.html',
  styleUrls: ['./admins-detail.component.scss']
})
export class AdminsDetailComponent implements OnInit {
  PathRoutes = PathRoutes
  id = null
  item: any
  loading = false
  environment = environment
  channels = []
  permissionsArr = [
    { key: "app_home", checked: true, label: 'Home' },
    { key: "lives", checked: true, label: 'Lives' },
    { key: "users", checked: true, label: 'Usuários' },
    { key: "admins", checked: true, label: 'Admins' },
    { key: "channels", checked: true, label: 'Canais' },
    { key: "complaints", checked: true, label: 'Denuncias' },
    { key: "settings", checked: true, label: 'Banners e Termos de uso' },
    { key: "notifications", checked: true, label: 'Notificação' },
    { key: "youtube", checked: true, label: 'Youtube' },
    { key: "home_ads", checked: true, label: 'Anúncio' },
    { key: "research_house", checked: true, label: 'Casas de análise' },
    { key: "posts", checked: true, label: 'Postagens' },
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private helper: HelperService,
    private adminsService: AdminsService,
    private router: Router,
    private postsService: PostsService
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getChannels()
    if (this.id) {
      this.getItem()
    }
  }
  async getChannels() {
    try {
      let res: any = await this.postsService.getChannels()
      this.channels = res.data.map(elem => ({ key: elem.id, label: elem.title, checked: true }))
    } catch (error) {
      console.log(error)
    }
  }

  getItem() {
    this.loading = true
    this.usersService.get(this.id)
      .then((res: any) => {
        if (res.status) {
          this.item = res.data
          let permissions = res.data.permissions.filter(elem => !elem.channel_id).map(elem => elem.function)
          let channels = res.data.permissions.filter(elem => elem.channel_id).map(elem => elem.channel_id)
          this.channels = this.channels.map(elem => ({ ...elem, checked: channels.includes(elem.key) }))
          this.permissionsArr = this.permissionsArr.map(elem => ({ ...elem, checked: permissions.includes(elem.key) }))
        }
      })
      .catch(e => console.log(e))
      .finally(() => this.loading = false)
  }

  changeStatus(status) {
    this.helper.cancelSwal(status)
      .then((result) => {
        if (result.value) {
          this.adminsService.save({ id: this.id, active: status }).then((res: any) => {
            if (res.status) {
              this.helper.triggerNotification(true, 'Alterado', 'Status alterado com sucesso')
              this.getItem()
            } else {
              this.helper.triggerNotification(false, 'Erro', 'Ocorreu algum erro, por favor tente novamente')
            }
          }).catch(e => console.log(e))

        }
      })
  }

  async delete() {
    try {
      let swal = await this.helper.deleteSwal()
      if (swal.value) {
        let del = await this.usersService.delete(this.id)
        this.router.navigate([PathRoutes.Admins.List])
      }
    } catch (error) {
      console.log(error)
    }
  }

}
