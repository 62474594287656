import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helpers/helper.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private router: Router,
    private accountService: AccountService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'type': 'admin'
    })
  }

  passwordEmail() {
    this.loading = true;
    this.accountService.sendToken(this.form.getRawValue()).then((res: any) => {
      if (res.status) {
        this.helper.triggerNotification(true, 'Enviada', res.message);
        this.router.navigate([PathRoutes.Login])
      } else {
        this.helper.triggerNotification(false, 'Erro', res.message);
        this.loading = false
      }
    })
  }

}
