import { PlansService } from 'src/app/services/plans.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { PostsService } from 'src/app/services/posts.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';
import { VimeoService } from './../../../services/vimeo.service';

export class uploadFiles {
  constructor(
    public video: File,
    public path: string,
    public uploadURI: string
  ) {
    this.video = video;
    this.path = path;
    this.uploadURI = uploadURI;
  }
}

@Component({
  selector: 'app-posts-add',
  templateUrl: './posts-add.component.html',
  styleUrls: ['./posts-add.component.scss'],
})
export class PostsAddComponent implements OnInit {
  PathRoutes = PathRoutes;
  environment = environment;
  form: FormGroup;
  id = null;
  loading = false;
  // files = Array(6).fill({
  files = [
    {
      image_url: null,
      image_src: null,
      fileName: 'Clique ou arraste aqui para inserir sua imagem',
      loadingImage: false,
      imgLoaded: null,
      isFileValid: false,
    },
    {
      image_url: null,
      image_src: null,
      fileName: 'Clique ou arraste aqui para inserir sua imagem',
      loadingImage: false,
      imgLoaded: null,
      isFileValid: false,
    },
    {
      image_url: null,
      image_src: null,
      fileName: 'Clique ou arraste aqui para inserir sua imagem',
      loadingImage: false,
      imgLoaded: null,
      isFileValid: false,
    },
    {
      image_url: null,
      image_src: null,
      fileName: 'Clique ou arraste aqui para inserir sua imagem',
      loadingImage: false,
      imgLoaded: null,
      isFileValid: false,
    },
    {
      image_url: null,
      image_src: null,
      fileName: 'Clique ou arraste aqui para inserir sua imagem',
      loadingImage: false,
      imgLoaded: null,
      isFileValid: false,
    },
    {
      image_url: null,
      image_src: null,
      fileName: 'Clique ou arraste aqui para inserir sua imagem',
      loadingImage: false,
      imgLoaded: null,
      isFileValid: false,
    },
  ];
  audio: any;
  audioFileName: any;
  item: any;
  channels = [];
  subchannels = [];
  types = [
    { id: 'text', name: 'Texto' },
    { id: 'poll', name: 'Enquete' },
  ];
  session: any;
  postType: any;
  submitting = false;
  video: any;
  videoSuccess = false;
  videoFileName: any;
  hasVideo = false;
  videoList: FileList;
  videoLinks = [];
  pendingFiles: uploadFiles[] = [];
  src: any;
  href: any;
  hasFile = false;
  symbols = [];

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private postsService: PostsService,
    private activcateRoute: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private vimeoService: VimeoService,
    private sanitizer: DomSanitizer
  ) {}

  public start(files: FileList) {
    this.uploading = true;
    this.videoList = files;
    console.log(this.videoList);
    const recursion = this.getLink(this.videoList[0], 0, this.videoList).pipe(
      expand((res: any) => {
        return res.index > res.arr.length - 1
          ? EMPTY
          : this.getLink(this.videoList[res.index], res.index, this.videoList);
      })
    );

    recursion.subscribe((x) => {
      if (x.index > x.arr.length - 1) {
        console.log('Link generated, Starting upload');
        // All links have been generated now you can start the upload
        this.videoUpload();
      }
    });
  }

  get percentage(): string {
    return this.vimeoService.percentage;
  }

  refreshData() {
    setInterval(() => {
      const iframe = document.getElementById('iframe') as HTMLIFrameElement;

      if (iframe) {
        iframe.src = iframe.src;
      }
    }, 5000);
  }

  getLink = (video: File, index, arr) => {
    console.log('index: ' + index);
    return this.vimeoService.createVideo(video).pipe(
      map((response) => {
        const videoFile = new uploadFiles(
          video,
          response.body.link,
          response.body.upload.upload_link
        );
        this.pendingFiles.push(videoFile);
        // console.log('response: ' + JSON.stringify(response, null, 3));
        this.video = response.body?.uri.split('/')[2];
        return {
          data: response,
          index: index + 1,
          arr: arr,
        };
      })
    );
  };
  uploading = false;
  videoUpload() {
    const success = () => {
      this.videoSuccess = true;
      this.uploading = false;
      this.hasVideo = true;
      console.log('after video upload section');
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://player.vimeo.com/video/${this.video}`
      );
      this.href = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://vimeo.com/${this.video}`
      );
      this.vimeoService.resetPercentage();
    };
    const upload: Array<any> = [];
    for (let i = 0; i < this.pendingFiles.length; i++) {
      upload.push(
        this.vimeoService.tusUpload(
          this.pendingFiles[i],
          i,
          this.pendingFiles,
          upload,
          success
        )
      );
    }
    console.log('start video upload sequentially');
    upload[0].start();
  }

  onReady(e) {}
  onChange(e) {}
  onFocus(e) {}
  onBlur(e) {}
  onFileUploadRequest(e) {}
  onFileUploadResponse(e) {}

  ngOnInit(): void {
    this.session = this.sessionService.getSession();
    this.setupForm();
    this.id = this.activcateRoute.snapshot.paramMap.get('id');
    this.getChannels().then(() => {
      this.getSubChannels();
    });
    this.getSymbols();
    this.setupOptions();
    this.refreshData();
    if (this.id) {
      this.getItem();
    } else {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  async changeSubChannels() {
    this.getSubChannels();
  }

  async getSymbols() {
    try {
      let res = await this.postsService.listAllStock({});
      this.symbols = res.data.data.map((elem) => ({
        id: elem.symbol,
        name: elem.symbol,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async getChannels() {
    try {
      let res = await this.postsService.getChannels();
      let channels = res.data;
      this.channels = channels.map((elem) => ({
        id: elem.id,
        name: elem.title,
      }));
      console.log(this.channels);
    } catch (error) {
      console.log(error);
    }
  }

  async getSubChannels() {
    try {
      let res = await this.postsService.getSubChannels(
        this.form.get('channel_id').value
      );
      // let permissionsArr = this.session.permissions.map(elem => elem.channel_id)
      // let channels = res.data.filter(elem => elem.id != 1 && permissionsArr.includes(elem.id))
      this.subchannels = res.data.map((elem) => {
        const channel = this.channels.find(
          (channel) => channel.id == elem.channel_id
        );
        return {
          id: elem.id,
          name: `${elem.title} (${channel.name})`,
          channel_id: elem.channel_id,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  buildOptions() {
    return this.formBuilder.group({
      option: [''],
    });
  }

  setupOptions() {
    // Array(2).fill('').map(e => this.optionsArray.push(this.buildOptions()))
    this.optionsArray.push(this.buildOptions());
  }

  get optionsArray() {
    return <FormArray>this.form.get('options');
  }

  addOptions(e) {
    e.preventDefault();
    this.optionsArray.push(this.buildOptions());
    for (var i = 0; i < this.optionsArray.length; i++) {
      this.optionsArray.controls[i]['controls']['option'].setValidators([
        Validators.required,
      ]);
      this.optionsArray.controls[i]['controls'][
        'option'
      ].updateValueAndValidity();
    }
  }

  cleanOptions(index, e) {
    e.preventDefault();
    this.optionsArray.controls[index]['controls']['option'].setValue('');
    this.optionsArray.controls[index]['controls']['option'].clearValidators();
    this.optionsArray.controls[index]['controls'][
      'option'
    ].updateValueAndValidity();
  }

  deleteOptions(index) {
    this.optionsArray.removeAt(index);
    if (this.optionsArray.length == 1) {
      for (var i = 0; i < this.optionsArray.length; i++) {
        this.optionsArray.controls[i]['controls']['option'].clearValidators();
        this.optionsArray.controls[i]['controls'][
          'option'
        ].updateValueAndValidity();
      }
    }
  }

  changeTrend(id) {
    this.form.get('trend').setValue(id);
  }

  setupForm() {
    this.form = this.formBuilder.group({
      id: this.id,
      title: ['', Validators.required],
      channel_id: ['', Validators.required],
      subchannel_id: ['', Validators.required],
      description: [''],
      type: ['', Validators.required],
      options: this.formBuilder.array([this.buildOptions()]),
      public_results: 1,
      symbol: '',
      trend: '',
    });
    this.form.get('channel_id').disable();
    this.form.get('subchannel_id').valueChanges.subscribe((value) => {
      if (!value) {
        this.form.get('channel_id').setValue('');
        return;
      }
      const subchannel = this.subchannels.find(
        (subchannel) => subchannel.id == +value
      );
      this.form.get('channel_id').setValue(subchannel?.channel_id);
    });
  }

  changePostType(e) {
    this.postType == e.target.value;
    if (e.target.value == 'text') {
      this.form.get('description').setValidators(Validators.required);
      this.form.get('description').updateValueAndValidity();
      for (var i = 0; i < this.optionsArray.length; i++) {
        this.optionsArray.controls[i]['controls']['option'].clearValidators();
        this.optionsArray.controls[i]['controls'][
          'option'
        ].updateValueAndValidity();
      }
    } else {
      this.form.get('description').clearValidators();
      this.form.get('description').updateValueAndValidity();
      for (var i = 0; i < this.optionsArray.length; i++) {
        this.optionsArray.controls[i]['controls']['option'].setValidators([
          Validators.required,
        ]);
        this.optionsArray.controls[i]['controls'][
          'option'
        ].updateValueAndValidity();
      }
    }
  }

  async getItem() {
    this.loading = true;
    try {
      const post = await this.postsService.show(this.id);
      this.selectedSymbols = post.data.symbol;
      this.selectedSymbolsArr = post.data.symbol
        ? post.data.symbol.split('|')
        : [];
      post.data.symbol = '';
      this.item = post.data;
      this.form.patchValue(post.data);
      this.files = this.files.map((e, i) => ({
        ...e,
        hasEdit: !this.item.images[i],
      }));
      if (post.data.videos.length > 0) {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://player.vimeo.com/video/${post.data.videos[0].video_id}`
        );
        this.href = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://vimeo.com/${post.data.videos[0].video_id}`
        );
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async save() {
    let params = this.form.getRawValue();

    if (this.selectedSymbols) {
      params.symbol = this.selectedSymbols;
    }

    if (this.audio) {
      params.audio = [this.audio];
    } else {
      delete params.audio;
    }

    if (!params.trend) {
      delete params.trend;
    }

    if (!params.symbol) {
      delete params.symbol;
    }

    if (this.files.some((elem) => elem.image_url)) {
      params = {
        ...params,
        // image: this.item ? [...this.item.images, ... this.files.filter(elem => elem.image_url).map(elem => elem.image_url)] : this.files.filter(elem => elem.image_url).map(elem => elem.image_url)
        image: this.files
          .filter((elem) => elem.image_url)
          .map((elem) => elem.image_url),
      };
    }

    if (this.video) {
      params = { ...params, video: [this.video] };
    }

    if (params.type == 'text') {
      params.option = null;
      delete params.option;
    } else {
      params.option = params.options.map((elem) => elem.option);
      params.description = null;
      params.public_results = params.public_results ? 1 : 0;
    }

    delete params.options;

    console.log(params);

    this.submitting = true;
    this.postsService
      .post(params)
      .then((res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true);
          this.router.navigate([`${PathRoutes.Posts.Detail}/${res.data.id}`]);
        }
      })
      .catch((e) => {
        this.helper.triggerNotification(false);
        console.log(e);
        this.submitting = false;
      })
      .finally(() => (this.submitting = false));
  }

  fileChangeEvent(event: any, index): void {
    this.files[index].loadingImage = true;
    this.files[index].isFileValid = false;
    let hiddenImage: any = document.getElementById('preview' + index);
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.addEventListener(
      'load',
      function () {
        hiddenImage.src = reader.result;
      },
      false
    );

    setTimeout(() => {
      if (event.target.files[0].size > 1000000) {
        this.helper.triggerNotification(
          false,
          'Erro',
          'Tamanho máximo para imagem é de 1mb'
        );
        this.files[index].isFileValid = false;
      } else {
        this.files[index].isFileValid = true;
        this.files[index].image_url = event.target.files[0];
        this.files[index].image_src = hiddenImage;
        this.files[index].imgLoaded = hiddenImage;
      }
      this.files[index].loadingImage = false;
    }, 1000);
  }

  cancelUpload(index) {
    this.files[index].image_src = null;
    this.files[index].imgLoaded = null;
    this.files[index].image_url = null;
    this.files[index].isFileValid = false;
    this.files[index].fileName =
      'Clique ou arraste aqui para inserir sua imagem';
  }

  audioFileChangeEvent(event: any) {
    this.audio = event.target.files[0];
    this.audioFileName = event.target.files[0].name;

    setTimeout(() => {
      let audio: any = document.getElementById('audio');
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.addEventListener(
        'load',
        function () {
          audio.src = reader.result;
        },
        false
      );
    }, 1000);
  }

  cancelAudioUpload() {
    this.audio = null;
    this.audioFileName = 'Clique ou arraste aqui para inserir seu audio';
    let audio: any = document.getElementById('audio');
    audio.src = this.item?.audio
      ? environment.imageBaseUrl + this.item?.audio
      : '';
  }

  videoFileChangeEvent(event: any) {
    this.hasFile = true;
    this.videoFileName = event.target.files[0].name;
    // setTimeout(() => {
    //   let video: any = document.getElementById('videoPlayer')
    //   let reader = new FileReader
    //   reader.readAsDataURL(event.target.files[0])

    //   reader.addEventListener("load", function () {
    //     video.src = reader.result;
    //   }, false);
    // }, 1000);
  }

  cancelVideoUpload() {
    this.hasFile = false;
    this.video = null;
    this.videoFileName = 'Clique ou arraste aqui para inserir seu video';
    this.hasVideo = false;
    if (this.item?.videos.length > 0) {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://player.vimeo.com/video/${this.item.videos[0].video_id}`
      );
    } else {
      this.src = '';
    }
  }

  async deleteImage(item) {
    try {
      let swal = await this.helper.deleteSwal();
      if (swal.value) {
        let del: any = await this.postsService.deleteImage(item.id);
        if (del.status) {
          this.getItem();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  searchResults = [];
  loadingSearch = false;

  async filter(search) {
    this.loadingSearch = true;
    try {
      search = search.target.value.trim().toLowerCase();
      if (search.length > 0) {
        let res = await this.postsService.listAllStock({ search });
        this.searchResults = res.data.data;
      } else {
        this.searchResults = this.symbols;
      }
      setTimeout(() => {
        this.loadingSearch = false;
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }

  selectedSymbolsArr = [];
  selectedSymbols = '';

  itemSelected(index, symbol = null) {
    var symbol = this.searchResults[index].symbol;
    this.form.controls['symbol'].setValue('');
    this.selectedSymbolsArr.push(symbol);
    this.selectedSymbols = this.selectedSymbolsArr.join('|');
    this.searchResults = [];
  }

  deleteSelectedItem(item) {
    let index = this.selectedSymbolsArr.indexOf(item);
    this.selectedSymbolsArr.splice(index, 1);
    this.selectedSymbols = this.selectedSymbolsArr.join('|');
  }
}
