<app-navbar *ngIf="!id" [breadcrumbCurrent]="'Adicionar'" [breadcrumbLink]="PathRoutes.BannerApp.List"
  [breadcrumbLinkName]="'Banners'"></app-navbar>
<app-navbar *ngIf="id" [breadcrumbCurrent]="'Editar'" [breadcrumbLink]="PathRoutes.BannerApp.List"
  [breadcrumbSubLink]="PathRoutes.BannerApp.Detail + '/'+ id" [breadcrumbSubLinkName]="'Detalhes'"
  [breadcrumbLinkName]="'Banners'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Banner" icon="fas fa-mobile-alt"></app-header>
  <app-card [loading]="loading">
    <form [formGroup]="form" (submit)="save()">
      <div class="row py-4">
        <div class="col-12 d-flex justify-content-center">
          <app-loading *ngIf="loadingImage"></app-loading>
        </div>
        <div class="col-12 d-flex flex-column" *ngIf="!loadingImage">
          <small>
            <span *ngIf="isFileValid" class="text-danger" style="cursor: pointer"
              (click)="cancelUpload()">Cancelar</span>
          </small>
          <div>
            <app-input-file [form]="form" [fileName]="fileName" (fileInput)="fileChangeEvent($event)"
              accept=".png, .jpg, .jpeg">
            </app-input-file>
            <img [src]="imgLoaded" id="preview" hidden />
          </div>
          <div *ngIf="isFileValid" class="row pt-3">
            <div class="col-md-6 col-12">
              <h4>Imagem original</h4>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
                format="jpg" backgroundColor="#FFFFFF" (imageCropped)="imageCropped($event)"
                [containWithinAspectRatio]="true">
              </image-cropper>
            </div>
            <div class="col-md-6 col-12">
              <h4>Imagem cortada</h4>
              <div class="text-center">
                <img [src]="croppedImage" style="border: 1px dashed #D2DDEC; width:100%; border-radius: 10px" />
              </div>
            </div>
          </div>
          <div *ngIf="!isFileValid" class="d-flex justify-content-center pt-4">
            <img [src]="item?.image ? environment.imageBaseUrl+item?.image : 'assets/img/placeholder.jpg'"
              class="img-fluid screenshot" style="max-width: 500px;">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-input [form]="form" label="Título" [required]="true" name="title" placeholder="Nome"
            [ngClass]="{'is-invalid': form.controls['title'].touched && !form.controls['title'].valid}"
            [messageCondition]="form.controls['title'].touched && !form.controls['title'].valid"
            message="Preencha o título"></app-input>
        </div>
        <div class="col-12">
          <app-textarea [form]="form" label="Descrição" [required]="true" name="description" placeholder="Descrição"
            [ngClass]="{'is-invalid': form.controls['description'].touched && !form.controls['description'].valid}"
            [messageCondition]="form.controls['description'].touched && !form.controls['description'].valid"
            message="Preencha a descrição"></app-textarea>
        </div>
        <div class="col-12">
          <app-input [form]="form" label="Duração do slide (em segundos)" type="number" [required]="true" name="timer"
            placeholder="10" [ngClass]="{'is-invalid': form.controls['timer'].touched && !form.controls['timer'].valid}"
            [messageCondition]="form.controls['timer'].touched && !form.controls['timer'].valid"
            message="Preencha a duração (máximo de 10 segundos e mínimo de 3 segundos)"></app-input>
        </div>
        <div class="col-12">
          <small>
            <span *ngIf="audio" class="text-danger" style="cursor: pointer; float: right;"
              (click)="cancelAudioUpload()">Cancelar</span>
          </small>
          <app-input-file label="Áudio" [form]="form" [fileName]="audioFileName"
            (fileInput)="audioFileChangeEvent($event)" accept=".mp3, .mp4, .wav">
          </app-input-file>

          <audio class="mt-4" *ngIf="item?.audio || audio" id="audio"
            [src]="id ? environment.imageBaseUrl+item?.audio : ''" controls style="width: 100%"></audio>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-form-button [form]="form" [loading]="submitting" [disabled]="submitting || form.invalid"
            [label]="'Concluir'">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-container>
