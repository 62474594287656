<app-navbar *ngIf="!id" [breadcrumbCurrent]="'Adicionar'" [breadcrumbLink]="PathRoutes.Reports.List"
  [breadcrumbLinkName]="'Relatórios'"></app-navbar>
<app-navbar *ngIf="id" [breadcrumbCurrent]="'Editar'" [breadcrumbLink]="PathRoutes.Reports.List"
  [breadcrumbSubLink]="PathRoutes.Reports.Detail + '/'+ id" [breadcrumbSubLinkName]="'Detalhes'"
  [breadcrumbLinkName]="'Relatórios'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header [title]="id? 'Editar relatório' : 'Adicionar relatório' "></app-header>
  <app-card [loading]="loading">
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12 mb-4">
          <app-input-file label="PDF" [required]="true" [form]="form" [fileName]="fileName"
            (fileInput)="fileChangeEvent($event)" accept=".pdf">
          </app-input-file>
        </div>
        <div class="col-12">
          <app-input [form]="form" label="Título" [required]="true" name="title" placeholder="Nome"
            [ngClass]="{'is-invalid': form.controls['title'].touched && !form.controls['title'].valid}"
            [messageCondition]="form.controls['title'].touched && !form.controls['title'].valid"
            message="Preencha o título"></app-input>
        </div>
        <div class="col-12">
          <app-select label="Tipo de relatório" placeholder="Selecione o tipo de relatório" [form]="form" name="type"
            [options]="types" [optionsId]="true" (change)="changeType($event)"
            [ngClass]="{'is-invalid': form.controls.type.touched && form.controls.type.invalid}"
            [messageCondition]="form.controls.type.touched && form.controls.type.invalid"
            message="Selecione o tipo de relatório">
          </app-select>
        </div>
        <div class="col-12">
          <app-select label="Casa de análise" placeholder="Selecione a casa de análise" [form]="form"
            name="research_company_id" [options]="researchCompanies" [optionsId]="true"
            (change)="changeResearchCompany($event)"
            [ngClass]="{'is-invalid': form.controls.research_company_id.touched && form.controls.research_company_id.invalid}"
            [messageCondition]="form.controls.research_company_id.touched && form.controls.research_company_id.invalid"
            message="Selecione o tipo de relatório">
          </app-select>
        </div>
        <div class="col-12">
          <app-input [form]="form" label="Data" name="date" placeholder="Qual a data de expiração?" [required]="true"
            type="date" [ngClass]="{'is-invalid': form.controls['date'].touched && !form.controls['date'].valid}"
            [messageCondition]="form.controls['date'].touched && !form.controls['date'].valid"
            message="Preencha a data"></app-input>
        </div>
        <!-- <div class="col-12">
          <app-select label="Categoria" placeholder="Selecione a categoria" [form]="form" name="report_category_id"
            [options]="reportCategories" [optionsId]="true" (change)="changeReportCategory($event)"
            [ngClass]="{'is-invalid': form.controls.report_category_id.touched && form.controls.report_category_id.invalid}"
            [messageCondition]="form.controls.report_category_id.touched && form.controls.report_category_id.invalid"
            message="Selecione o tipo de relatório">
          </app-select>
        </div> -->
        <div class="col-12">
          <app-select-custom label="Categoria" placeholder="Selecione a categoria" [form]="form"
            name="report_category_id" [options]="reportCategories" [optionsId]="true" [value]="categoryValue"
            (change)="changeReportCategory($event)"
            [ngClass]="{'is-invalid': form.controls.report_category_id.touched && form.controls.report_category_id.invalid}"
            [messageCondition]="form.controls.report_category_id.touched && form.controls.report_category_id.invalid"
            message="Selecione o tipo de relatório" actionLabel="Adicionar categoria" dataTarget="#categoriesModal">
          </app-select-custom>
        </div>
        <div class="col-12">
          <app-textarea [form]="form" label="Descrição" [required]="true" name="description" placeholder="Descrição"
            [ngClass]="{'is-invalid': form.controls['description'].touched && !form.controls['description'].valid}"
            [messageCondition]="form.controls['description'].touched && !form.controls['description'].valid"
            message="Preencha a descrição"></app-textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-form-button [form]="form" [loading]="submitting" [disabled]="submitting || form.invalid"
            [label]="'Concluir'">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
  <app-modal identifier="categoriesModal" [title]="'Adicionar Categoria'">
    <form [formGroup]="categoryForm" (submit)="saveCategory()">
      <app-input type="text" name="title" placeholder="Nome do categoria" [form]="categoryForm"
        [ngClass]="{'is-invalid': categoryForm.controls.title.touched && categoryForm.controls.title.invalid }"
        [messageCondition]="categoryForm.controls.title.touched && categoryForm.controls.title.invalid"
        message="Digite o nome da categoria">
      </app-input>
      <app-form-button label="Concluir" [disabled]="loading || categoryForm.invalid" [loading]="loading"
        [form]="categoryForm">
      </app-form-button>
    </form>
  </app-modal>
</app-container>
