import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() form: FormGroup
  @Input() label: string = ''
  @Input() required: boolean = false
  @Input() options: any = []
  @Input() optionsId: boolean = false
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeEmitted(event) {
    this.change.emit(event)
  }

}
