import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit, OnChanges {

  @Input() fileName: string
  @Input() form: FormGroup = null
  @Input() label: string = null
  @Input() required: boolean = false
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() value: string = ''
  @Input() disabled: boolean = false
  @Input() multiple: boolean = false
  @Input() accept: string
  @Output() fileInput: EventEmitter<any> = new EventEmitter();
  fn: string

  constructor() { }

  ngOnInit(): void {
    this.fn = this.fileName || 'Clique aqui ou arraste para inserir o seu arquivo'
  }

  ngOnChanges(changes) {
    this.fn = this.fileName || 'Clique aqui ou arraste para inserir o seu arquivo'
  }
  fileChange(event) {
    this.fileInput.emit(event)
    var f = event.target.files[0]
    this.fileName = f.name
  }

}
