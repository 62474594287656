import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { AccountService } from 'src/app/services/account.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  message = ''
  environment = environment

  constructor(private formBuilder: FormBuilder,
    private accountService: AccountService,
    private sessionService: SessionService,
    private router: Router) {
    this.form = this.formBuilder.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.required]
    })
    document.documentElement.style.setProperty('--primary-color', '#f75a38')
  }

  login() {

    this.loading = true
    this.message = ''
    this.accountService.login({ ...this.form.getRawValue(), type: 'admin' })
      .then((res: any) => {
        if (res.status && res.data.type == 'admin') {
          this.router.navigate([PathRoutes.Home])
        } else {
          this.message = 'Usuário ou senha inválidos'
        }
      })
      .catch(e => console.log(e))
      .finally(() => this.loading = false)
  }

  ngOnInit() {

  }

}
