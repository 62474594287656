<app-navbar [breadcrumbLink]="PathRoutes.Admins.List" [breadcrumbLinkName]="'Administradores'"
  [breadcrumbCurrent]="'Detalhes'">
</app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Detalhes do administrador" buttonLabel="Editar" buttonIcon="fe fe-edit mr-2"
    [buttonLink]="PathRoutes.Admins.Edit+'/'+id" [custom]="true">
    <button class="btn btn-danger" (click)="delete()">
      <span class="fas fa-times-circle mr-2"></span>
      Excluir
    </button>
  </app-header>
  <app-card [loading]="loading">
    <div class="row">
      <div class="col-12 pb-5 pt-2 d-flex justify-content-center" *ngIf="item?.profile_picture">
        <img [src]="environment.imageBaseUrl+item?.profile_picture" class="img-fluid screenshot rounded-circle"
          width="150">
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Nome" [value]="item?.name"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Nickname" [value]="item?.nickname"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Email" [value]="item?.email"></app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input [disabled]="true" label="Administrador desde" [value]="item?.created_at | date: 'dd/MM/yyyy'">
        </app-input>
      </div>
      <div class="col-12 pb-3">
        <label for="">Permissões</label>
        <div class="form-check" *ngFor="let p of permissionsArr; let i = index">
          <input class="form-check-input" type="checkbox" value="" [id]="p.key" [checked]="p.checked" disabled>
          <label class="form-check-label" [for]="p.key">
            {{p.label}}
          </label>
          <div *ngIf="p.key == 'posts' && p.checked">
            <div class="form-check" *ngFor="let c of channels; let i = index">
              <input class="form-check-input" type="checkbox" value="" [id]="c.key" [checked]="c.checked" disabled>
              <label class="form-check-label" [for]="c.key">
                {{c.label}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</app-container>
