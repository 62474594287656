<app-navbar [breadcrumbCurrent]="'Administradores'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-md-10 col-12">
  <app-header title="Administradores" icon="fas fa-user-cog" [buttonLink]="PathRoutes.Admins.Add"
    buttonLabel="Adicionar" buttonIcon="fas fa-plus-circle mr-2"></app-header>
  <app-table [columns]="columns" [more]="btnLoading" [loading]="loading" (loadMore)="loadMore()"
    (search)="search($event)" [empty]="items.length == 0" [hasSearch]="true">
    <tr *ngFor="let item of items;" (click)="detail(item.id)" class="tr-link">
      <td width="5%">
        <div class="avatar avatar-sm" *ngIf="item?.profile_picture">
          <img [src]="environment.imageBaseUrl+item?.profile_picture" alt="..." class="avatar-img rounded-circle">
        </div>
        <span class="fas fa-user-circle text-primary display-3" *ngIf="!item?.profile_picture"></span>
      </td>
      <td class="tables-name">
        <span>{{ item.name }}</span>
      </td>
      <td class="tables-nickname">
        <span>{{ item.nickname }}</span>
      </td>
      <td class="tables-email">
        <span>{{ item.email }}</span>
      </td>
      <td class="tables-admin">
        <span>{{ item.created_at | date: 'dd/MM/yyyy' }}</span>
      </td>
    </tr>
  </app-table>
</app-container>
