import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() form: FormGroup = null
  @Input() label: string = null
  @Input() required: boolean = false
  @Input() type: string = 'text'
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() price: any = ''
  @Input() mask: any = ''
  @Input() currency: boolean = false
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() value: string = ''
  @Input() disabled: boolean = false
  @Input() maxlength = null
  @Input() link: string = ''
  @Input() linkLabel: string = ''
  @Input() dropCharacters: any

  constructor() { }

  ngOnInit(): void {
  }

}
