<app-navbar breadcrumbCurrent="Home"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-10">
  <app-header icon="fas fa-poll" title="Home"
    subtitle="Aqui você terá acesso as informações de forma rápida e prática sobre o andamento do aplicativo">
  </app-header>
  <div class="row">
    <!-- <div class="col-12 col-lg-4">
      <a routerLink="{{PathRoutes.Financial}}" class="card bg-secondary lift">
        <div class="card-body">
          <h2 class="text-white">
            <i class="fas fa-dollar-sign"></i>
            Movimentação total do mês
          </h2>
          <div class="display-2 mb-3 text-white">
            {{balance | currency: 'BRL'}}
          </div>
          <p class="font-weight-bold text-white mb-0">
            &nbsp;
          </p>
        </div>
      </a>
    </div>
    <div class="col-12 col-lg-4">
      <a routerLink="{{PathRoutes.Financial}}" class="card bg-secondary lift">
        <div class="card-body">
          <h2 class="text-white">
            <i class="fas fa-dollar-sign"></i>
            Taxa de conveniência total do mês
          </h2>
          <div class="display-2 mb-3 text-white">
            {{fee | currency: 'BRL'}}
          </div>
          <p class="font-weight-bold text-white mb-0">
            &nbsp;
          </p>
        </div>
      </a>
    </div> -->
    <!-- <div class="col-12 col-lg-4">
      <a routerLink="{{PathRoutes.Cases.List}}" class="card lift">
        <div class="card-body">
          <h2 class="text-primary">
            <i class="fas fa-store"></i>
            Novos casos
          </h2>
          <div class="display-2 my-3 text-dark">
            {{casesTotal}}
          </div>
          <p class="font-weight-bold text-muted mb-0">
            Ver mais
          </p>
        </div>
      </a>
    </div> -->
    <!-- <div class="col-12 col-lg-4">
      <a routerLink="{{PathRoutes.Orders.List}}" class="card lift">
        <div class="card-body">
          <h2 class="text-primary">
            <i class="fas fa-newspaper"></i>
            Novos pedidos
          </h2>
          <div class="display-2 my-3 text-dark">
            {{ordersTotal}}
          </div>
          <p class="font-weight-bold text-muted mb-0">
            Ver mais
          </p>
        </div>
      </a>
    </div> -->
    <div class="col-12 col-lg-4" *ngIf="permissions.posts">
      <a routerLink="{{PathRoutes.Posts.List}}" class="card lift">
        <div class="card-body">
          <h2 class="text-primary">
            <i class="fas fa-th-list"></i>
            Novos posts
          </h2>
          <div class="display-2 my-3 text-white">
            {{postsTotal}}
          </div>
          <p class="font-weight-bold text-muted mb-0">
            Ver mais
          </p>
        </div>
      </a>
    </div>
    <div class="col-12 col-lg-4" *ngIf="permissions.users">
      <a routerLink="{{PathRoutes.Users.List}}" class="card lift">
        <div class="card-body">
          <h2 class="text-primary">
            <i class="fas fa-user-friends"></i>
            Novos usuários
          </h2>
          <div class="display-2 my-3 text-white">
            {{usersTotal}}
          </div>
          <p class="font-weight-bold text-muted mb-0">
            Ver mais
          </p>
        </div>
      </a>
    </div>
    <div class="col-12 col-lg-4" *ngIf="permissions.admins">
      <a routerLink="{{PathRoutes.Admins.List}}" class="card lift">
        <div class="card-body">
          <h2 class="text-primary">
            <i class="fas fa-users-cog"></i>
            Administradores
          </h2>
          <div class="display-2 my-3 text-white">
            {{adminsTotal}}
          </div>
          <p class="font-weight-bold text-muted mb-0">
            Ver mais
          </p>
        </div>
      </a>
    </div>
  </div>
</app-container>
