<div [formGroup]="form" class="pt-4">
  <div class="form-group">
    <button class="btn btn-primary d-block btn-block" (click)="actionEmittted()" *ngIf="action"
      [disabled]="disabled">
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ label }}
    </button>
    <button class="btn btn-primary d-block btn-block" *ngIf="!action"
      [disabled]="disabled">
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ label }}
    </button>
  </div>
</div>
