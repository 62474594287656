
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { HomeAdsService } from 'src/app/services/home-ads.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home-ads',
  templateUrl: './home-ads.component.html',
  styleUrls: ['./home-ads.component.scss']
})
export class HomeAdsComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  form: FormGroup
  id = null
  loading = false
  ufs = []
  files = []
  hasLogo = false
  image_url: any
  item: any
  establishment_id: any
  price: any
  categories = []
  activeOptions = [
    { id: 1, name: 'Sim' },
    { id: 0, name: 'Não' }
  ]
  hasItem: any
  promotionalPrice = false
  percentage = 0
  discount = null
  category_name = null
  fileName = 'Clique ou arraste aqui para inserir sua imagem'
  loadingImage = false
  imgLoaded: any
  submitting = false

  constructor(
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private homeAdsService: HomeAdsService,
  ) { }

  ngOnInit(): void {
    this.getItem()
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "title": [null, Validators.required],
      "image": [null],
      "url": [null],
      "store_review": [false],
    })
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.homeAdsService.get()
      this.item = res.data
      this.form.patchValue(res.data)
    } catch (error) {
      console.log(error)
      this.loading = false
    } finally {
      this.loading = false
    }
  }

  storeReviewChanged(e) {
    this.form.get('store_review').setValue(e.target.checked ? 1 : 0)
    this.form.get('url').setValue('')
  }

  async save() {
    try {
      let params = this.form.getRawValue()
      if (this.image_url && this.image_url != params.image) {
        params.image = this.image_url
      } else {
        delete (params.image)
      }
      params.store_review = params.store_review ? 1 : 0
      console.log(params)
      // return
      this.submitting = true
      let res = await this.homeAdsService.save(params)
      if (res.status) {
        console.log(res.data)
        this.helper.triggerNotification(true)
        this.cancelUpload()
        this.getItem()
      } else {
        this.helper.triggerNotification(false)
      }
    } catch (error) {
      this.helper.triggerNotification(false)
      console.log(error)
      this.submitting = false
    } finally {
      this.submitting = false
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isFileValid: any

  fileChangeEvent(event: any): void {
    this.loadingImage = true
    this.isFileValid = false
    this.imageChangedEvent = event
    let hiddenImage: any = document.getElementById('preview')
    let reader = new FileReader
    reader.readAsDataURL(event.target.files[0])

    reader.addEventListener("load", function () {
      hiddenImage.src = reader.result;
    }, false);

    setTimeout(() => {
      // if (hiddenImage.height < 250) {
      //   this.helper.triggerNotification(false, 'Erro', 'Tamanho mínimo para altura é de 250px')
      //   this.isFileValid = false
      // } else
      if (event.target.files[0].size > 1000000) {
        this.helper.triggerNotification(false, 'Erro', 'Tamanho máximo para imagem é de 1mb')
        this.isFileValid = false
      } else {
        this.isFileValid = true
      }
      this.loadingImage = false
    }, 1000)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.image_url = this.helper.dataURLtoFile(event.base64, 'homeads.jpg')
  }

  cancelUpload() {
    this.image_url = null;
    this.isFileValid = false
    this.fileName = 'Clique ou arraste aqui para inserir sua imagem'
  }

}
