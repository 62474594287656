import { HelperService } from 'src/app/helpers/helper.service';
import { environment } from 'src/environments/environment';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss']
})
export class UsersDetailComponent implements OnInit {
  environment = environment
  PathRoutes = PathRoutes
  loading = false
  id: any
  item: any
  requestsLeft = 0
  paymentHistory = []
  percentage: any

  constructor(
    public helper: HelperService,
    private usersService: UsersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getItem()
  }

  getItem() {
    this.loading = true
    this.usersService.get(this.id).then((res: any) => {
      if (res.status) {
        this.item = res.data
      } else {
        this.router.navigate([PathRoutes.NotFound])
      }

    })
      .catch(e => console.log(e))
      .finally(() => this.loading = false)
  }

  getPaymentHistory() {
    this.usersService.paymentHistory(this.id)
      .then((res: any) => {
        if (res.status) {
          this.paymentHistory = res.data
        }
      })
      .catch(e => console.log(e))
      .finally(() => this.loading = false)
  }



  activeUser(id, active) {
    this.helper.cancelSwal(active).then((result) => {
      if (result.value) {
        this.usersService.active({ id, active: active ? 1 : 0 })
          .then((res: any) => {
            if (res.status) {
              if (active) {
                this.helper.triggerNotification(true, 'Desbloqueado', 'Usuário desbloqueado com sucesso');
              } else {
                this.helper.triggerNotification(true, 'Bloqueado', 'Usuário bloqueado com sucesso')
              }
              this.getItem()
            } else {
              this.helper.triggerNotification(false, 'Erro', 'Ops, ocorreu algum erro!')
            }
          })
      }
    })
  }
  deleteUser(id) {
    this.helper.cancelSwal(false).then((result) => {
      if (result.value) {
        this.usersService.delete(id)
          .then((res: any) => {
            if (res.status) {

              this.helper.triggerNotification(true, 'Excluído', 'Usuário excluído com sucesso');
              this.router.navigate([PathRoutes.Users.List])
            } else {
              this.helper.triggerNotification(false, 'Erro', 'Ops, ocorreu algum erro!')
            }
          })
      }
    })
  }
}
