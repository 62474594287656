<div class="d-flex align-items-center border-top border-top-2 border-primary vh-100">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
        <!-- <div class="d-flex align-items-start">
          <img src="assets/img/logo.png" alt="Logo" class="rounded mb-4" height="100">
        </div> -->
        <form [formGroup]="form" (submit)="login()">
          <div class="form-group">
            <label>
              Email
            </label>
            <input type="email" [ngClass]="{'is-invalid': form.controls.email.touched && !form.controls.email.valid}"
              formControlName="email" name="login-email" placeholder="nome@email.com" class="form-control">
            <div class="invalid-feedback">Digite seu email</div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label>Password</label>
              </div>
            </div>
            <div class="form-group">
              <input type="password"
                [ngClass]="{'is-invalid': form.controls.password.touched && !form.controls.password.valid}"
                formControlName="password" name="login-password" placeholder="Sua senha" class="form-control">
              <div class="invalid-feedback">Informe sua senha</div>
            </div>
          </div>
          <div class="text-center">
            <small>{{message}}</small>
          </div>
          <button type="submit" [disabled]="!form.valid || loading" class="btn btn-lg btn-block btn-primary mb-3 mt-4">
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Entrar
          </button>
          <div class="text-center">
            <small>
              Esqueceu sua senha? <a routerLink="{{ PathRoutes.Password.Forgot }}">Cadastre uma
                nova.</a>
            </small>
          </div>
        </form>
      </div>
    </div>
    <div class="row justify-content-center align-items-center" *ngIf="environment.version">
      <div class="col-12 col-md-5 col-xl-4 text-right">
        <span class="text-muted">
          {{ environment.version }}
        </span>
      </div>
    </div>
  </div>
</div>
