import { Injectable } from '@angular/core';
import { Endpoints } from './../routes/endpoints';
import { NetworkService } from './network.service';
@Injectable({
  providedIn: 'root'
})
export class LivesService {

  constructor(private network: NetworkService) { }

  // list(filters){
  //   var url = `${Endpoints.Admins}`
  //   return this.network.get(url)
  // }

  get() {
    var url = `${Endpoints.Lives}`
    return this.network.get(url)
  }

  async save(params) {
    let res = await this.get()
    let live_id = res.data[0]?.id
    console.log('live_id', live_id)
    if (live_id) {
      return this.network.put(`${Endpoints.Lives}/${live_id}`, params)
    } else {
      return this.network.post(Endpoints.Lives, params)
    }
  }

  async saveAccessToken(params) {
    let res = await this.get()
    let live_id = res.data[0]?.id
    console.log('live_id', live_id)
    await this.network.put(`${Endpoints.Lives}/${live_id}`, params)
  }

  async getAccessToken(code) {
    const lc = await this.get()
    let livesConfig = lc.data[0]
    this.network.makeExternalRequest(`https://accounts.google.com/o/oauth2/token?code=${code}&client_id=${livesConfig.client_id}&client_secret=${livesConfig.client_secret}&redirect_uri=${livesConfig.redirect_uri}&grant_type=authorization_code&prompt=consent&access_type=offline`, {}, 'post')
      .then(async (res: any) => {
        if (res.error) {
          console.log('ERRO PARA GERAR ACCESS E REFRESH TOKEN', res.error)
        } else {
          console.log(res)
          await this.saveAccessToken({ access_token: res.access_token, refresh_token: res.refresh_token })
        }
      })
  }

}
