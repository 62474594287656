<div class="form-group" [formGroup]="form" *ngIf="!disabled">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>


  <input *ngIf="!currency" [type]="type" [formControlName]="name" class="form-control" [placeholder]="placeholder"
    [ngClass]="ngClass" [dropSpecialCharacters]="dropCharacters" [mask]="mask" [maxlength]="maxlength">

  <input *ngIf="currency" [type]="type" [formControlName]="name" class="form-control" [placeholder]="placeholder"
    [ngClass]="ngClass" currencyMask>

  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
<div class="form-group" *ngIf="disabled">
  <label class="text-muted" *ngIf="label">{{ label }} </label>
  <a *ngIf="link" routerLink="{{ link }}" class="float-right"><small>{{ linkLabel }}</small></a>
  <input class="form-control" [value]="value" [disabled]="true">
</div>
