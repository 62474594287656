import { SessionService } from 'src/app/services/session.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { PostsService } from 'src/app/services/posts.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-posts-detail',
  templateUrl: './posts-detail.component.html',
  styleUrls: ['./posts-detail.component.scss']
})
export class PostsDetailComponent implements OnInit {
  PathRoutes = PathRoutes
  id: any
  loading = false
  item: any
  environment = environment
  session: any
  height: any
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  target: any
  src: any

  @ViewChild('postImage', { static: true }) public postImage: ElementRef;

  constructor(
    private activcateRoute: ActivatedRoute,
    private postsService: PostsService,
    private router: Router,
    private sessionService: SessionService,
    public helper: HelperService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.id = this.activcateRoute.snapshot.paramMap.get('id');
    this.session = this.sessionService.getSession()
    this.getItem()

    this.galleryOptions = [
      {
        width: '100%',
        height: '450px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ]
  }

  async getItem() {
    this.loading = true
    try {
      const res = await this.postsService.show(this.id);
      const post = res.data;
      post.symbol = post.symbol && post.symbol.split('|')
      this.item = post
      // this.calculateSizes()
      this.target = post.poll_options.reduce((p, c) => p += c.vote_count, 0)
      if (post.videos.length > 0) {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${post.videos[0].video_id}`);
      }

      this.galleryImages = post.images.map(elem => ({ small: environment.imageBaseUrl + elem.url, medium: environment.imageBaseUrl + elem.url, big: environment.imageBaseUrl + elem.url }))
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  calculateProgress(item) {
    if (this.target == 0) {
      return '0%'
    } else if (item?.vote_count > this.target) {
      return '100%'
    } else {
      let p = item?.vote_count * 100 / this.target
      return `${p.toFixed(2)}%`
    }
  }

  async deletePost() {
    try {
      let swal = await this.helper.deleteSwal()
      if (swal.value) {
        let del = await this.postsService.update(this.id, { deleted: 1 })
        this.router.navigate([PathRoutes.Posts.List])
      }
    } catch (error) {
      console.log(error)
    }
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.calculateSizes()
  // }

  // calculateSizes() {
  //   if (this.item?.images.length > 0) {
  //     setTimeout(() => {
  //       let width = this.postImage.nativeElement.offsetWidth
  //       console.log(width)
  //       this.height = `${width / 16 * 9}px`
  //       console.log(this.height)
  //     }, 250);
  //   }
  // }

}
