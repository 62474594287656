<app-navbar [breadcrumbCurrent]="'Comunidades'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-md-10 col-12">
  <app-header title="Comunidades" icon="fas fa-th-large" [custom]="true">
    <div class="col-auto">
      <button class="btn btn-primary ml-2" (click)="setupForm()" data-toggle="modal" data-target="#modalChannels">
        Adicionar
        <i class="fas fa-plus-circle ml-1"></i>
      </button>
    </div>
  </app-header>
  <app-table [columns]="columns" [empty]="items.length == 0" [loading]="loading">
    <tr *ngFor="let item of items;" class="tr-link">
      <td class="tables-name">
        <span>{{ item.title }}</span>
      </td>
      <td width="5%">
        <div class="dropdown">
          <a href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            class="dropdown-ellipses dropdown-toggle">
            <i class="fe fe-more-vertical"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#" (click)="getItem(item.id)" data-toggle="modal"
              data-target="#modalChannels">Editar</a>
            <button class="btn btn-link dropdown-item text-danger" href="#"
              (click)="deleteChannel(item.id)">Excluir</button>
          </div>
        </div>
      </td>
    </tr>
  </app-table>
</app-container>
<app-modal identifier="modalChannels" [title]="hasItem ? 'Editar Comunidade' : 'Adicionar Comunidade'">
  <form [formGroup]="form" (submit)="save()">
    <div class="row pb-5 pt-2" *ngIf="picture">
      <div class="col-12 d-flex justify-content-center">
        <img [src]="environment.imageBaseUrl+picture" class="img-fluid screenshot rounded-circle" width="150">
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-12 d-flex justify-content-center">
        <app-loading *ngIf="loadingImage"></app-loading>
      </div>
      <div class="col-12 d-flex flex-column" *ngIf="!loadingImage">
        <small>
          <span *ngIf="isFileValid" class="text-danger" style="cursor: pointer" (click)="cancelUpload()">Cancelar</span>
        </small>
        <div>
          <app-input-file [form]="form" [fileName]="fileName" (fileInput)="fileChangeEvent($event)"
            accept=".png, .jpg, .jpeg">
          </app-input-file>
          <img [src]="imgLoaded" id="preview" hidden />
        </div>
        <div *ngIf="isFileValid" class="row pt-3">
          <div class="col-md-6 col-12">
            <h4>Imagem original</h4>
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
              format="jpg" backgroundColor="#FFFFFF" (imageCropped)="imageCropped($event)"
              [containWithinAspectRatio]="true">
            </image-cropper>
          </div>
          <div class="col-md-6 col-12">
            <h4>Imagem cortada</h4>
            <div class="text-center">
              <img [src]="croppedImage" style="border: 1px dashed #D2DDEC; width:100%; border-radius: 10px" />
            </div>
          </div>
        </div>
        <!-- <div *ngIf="!isFileValid" class="d-flex justify-content-center pt-4">
            <img [src]="item?.image_url ? environment.imageBaseUrl+item?.image_url : 'assets/img/placeholder.jpg'"
              class="img-fluid screenshot" style="max-width: 500px;">
          </div> -->
      </div>
    </div>
    <app-input type="text" name="title" placeholder="Nome do Comunidade" [form]="form"
      [ngClass]="{'is-invalid': form.controls.title.touched && form.controls.title.invalid }"
      [messageCondition]="form.controls.title.touched && form.controls.title.invalid" message="Digite o nome do Comunidade">
    </app-input>

    <app-input type="text" name="description" placeholder="Descrição do Comunidade" [form]="form"
      [ngClass]="{'is-invalid': form.controls.title.touched && form.controls.title.invalid }"
      [messageCondition]="form.controls.title.touched && form.controls.title.invalid" message="Digite o Descrição do Comunidade">
    </app-input>

    <!-- <label for="">Planos</label>
    <div class="form-check" *ngFor="let p of plans; let i = index">
      <input class="form-check-input" type="checkbox" value="" [id]="p.id" [checked]="p.checked"
        (change)="plansChanged(i)">
      <label class="form-check-label" [for]="p.id">
        {{p.name}}
      </label>
    </div> -->

    <app-form-button label="Concluir" [disabled]="loading || form.invalid" [loading]="loading" [form]="form">
    </app-form-button>
  </form>
</app-modal>