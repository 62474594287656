<app-navbar [breadcrumbCurrent]="'Home Ads'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Home Ads" icon="fas fa-ad"></app-header>
  <app-card [loading]="loading">
    <form [formGroup]="form" (submit)="save()">
      <div class="row py-4">
        <div class="col-12 d-flex justify-content-center">
          <app-loading *ngIf="loadingImage"></app-loading>
        </div>
        <div class="col-12 d-flex flex-column" *ngIf="!loadingImage">
          <small>
            <span *ngIf="isFileValid" class="text-danger" style="cursor: pointer"
              (click)="cancelUpload()">Cancelar</span>
          </small>
          <div>
            <app-input-file [form]="form" [fileName]="fileName" (fileInput)="fileChangeEvent($event)"
              accept=".png, .jpg, .jpeg">
            </app-input-file>
            <img [src]="imgLoaded" id="preview" hidden />
          </div>
          <div *ngIf="isFileValid" class="row pt-3">
            <div class="col-md-6 col-12">
              <h4>Imagem original</h4>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="9/16"
                format="jpg" backgroundColor="#FFFFFF" (imageCropped)="imageCropped($event)"
                [containWithinAspectRatio]="true">
              </image-cropper>
            </div>
            <div class="col-md-6 col-12">
              <h4>Imagem cortada</h4>
              <div class="text-center">
                <img [src]="croppedImage" style="border: 1px dashed #D2DDEC; width:100%; border-radius: 10px" />
              </div>
            </div>
          </div>
          <div *ngIf="!isFileValid" class="d-flex justify-content-center pt-4">
            <img [src]="item?.image ? environment.imageBaseUrl+item?.image : 'assets/img/placeholder.jpg'"
              class="img-fluid screenshot" style="max-width: 500px;">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-input [form]="form" label="Título" [required]="true" name="title" placeholder="Nome"
            [ngClass]="{'is-invalid': form.controls['title'].touched && !form.controls['title'].valid}"
            [messageCondition]="form.controls['title'].touched && !form.controls['title'].valid"
            message="Preencha o título"></app-input>
        </div>
        <div class="col-12" *ngIf="!form.controls['store_review'].value">
          <app-input [form]="form" label="Link" [required]="true" name="url" placeholder="https://google.com"
            [ngClass]="{'is-invalid': form.controls['url'].touched && !form.controls['url'].valid}"
            [messageCondition]="form.controls['url'].touched && !form.controls['url'].valid" message="Preencha o link">
          </app-input>
        </div>
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" name="store_review"
              [checked]="form.controls['store_review'].value" (change)="storeReviewChanged($event)">
            <label class="form-check-label">
              Avaliação das lojas
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-form-button [form]="form" [loading]="submitting" [disabled]="submitting || form.invalid"
            [label]="'Concluir'">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-container>
