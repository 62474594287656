<app-navbar [breadcrumbCurrent]="'Detalhes'" [breadcrumbLink]="PathRoutes.Posts.List"
  [breadcrumbLinkName]="'Postagens'">
</app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header [title]="'Detalhes da postagem'" [custom]="true"
    [buttonLabel]="item?.user?.id == session?.id  && item?.type == 'text' ? 'Editar': false"
    buttonIcon="fe fe-edit mr-2" [buttonLink]="PathRoutes.Posts.Edit+'/'+id">
    <button *ngIf="!item?.deleted" class="btn btn-danger" (click)="deletePost()">
      <span class="fas fa-times-circle mr-2"></span>
      Excluir
    </button>
    <span *ngIf="item?.deleted">
      Postagem exlcuida
      <i class="fas fa-times-circle text-danger"></i>
    </span>
  </app-header>
  <app-card [loading]="loading">
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <h2 class="mr-3" *ngFor="let s of item?.symbol">
          <span class="badge badge-soft-primary">
            {{s}}
          </span>
        </h2>
        <h2 *ngIf="item?.trend">
          <span class="badge"
            [ngClass]="{'badge-soft-info': item?.trend == 'neutral', 'badge-soft-success': item?.trend == 'up', 'badge-soft-danger': item?.trend == 'down'}">
            {{item?.trend == 'neutral' ? 'Neutra' : item?.trend == 'up' ? 'Alta' : 'Baixa' }}
          </span>
        </h2>
      </div>
    </div>
    <div class="row pb-3" *ngIf="item?.type == 'text' && item?.images.length > 0">
      <div class="col-12">
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
      </div>
    </div>

    <!-- <div class="row py-5" *ngIf="item?.type == 'text' && item?.images.length > 0">
      <div #postImage class="col-12 col-md-4" *ngFor="let img of item?.images">
        <a href="" data-toggle="modal" class="text-primary" data-target="#modalImages">
          <img #postImage [src]="environment.imageBaseUrl+img?.url" class="img-fluid screenshot rounded"
            [ngStyle]="{'height': height}">
        </a>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <app-input label="Autor" [value]="item?.user?.name || '---'" [disabled]="true"></app-input>
      </div>
      <div class="col-12">
        <app-input label="Comunidade" [value]="item?.channel?.title || '---'" [disabled]="true"></app-input>
      </div>
      <div class="col-12">
        <app-input label="Canal" [value]="item?.subchannel?.title || '---'" [disabled]="true"></app-input>
      </div>
      <div class="col-12">
        <app-input label="Título" [value]="item?.title || '---'" [disabled]="true"></app-input>
      </div>
    </div>
    <div class="row" *ngIf="item?.type == 'text'">
      <div class="col-12">
        <label class="text-muted">Descrição</label>
        <div class="col-12 card p-3 shadow-none" [innerHtml]="item?.description" style="background-color: #323844;">
        </div>
        <!-- <app-textarea label="Descrição" [value]="item?.description" disabled="true">
        </app-textarea> -->
      </div>
      <div class="col-12" *ngIf="item?.audios.length > 0">
        <div class="form-group">
          <label class="text-muted">Áudio</label>
          <audio [src]="environment.imageBaseUrl+item?.audios[0]?.url" controls style="width: 100%"></audio>
        </div>
      </div>
      <div class="col-12" *ngIf="item?.videos.length > 0">
        <div class="form-group">
          <label class="text-muted">Vídeo</label>
          <iframe *ngIf="item?.videos[0]" [src]="src" width="100%" height="400" frameborder="0"
            [title]="video?.title"></iframe>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="item?.type == 'poll'">
      <div class="col-12">
        <label class="text-muted">Opções</label>
      </div>
      <div class="col-12" *ngFor="let option of item?.poll_options">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <div class="d-flex justify-content-between mb-3">
                  <h3 class="mb-0">
                    {{ option?.text }}
                  </h3>
                  <h2 class="mb-0">
                    <small>
                      {{ calculateProgress(option) }}
                    </small>
                  </h2>
                </div>

                <div class="row align-items-center no-gutters mb-3">
                  <div class="col">
                    <div class="progress progress-sm">
                      <div class="progress-bar" role="progressbar" [ngStyle]="{'width': calculateProgress(option)}"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <span class="h5 mb-0">
                    {{ option?.vote_count }} {{ option?.vote_count == 1 ? 'voto': 'votos' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </app-card>
</app-container>
<app-modal identifier="modalImages" title="Imagens">
  <ngb-carousel>
    <ng-template ngbSlide *ngFor="let img of item?.images">
      <div class="picsum-img-wrapper">
        <img [src]="environment.imageBaseUrl+img?.url" alt="Angular Carousel 1">
      </div>
    </ng-template>
  </ngb-carousel>
</app-modal>
