<div>
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <div #inputFileCustom class="form-control-custom d-flex justify-content-center align-items-center"
    [ngStyle]="{'height': height}">
    <ng-content></ng-content>

    <div *ngIf="!hasFile" class="empty-state d-flex justify-content-center align-items-center">
      <i class="fas fa-image text-secondary display-2"></i>
    </div>

    <input type="file" (change)="fileChange($event);" (click)="fileClicked($event)"
      [accept]="accept || '.xls, .xlsx'" />
    <!-- <p class="small-custom">{{fn}}</p> -->
    <div class="actions">
      <i *ngIf="hasEdit" class="fas fa-pencil-alt text-white shadow bg-primary rounded icon border border-white"></i>
      <i *ngIf="!hasFile" class="fas fa-plus text-white shadow bg-primary rounded icon border border-white"></i>
      <i *ngIf="hasCancel" style="z-index: 99; cursor: pointer;"
        class="fas fa-times text-white shadow bg-primary rounded icon border border-white"
        (click)="cancelUploadClicked()"></i>
      <i *ngIf="hasImage" style="z-index: 99; cursor: pointer;"
        class="fas fa-times text-white shadow bg-primary rounded icon border border-white"
        (click)="deleteImageClicked()"></i>
    </div>
  </div>
</div>
