import { UsersService } from 'src/app/services/users.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from './../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from './../../helpers/helper.service';
import { Component, OnInit } from '@angular/core';
import { PostsService } from 'src/app/services/posts.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {
  columns = ['Autor', 'Título', 'Tipo', 'Canal', 'Data']
  environment = environment
  PathRoutes = PathRoutes
  loading = false
  loadingMore = false
  items = []
  filters = {
    search: '',
    page: 1,
  }
  btnLoading = false
  paginateInfo: any

  constructor(
    public helper: HelperService,
    private postsService: PostsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getItems();
  }

  getItems(loadingMore = false) {

    if (!loadingMore) {
      this.filters.page = 1
      this.loading = true
    } else {
      this.loadingMore = true
    }

    this.postsService.list(this.filters)
      .then((res: any) => {
        const { status, data } = res;
        if (status) {
          console.log(data);
          
          const posts = data.map(elem => ({ ...elem, type_string: elem.type == 'text' ? 'Texto' : 'Enquete' }))
          this.paginateInfo = { to: res.to, total: res.total }
          this.btnLoading = res.next_page_url ? true : false
          this.items = loadingMore ? this.items.concat(posts) : posts
        }
        this.filters.page++
      })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
        this.loadingMore = false
      })
  }

  loadMore() {
    this.getItems(true)
  }

  search(string) {
    this.filters.search = string
    this.getItems(false)
  }

  detail(id) {
    this.router.navigate([PathRoutes.Posts.Detail + `/${id}`])
  }
}
