import { PathRoutes } from 'src/app/routes/path-routes';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { HelperService } from 'src/app/helpers/helper.service';
import { AdminsService } from 'src/app/services/admins.service';

@Component({
  selector: 'app-admins-change-password',
  templateUrl: './admins-change-password.component.html',
  styleUrls: ['./admins-change-password.component.scss']
})
export class AdminsChangePasswordComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  id = null

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private helper: HelperService,
    private adminsService: AdminsService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": this.id,
      "old_password": ['', Validators.compose([Validators.required])],
      "password": ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      "confirm_password": ['']
    })
  }

  save() {
    this.loading = true
    if (!this.form.invalid) {
      this.userService.save(this.form.getRawValue())
        .then((res: any) => {
          if (res.status) {
            this.helper.triggerNotification(true);
            this.router.navigate([PathRoutes.Admins.List])
          } else {
            this.helper.triggerNotification(false, 'Ocorreu um erro', res.message);
            this.loading = false
          }
        })
        .catch(error => console.log(error))
    }
  }
}
