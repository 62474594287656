<div class="containder-fluid pt-7">
  <div class="row justify-content-center w-100">
    <div class="col-10">
      <div class="text-center mb-4">
        <h1 class="mb-1">
          Termos de uso
        </h1>
      </div>

      <div class="row justify-content-center">
        <div class="col-10 card p-5" [innerHtml]="terms"></div>
      </div>

    </div>
  </div>
</div>
