import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PathRoutes } from 'src/app/routes/path-routes';
import { ReportsService } from 'src/app/services/reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';

@Component({
  selector: 'app-reports-detail',
  templateUrl: './reports-detail.component.html',
  styleUrls: ['./reports-detail.component.scss']
})
export class ReportsDetailComponent implements OnInit {
  id: any
  item: any
  loading = false
  environment = environment
  PathRoutes = PathRoutes
  src: any

  constructor(
    private reportsService: ReportsService,
    private activatedRoute: ActivatedRoute,
    public helper: HelperService,
    private router: Router,
    protected _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getItem()
  }

  async getItem() {
    this.loading = true
    try {
      let res = await this.reportsService.showReports(this.id)
      if (res.status) {
        this.item = res.data
        this.src = environment.production ? environment.imageBaseUrl + res.data.path : this._sanitizer.bypassSecurityTrustResourceUrl(environment.imageBaseUrl + res.data.path)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  getType(t) {
    switch (t) {
      case 'report':
        return 'Relatórios'
      case 'recommended_wallet':
        return 'Carteira recomendada'
    }
  }

  async delete() {
    try {
      let swal = await this.helper.deleteSwal()
      if (swal.value) {
        let del = await this.reportsService.deleteReport(this.id)
        console.log(del)
        this.router.navigate([PathRoutes.Reports.List])
      }
    } catch (error) {
      console.log(error)
    }
  }

}
