<app-navbar [breadcrumbCurrent]="'Notificação'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header [title]="'Enviar Notificação'" icon="fas fa-bell"></app-header>
  <app-card>
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12">
          <app-input [form]="form" label="Título" [required]="true" name="title" placeholder="Título"
            [ngClass]="{'is-invalid': form.controls['title'].touched && !form.controls['title'].valid}"
            [messageCondition]="form.controls['title'].touched && !form.controls['title'].valid"
            message="Preencha o título"></app-input>
        </div>
        <div class="col-12">
          <app-textarea [form]="form" label="Descrição" [required]="true" name="description" placeholder="Descrição"
            [ngClass]="{'is-invalid': form.controls['description'].touched && !form.controls['description'].valid}"
            [messageCondition]="form.controls['description'].touched && !form.controls['description'].valid"
            message="Preencha a descrição"></app-textarea>
        </div>
        <div class="col-12">
          <app-select label="Plano" placeholder="Selecione o plano" [form]="form" name="plan_id" [options]="plans"
            [optionsId]="true"
            [ngClass]="{'is-invalid': form.controls.plan_id.touched && form.controls.plan_id.invalid}"
            [messageCondition]="form.controls.plan_id.touched && form.controls.plan_id.invalid"
            message="Selecione o plano">
          </app-select>
          <!-- (change)="changeResearchCompany($event)" -->
        </div>
      </div>
      <app-form-button [loading]="loading" [disabled]="loading || form.invalid" [form]="form" [label]="'Enviar'">
      </app-form-button>
    </form>
  </app-card>
</app-container>
