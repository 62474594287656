<app-navbar [breadcrumbCurrent]="'Usuários'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-md-10">
  <app-header title="Usuários" icon="fas fa-user-friends" [custom]="true">
    <div class="row">
      <div class="col">
        <app-flatpickr (change)="dateChange($event)"></app-flatpickr>
      </div>
      <div class="col-auto">
        <select (change)="planStatusChange($event)" class="custom-select">
          <option value="" disabled selected>Status</option>
          <option value="">Todas</option>
          <option *ngFor="let p of planStatus" [value]="p.key">{{p.label}}</option>
        </select>
      </div>
      <div class="col-auto">
        <a class="btn btn-primary" (click)="exportUsers($event)">
          <i class="fas fa-download"></i>
          Exportar
        </a>
      </div>
      <div class="col-auto">
        <app-button [link]="PathRoutes.Users.Add" icon="fas fa-plus-circle mr-2" label="Adicionar"></app-button>
      </div>
    </div>
  </app-header>
  <app-table [columns]="columns" [more]="btnLoading" [loading]="loading" (loadMore)="loadMore()"
    (search)="search($event)" [empty]="items.length == 0" [hasSearch]="true">
    <tr *ngFor="let item of items;" (click)="detail(item.id)" class="tr-link">
      <td>
        <div class="avatar avatar-sm" *ngIf="item?.profile_picture">
          <img [src]="environment.imageBaseUrl+item?.profile_picture" alt="..." class="avatar-img rounded-circle">
        </div>
        <span class="fas fa-user-circle text-primary display-3" *ngIf="!item?.profile_picture"></span>
      </td>
      <td width="65%">
        <span>{{ item.name }}</span>
      </td>
      <td width="20%">
        <span>{{ item.email }}</span>
      </td>
      <td width="20%">
        <span>{{ item.payment_status }}</span>
      </td>
      <td width="10%">
        <span>{{ item.created_at | date: 'dd/MM/yyyy' }}</span>
      </td>
    </tr>
  </app-table>
</app-container>
