<app-navbar [breadcrumbCurrent]="'Banners'"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-md-10">
  <app-header title="Banners" icon="fas fa-mobile-alt" [buttonLink]="PathRoutes.BannerApp.Add" buttonLabel="Adicionar"
    buttonIcon="fas fa-plus-circle mr-2"></app-header>
  <app-table [columns]="columns" [loading]="loading" [empty]="items.length == 0" cdkDropList
    (cdkDropListDropped)="drop($event)" (orderAction)="updateOrder()" (enableAction)="order()" [hasOrder]="true"
    [moveEnabled]="moveEnabled">
    <tr *ngFor="let item of items;" (click)="detail(item.id)" class="tr-link" cdkDrag [cdkDragDisabled]="!moveEnabled"
      [ngClass]="{'move-cursor': moveEnabled, 'pointer-cursor': !moveEnabled}">
      <td>
        <div class="avatar avatar-sm" *ngIf="item?.image">
          <img [src]="environment.imageBaseUrl+item?.image" alt="..." class="img-fluid screenshot rounded">
        </div>
        <span class="fas fa-user-circle text-primary display-3" *ngIf="!item?.image"></span>
      </td>
      <td width="65%">
        <span>{{ item.title }}</span>
      </td>
    </tr>
  </app-table>
</app-container>
