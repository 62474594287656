import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.scss']
})
export class UsersAddComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  form: FormGroup
  id = null
  loading = false;
  loadingAdmin = false
  loadingImage = false
  image_url: any
  fileName = 'Clique ou arraste aqui para inserir sua imagem'
  message: any
  item: any
  reg = /[^a-z0-9._@]/g
  public customPatterns = { '0': { pattern: new RegExp(this.reg) } };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private usersService: UsersService,
  ) { }


  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.setupForm();

    if (this.id) {
      this.getItem()
    }
  }

  getItem() {
    this.loadingAdmin = true
    this.usersService.get(this.id)
      .then((res: any) => {
        if (res.status) {
          this.item = res.data
          this.form.patchValue(res.data)
        }
      })
      .catch(e => console.log(e))
      .finally(() => this.loadingAdmin = false)
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": this.id,
      "nickname": [''],
      "name": ['', Validators.compose([Validators.required])],
      "email": ['', Validators.compose([Validators.required, Validators.email])],
      "phone": ['', Validators.compose([Validators.required])],
      // "start_date": ['', Validators.compose([Validators.required])],
      "expiration_date": ['', Validators.compose([Validators.required])],
      "password": ['', Validators.compose(!this.id ? [Validators.required, Validators.minLength(8)] : [])],
      "confirm_password": [''],
      "type": 'user',
    })
  }

  toLowerCase(event) {
    event.target.value = event.target.value.toLowerCase();
  }

  save() {
    let params
    if (this.image_url) {
      params = {
        ...this.form.getRawValue(),
        profilePicture: this.image_url
      }
    } else {
      params = this.form.getRawValue()
    }

    if (params.id) {
      delete (params.password)
      delete (params.confirm_password)
    }

    console.log(params)
    // return
    this.loading = true

    if (!this.form.invalid) {
      this.usersService.save(params)
        .then((res: any) => {
          if (res.status) {
            this.helper.triggerNotification(true);
            this.router.navigate([PathRoutes.Users.Detail + '/' + res.data.id])
          } else {
            let p = []
            Object.keys(res.errors).forEach((key) => res.errors[key].map(e => p.push(e)));
            this.helper.triggerNotification(false, 'Erro', p.map(elem => elem).join('\n'), null);
            this.loading = false
          }
        })
        .catch(e => {
          let p = []
          Object.keys(e.error.errors).forEach((key) => e.error.errors[key].map(r => p.push(r)));
          this.helper.triggerNotification(false, 'Erro', p.map(elem => elem).join('\n'), null)
          this.loading = false
        })
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isFileValid: any

  fileChangeEvent(event: any): void {
    this.loadingImage = true
    this.isFileValid = false
    this.imageChangedEvent = event
    let hiddenImage: any = document.getElementById('preview')
    let reader = new FileReader
    reader.readAsDataURL(event.target.files[0])

    reader.addEventListener("load", function () {
      hiddenImage.src = reader.result;
    }, false);

    setTimeout(() => {
      if (hiddenImage.height < 250) {
        this.helper.triggerNotification(false, 'Erro', 'Tamanho mínimo para altura é de 250px')
        this.isFileValid = false
      } else if (event.target.files[0].size > 2000000) {
        this.helper.triggerNotification(false, 'Erro', 'Tamanho máximo para imagem é de 2mb')
        this.isFileValid = false
      } else {
        this.isFileValid = true
      }
      this.loadingImage = false
    }, 1000)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.image_url = this.helper.dataURLtoFile(event.base64, 'profile.jpg')
  }

  cancelUpload() {
    this.image_url = null;
    this.isFileValid = false
    this.fileName = 'Clique ou arraste aqui para inserir sua imagem'
  }

}
