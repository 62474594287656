<app-navbar breadcrumbCurrent="Termos de uso"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-12 col-lg-10 col-xl-8">
  <app-header title="Termos de uso" buttonLabel="Visualizar" [buttonLink]="PathRoutes.TermsOfUse.Show"
    icon="fas fa-file-signature"></app-header>
  <app-card>
    <form [formGroup]="form" (submit)="save()">
      <ckeditor formControlName="value" [config]="{uiColor: '#CCCCCC', entities:false, height: '500px'}"
        (change)="onChange($event)" (ready)="onReady($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
        (fileUploadRequest)="onFileUploadRequest($event)" (fileUploadResponse)="onFileUploadResponse($event)"
        debounce="700">
        {{terms}}
      </ckeditor>
      <div class="row">
        <div class="col-12 mt-5">
          <app-button label="Salvar" [disabled]="loading" [ngClass]="'btn-block'"></app-button>
        </div>
      </div>
    </form>
  </app-card>
</app-container>
