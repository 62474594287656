<app-navbar breadcrumbCurrent="Permissão do Google"></app-navbar>
<app-sidebar></app-sidebar>
<app-container col="col-10">
  <app-header title="Permissão do Google"></app-header>
  <app-card>
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12">
          <app-input [form]="form" label="Código" [required]="true" name="code" placeholder="Código"
            [ngClass]="{'is-invalid': form.controls['code'].touched && !form.controls['code'].valid}"
            [messageCondition]="form.controls['code'].touched && !form.controls['code'].valid"
            message="Preencha o código"></app-input>
        </div>
      </div>
      <app-form-button [loading]="loading" [disabled]="loading || form.invalid" [form]="form" [label]="'Salvar'">
      </app-form-button>
    </form>
  </app-card>
</app-container>
