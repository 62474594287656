<div class="card text-light mb-5">
  <div class="card-header">
    <form class="row align-items-center" *ngIf="hasSearch">
      <div class="col-auto pr-0">
        <span class="fe fe-search text-muted"></span>
      </div>
      <div class="col" style="flex:5">
        <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchString"
          class="form-control form-control-flush form-control-flush-no-border " placeholder="Pesquisar">
      </div>
      <div class="col text-right">
        <button class="btn btn-white" (click)="searchEmitted()">Pesquisar</button>
      </div>
    </form>

    <div class="row lign-items-center" *ngIf="hasOrder">
      <div class="col"></div>
      <div class="col-auto pl-0">
        <button class="btn btn-sm btn-outline-primary shadow" (click)="enableEmitted()" *ngIf="!moveEnabled">
          Ordenar
          <i class="fas fa-long-arrow-alt-up"></i>
          <i class="fas fa-long-arrow-alt-down"></i>
        </button>
        <button class="btn btn-sm btn-danger-soft shadow mr-2" (click)="enableEmitted()" *ngIf="moveEnabled">
          Cancelar
        </button>
        <button class="btn btn-sm btn-primary" (click)="orderEmitted()" *ngIf="moveEnabled">
          Concluir
        </button>
      </div>
    </div>
  </div>
  <div class="card card-inactive" *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <div class="table-responsive" *ngIf="!loading && !empty">
    <table class="table table-sm table-hover mb-0">
      <thead>
        <tr>
          <th *ngFor="let column of columns">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-content *ngIf="!loading"></ng-content>
      </tbody>
    </table>
  </div>
</div>
<div class="text-right text-muted mb-4" *ngIf="!loading">
  <span>mostrando {{ paginateInfo?.to }} de {{ paginateInfo?.total }} resultado(s)</span>
</div>
<app-empty-state *ngIf="!loading && empty"></app-empty-state>
<div class="row" *ngIf="more">
  <div class="col-12 d-flex justify-content-center">
    <button class="btn btn-outline-primary" (click)="loadMoreEmitted()" [disabled]="loadingMore">
      Carregar mais
      <i class="ml-2 fas fa-arrow-right"></i>
    </button>
  </div>
</div>
