<div class="vh-100 d-flex align-items-center border-top border-top-2 border-primary" style="display: block;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-xl-6 my-5">
        <div class="text-center">
          <h6 class="text-uppercase text-muted mb-4">
            erro 404
          </h6>
          <h1 class="display-4 mb-3">
            Página não encontrada 😭
          </h1>
          <p class="text-muted mb-4">
            Parece que você parou aqui por acidente
          </p>
          <a routerLink="" class="btn btn-lg btn-primary">
            Retornar ao dashboard
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
