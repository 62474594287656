import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';
import { HelperService } from './../../helpers/helper.service';
import { LivesService } from './../../services/lives.service';

@Component({
  selector: 'app-youtube-settings',
  templateUrl: './youtube-settings.component.html',
  styleUrls: ['./youtube-settings.component.scss']
})
export class YoutubeSettingsComponent implements OnInit {
  form: FormGroup
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private router: Router,
    private livesService: LivesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setupForm()
    this.getItem()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'client_id': ['', Validators.required],
      'client_secret': ['', Validators.required],
      'channel_youtube': ['', Validators.required],
      'google_api_key': ['', Validators.required],
      'redirect_uri': ['http://localhost:4200/google-permission', Validators.required],
    })
  }

  async getItem() {
    try {
      const configs = await this.livesService.get()
      if (configs.status) {
        this.form.patchValue(configs.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  save() {
    console.log(this.form.getRawValue())
    this.loading = true
    this.livesService.save(this.form.getRawValue())
      .then(async (res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true);
        } else {
          console.log(res.error)
          this.helper.triggerNotification(false, 'Erro', res.error);
        }
      })
      .catch(e => {
        console.log(e)
        this.helper.triggerNotification(false, 'Erro', e);
      })
      .finally(() => this.loading = false)
  }


}
