<div class="navbar-container">
  <nav class="navbar navbar-expand-lg navbar-light fixed-top pr-6 pl-5">
    <div class="container-fluid">
      <nav aria-label="breadcrumb ml-auto">
        <ol class="breadcrumb">
          <li *ngIf="breadcrumbLink" class="breadcrumb-item">
            <a routerLink="{{ breadcrumbLink }}" routerLinkActive="active">{{ breadcrumbLinkName }}</a>
          </li>
          <li *ngIf="breadcrumbSubLink" class="breadcrumb-item d-flex align-items-center">
            <a routerLink="{{ breadcrumbSubLink }}" routerLinkActive="active">{{ breadcrumbSubLinkName }}</a>
          </li>
          <li *ngIf="breadcrumbCurrent" class="breadcrumb-item active d-flex align-items-center" aria-current="page">
            {{ breadcrumbCurrent }}</li>
        </ol>
      </nav>
      <div class="collapse navbar-collapse justify-content-end">
        <div class="dropdown d-flex align-items-center">
          <span class="text-muted pr-3">
            {{session?.name}}
          </span>
          <span class="fas fa-user-circle display-4 text-primary"></span>
          <div class="dropdown-menu dropdown-menu-right dropdown-content">
            <a class="dropdown-item" routerLink="{{ PathRoutes.Admins.Detail+'/'+session?.id }}">Minha conta</a>
            <hr>
            <button class="dropdown-item" (click)="logout()">Sair</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
