export const PathRoutes = Object({
  Home: '/home',
  Login: '/login',
  Password: {
    ResetSuccess: '/senha-alterada',
    Reset: '/password/reset',
    Forgot: '/esqueci-minha-senha',
  },
  Lives: {
    List: '/lives',
    Detail: '/lives/detalhes'
  },
  Financial: {
    List: '/financeiro',
    Detail: '/financeiro/detalhes'
  },
  Admins: {
    List: '/admins',
    Add: '/admins/adicionar',
    Edit: '/admins/editar',
    Detail: '/admins/detalhe',
    ChangePassword: '/admins/alterar-senha'
  },
  Users: {
    List: '/usuarios',
    Detail: '/usuarios/detalhes',
    Add: '/usuarios/adicionar'
  },
  NotFound: '/notfound',
  TermsOfUse: {
    Show: '/termos-de-uso',
    Edit: '/termos-de-uso/editar'
  },
  GooglePermission: '/google-permission',
  YoutubeSettings: '/youtube-settings',
  Complaints: '/denuncias',
  BannerApp: {
    List: '/banner-app',
    Add: '/banner-app/adicionar',
    Detail: '/banner-app/detalhes',
    Edit: '/banner-app/editar'
  },
  Notification: '/enviar-notificacao',
  Posts: {
    List: '/postagens',
    Add: '/postagens/adicionar',
    Detail: '/postagens/detalhes',
    Edit: '/postagens/editar'
  },
  Channels: {
    List: '/canais'
  },
  Subchannels: {
    List: '/subcanais'
  },
  HomeAds: '/home-ads',
  Reports: {
    List: '/relatorios',
    Detail: '/relatorios/detalhes',
    Add: '/relatorios/adicionar',
    Edit: '/relatorios/editar'
  },
  ResearchCompanies: {
    List: '/casas-de-analise',
    Detail: '/casas-de-analise/detalhes',
    Add: '/casas-de-analise/adicionar',
    Edit: '/casas-de-analise/editar'
  },
});



